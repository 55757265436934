import { AllHtmlEntities } from 'html-entities';
import { useMemo } from 'react';

const entities = new AllHtmlEntities();

const TAGS_TO_REPLACE = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
const COMMENTS_AND_PHP_TO_STRIP = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

export function useStrippedTags(input: string, allowed?: string) {
  const actuallyAllowed = useMemo(
    () =>
      (
        ((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
      ).join(''),
    [allowed]
  );

  return useMemo(() => {
    if (!input) {
      return '';
    }

    return stripTags(input, actuallyAllowed);
  }, [input, actuallyAllowed]);
}

export function stripTags(input: string, allowed: string) {
  return input
    .replace(COMMENTS_AND_PHP_TO_STRIP, '')
    .replace(TAGS_TO_REPLACE, function ($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
}

export function useSafe(input: string, allowed?: string) {
  const strippedInput = useStrippedTags(input, allowed);
  return useMemo(() => entities.decode(strippedInput), [strippedInput]);
}
