import { PrimaryButton, TextButton, useLocale } from '@introcloud/blocks';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import { t } from 'i18n-js';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Clipboard, Platform, ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Appbar,
  Avatar,
  DataTable,
  Dialog,
  List,
  Paragraph,
  Portal,
  Surface,
  ThemeProvider,
  useTheme,
} from 'react-native-paper';
import { useHasContrastOnLight } from 'use-color-luminance';
import { permitKey, suspend } from '../core/Authentication';
import { BlockProvision } from '../core/BlockProvision';
import { Header } from '../core/Header';
import {
  BLOCK_CUSTOM_EMBED,
  BLOCK_GELEGRAAF,
  BLOCK_INFO_MARKET,
  BLOCK_RIJKSMUSEUM,
  BLOCK_ZOO_AUDIO_TOUR,
  CHAT_BUTTONS_ENABLED,
  EXPERIENCE_ENABLED,
  GELEGRAAF_ENABLED,
  INITIAL_MAP_FILTER,
  MAP_SWIPER_ENABLED,
  MULTI_COMPANY_ENABLED,
  PAGE_COLORS_ENABLED,
  TAG_OVERRIDES_ENABLED,
} from '../features';
import {
  AuthenticationPortal,
  Permit,
  useAuthentication,
  useEndpoint,
  useLogout,
} from '../hooks/useAuthentication';
import { useCompany, useRemoteCompany } from '../hooks/useCompany';
import { useGroups } from '../hooks/useGroup';
import { useTab } from '../hooks/useTab';
import { useUser } from '../hooks/useUser';
import { Group } from './Group';
import { ProfileCard } from './ProfileCard';
import { ProfileMatching } from './ProfileMatching';

export function ProfileScreen({ asTab }: { asTab?: boolean }) {
  // trigger render when locale changes
  useLocale();

  // trigger render when company changes
  useCompany();

  // Allow pushing through any "memo/blocked" updates
  const theme = useTheme();

  const [debugDialogActive, setDebugDialogActive] = useState(false);
  const showDebugDialog = useCallback(() => setDebugDialogActive(true), []);
  const hideDebugDialog = useCallback(() => setDebugDialogActive(false), []);

  return (
    <BlockProvision screen="ProfileScreen">
      <ThemeProvider theme={theme}>
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
            maxHeight: Platform.select({ web: '100vh', default: '100%' }),
          }}
        >
          <Header
            backFallback={{ screen: 'Tabs', params: { screen: 'Home' } }}
            hideProfile
            hideBack={asTab}
            title="Profile"
            subTitle={undefined}
            showTranslate
            colorOverride={theme.colors.primary}
          >
            <Appbar.Action
              icon="bug"
              accessibilityLabel="See debug information"
              onPress={() => showDebugDialog()}
            />
          </Header>
          <ScrollView
            nativeID="scroller"
            style={{ flex: 1, maxHeight: '100%' }}
            contentContainerStyle={{
              maxWidth: 720,
              alignSelf: 'center',
              paddingBottom: 56,
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <View>
              <ProfileCard />
              <ProfileMatching />
              {CHAT_BUTTONS_ENABLED ? <ChatButton /> : null}
              <GroupList />
              <CompanySwitcher />
              <Logout />
            </View>
          </ScrollView>
        </View>
        <Portal>
          <Dialog
            visible={debugDialogActive}
            onDismiss={hideDebugDialog}
            style={{ alignSelf: 'center', maxWidth: 450, width: '100%' }}
          >
            <DebugDialogContent onDismiss={hideDebugDialog} />
          </Dialog>
        </Portal>
      </ThemeProvider>
    </BlockProvision>
  );
}

function DebugDialogContent({ onDismiss }: { onDismiss: () => void }) {
  const { data: user } = useUser();
  const { authentication } = useAuthentication();
  const domain = useEndpoint();
  const [copied, setCopied] = useState(false);

  const manifest = Constants.manifest!;
  const extra = manifest.extra || {};

  const information = useMemo((): [string, string][] => {
    return [
      ['Device', Constants.isDevice ? 'Yes' : 'No'],
      ['Device name', Constants.deviceName || 'Unknown'],
      ['Device class', Constants.deviceYearClass || '-'],
      ['Runtime version', Constants.expoRuntimeVersion || '-'],
      ['App version', Constants.nativeAppVersion || '-'],
      ['App build', Constants.nativeBuildVersion || '-'],
      ['App owner', Constants.appOwnership || '-'],
      ['Platform', Platform.OS || '-'],
      [
        'Platform model',
        Platform.select({ ios: Constants.platform?.ios?.model }) || '-',
      ],
      ['Platform version', Platform.Version || '-'],
      ['System version', Constants.systemVersion || '-'],
      ['', ''],
      ['Manifest name', manifest.name || '-'],
      ['Release channel', manifest.releaseChannel || 'none'],
      ['Release id', manifest.releaseId || '-'],
      ['Release revision', manifest.revisionId || '-'],
      ['Release version', manifest.version || '-'],
      ['Release sdk', manifest.sdkVersion || '-'],
      ['', ''],
      ['IntroCloud locale', extra['locale'] || '-'],
      [
        'IntroCloud domain',
        extra['tactile-domain'] || extra['introcloud-domain'] || 'none',
      ],
      [
        'Whitelabel domain',
        extra['whitelabel-domain'] ||
          extra['tactile-domain'] ||
          extra['introcloud-domain'] ||
          'none',
      ],
      ['Initial map filter', INITIAL_MAP_FILTER || '<none>'],
      ['Custom Newspaper', GELEGRAAF_ENABLED ? 'yes' : 'no'],
      ['Custom Map', MAP_SWIPER_ENABLED ? 'yes' : 'no'],
      ['Custom Page colors', PAGE_COLORS_ENABLED ? 'yes' : 'no'],
      ['Custom Tag names', TAG_OVERRIDES_ENABLED ? 'yes' : 'no'],
      ['Custom Experience', EXPERIENCE_ENABLED ? 'yes' : 'no'],
      ['Extra Chat buttons', CHAT_BUTTONS_ENABLED ? 'yes' : 'no'],
      ['Block newspaper', BLOCK_GELEGRAAF ? 'yes' : 'no'],
      ['Block info market', BLOCK_INFO_MARKET ? 'yes' : 'no'],
      ['Block audio tour', BLOCK_ZOO_AUDIO_TOUR ? 'yes' : 'no'],
      ['Block custom embed', BLOCK_CUSTOM_EMBED ? 'yes' : 'no'],
      ['Block rijksmuseum', BLOCK_RIJKSMUSEUM ? 'yes' : 'no'],
      ['', ''],
      ['Authenticated user', user?.email?.value || '-'],
      [
        'Authenticated domain',
        authentication?.domainFull?.replace('https://', '') || '-',
      ],
      [
        'Active domain',
        domain?.replace('https://', '')?.replace('/api', '') || '-',
      ],
    ];
  }, [user, authentication?.domainFull, domain]);

  const copyToClipboard = useCallback(() => {
    const leftSize = Math.max(...information.map((row) => row[0]?.length || 0));

    Clipboard.setString(
      information
        .map((row) =>
          row[0] === '' ? '' : `${row[0].padEnd(leftSize, ' ')}\t\t${row[1]}`
        )
        .join('\n')
    );

    setCopied(true);
  }, [information]);
  return (
    <Fragment>
      <Dialog.Title>Device and App information</Dialog.Title>
      <Dialog.Content>
        <Paragraph>
          In order to resolve any issues encountered, there is information that
          will really help us track down the root cause. Please copy this
          information and provide it when you report any issues.
        </Paragraph>
      </Dialog.Content>
      <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
        <ScrollView style={{ maxHeight: 300 }}>
          <DataTable>
            {information.map((row, index, self) => (
              <DataTable.Row key={index}>
                {row.map((cell, i) => (
                  <DataTable.Cell
                    numeric={i > 0}
                    key={i}
                    style={{
                      flexShrink: i === 1 ? 0 : 1,
                      paddingHorizontal: 10,
                    }}
                  >
                    {cell}
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </DataTable>
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions
        style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
      >
        <PrimaryButton
          icon={copied ? 'check' : 'content-copy'}
          onPress={copyToClipboard}
          style={{ marginRight: 'auto' }}
        >
          {copied ? 'Copied' : 'Copy to clipboard'}
        </PrimaryButton>
        <TextButton icon="close" onPress={onDismiss}>
          Close
        </TextButton>
      </Dialog.Actions>
    </Fragment>
  );
}

function ChatButton() {
  const { navigate } = useNavigation();
  const tab = useTab('chats');

  return (
    <PrimaryButton
      style={{
        marginTop: 8,
        marginRight: 'auto',
        marginLeft: 16,
        marginBottom: 8,
      }}
      icon={
        ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
          (tab?.icon.name as 'forum')) ||
        'chat'
      }
      onPress={() => navigate('Chats')}
    >
      {t('app.actions.goto_chats')}
    </PrimaryButton>
  );
}

function GroupList() {
  const { groups } = useGroups();

  if (groups === undefined) {
    return null;
  }

  if (groups === null || groups.length === 0) {
    return <NoGroups />;
  }

  return (
    <Fragment>
      {groups.map((group, index) => {
        return <Group key={group.name.id + '-' + index} {...group} />;
      })}
    </Fragment>
  );
}

function NoGroups() {
  useLocale();

  return (
    <Fragment>
      <List.Subheader>{t('app.profile.groups.title')}</List.Subheader>
      <Paragraph style={{ marginHorizontal: 16 }}>
        {t('app.profile.groups.no_groups')}
      </Paragraph>
    </Fragment>
  );
}

function CompanySwitcher() {
  const { available, login, resolve, authentication } = useAuthentication();
  const theme = useTheme();

  if (!MULTI_COMPANY_ENABLED) {
    return null;
  }

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <List.Subheader>Sessions</List.Subheader>
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
          marginHorizontal: 16,
        }}
      >
        {available.map((key) => {
          return (
            <ResolveAvailableSessionItem
              key={key}
              resolveKey={permitKey(key)}
              resolve={resolve}
              login={login}
              current={
                permitKey(authentication?.domainFull || '') === permitKey(key)
              }
            />
          );
        })}
      </Surface>
    </View>
  );
}

function ResolveAvailableSessionItem({
  resolveKey,
  resolve,
  login,
  current,
}: {
  resolveKey: string;
  resolve(key: string): Promise<Permit | null>;
  login(permit: Permit): Promise<unknown>;
  current: boolean;
}) {
  const [resolved, setResolved] = useState<Permit | undefined | null>();

  useEffect(() => {
    let mounted = true;
    resolve(resolveKey).then((resolved) => {
      if (!mounted) {
        return;
      }

      setResolved(resolved);
    });

    return () => {
      mounted = false;
    };
  }, [resolveKey]);

  if (!resolved) {
    return null;
  }

  return (
    <AuthenticationPortal key={resolveKey} permit={resolved}>
      <ActiveSessionItem
        key={resolveKey}
        {...resolved}
        current={current}
        set={() => login(resolved)}
      />
    </AuthenticationPortal>
  );
}

function ActiveSessionItem({
  current,
  set,
  ...permit
}: { current: boolean; set(): void } & Permit) {
  const { data: company, isLoading } = useRemoteCompany(permit.domainFull);
  const { data: user } = useUser();

  const initials = (company?.name.full?.split(' ') || [])
    .slice(0, 2)
    .map((l) => (l || '')[0])
    .join('');

  const logo = company?.image?.profile
    ? [
        permit.domainFull,
        'api',
        'image',
        company?.image?.profile,
        'icon_64',
      ].join('/')
    : null;

  const {
    colors: { primary },
  } = useTheme();
  const textColor = useHasContrastOnLight(primary) ? '#fff' : '#121212';

  const renderIllustration = useCallback(
    () =>
      logo ? (
        <Avatar.Image
          size={40}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 8,
            marginBottom: 4,
            backgroundColor: 'transparent',
          }}
          source={{ uri: logo, width: 40, height: 40 }}
        />
      ) : (
        <Avatar.Text
          color={textColor}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 8,
            marginBottom: 4,
          }}
          size={40}
          label={initials}
        />
      ),
    [logo, initials, textColor]
  );

  return (
    <List.Item
      title={company?.name.full}
      description={user?.email.value}
      onPress={current ? undefined : set}
      left={
        isLoading
          ? () => (
              <ActivityIndicator
                style={{
                  marginLeft: 8,
                  marginRight: 16,
                  marginTop: 8,
                  marginBottom: 4,
                  width: 40,
                  height: 40,
                }}
              />
            )
          : renderIllustration
      }
      right={current ? () => <List.Icon icon="check" /> : undefined}
    />
  );
}

function Logout() {
  const logout = useLogout();

  if (!MULTI_COMPANY_ENABLED) {
    return (
      <PrimaryButton
        onPress={logout}
        style={{
          marginHorizontal: 16,
          marginVertical: 16,
          alignSelf: 'flex-start',
        }}
      >
        {t('app.actions.logout')}
      </PrimaryButton>
    );
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <PrimaryButton
        onPress={suspend}
        style={{
          marginLeft: 16,
          marginRight: 8,
          marginVertical: 16,
          alignSelf: 'flex-start',
        }}
      >
        Add another
      </PrimaryButton>
      <PrimaryButton
        onPress={logout}
        style={{
          marginRight: 16,
          marginVertical: 16,
          alignSelf: 'flex-start',
        }}
      >
        {t('app.actions.logout')}
      </PrimaryButton>
    </View>
  );
}
