import { TactileCompanyTabs } from '@introcloud/api-client';
import { RemoteTabConfiguration, TabIdentifier } from '@introcloud/tabs';
import { useMemo } from 'react';

import { useCompanyTabs } from './useCompanyTabs';

type Tab<T extends TabIdentifier> =
  | undefined
  | (RemoteTabConfiguration & { tab: T });

export function useTab<T extends TabIdentifier>(identifier: T): Tab<T> {
  const { values } = useCompanyTabs();

  return useMemo(
    () => values.find(({ tab }) => tab === identifier) as Tab<T>,
    [identifier]
  );
}

export function useTabConfiguration<
  T extends keyof TactileCompanyTabs['configuration']
>(identifier: T): TactileCompanyTabs['configuration'][T] {
  const { configuration } = useCompanyTabs();

  return configuration[identifier];
}
