import {
  getActionFromState,
  getStateFromPath,
  LinkingOptions,
  NavigationContainerRef,
} from '@react-navigation/native';
import { createRef, MutableRefObject } from 'react';

export const isReadyRef: MutableRefObject<boolean | null> = createRef();

export const navigationRef: MutableRefObject<NavigationContainerRef | null> =
  createRef();
export const navigationConfigRef: MutableRefObject<
  LinkingOptions['config'] | null
> = createRef();

export function navigate(
  name: string,
  params: Record<string, unknown> | undefined
) {
  if (isReadyRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current.navigate(name, params);
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
}

export function dispatch(
  action: NonNullable<ReturnType<typeof getActionFromState>>
) {
  navigationRef.current?.dispatch(action);
}

export function linkTo(
  path: string,
  config: Parameters<typeof getStateFromPath>[1] = undefined
) {
  const state = getStateFromPath(
    path,
    navigationConfigRef.current || undefined
  );

  if (!state) {
    return false;
  }

  const action = getActionFromState(state);
  if (action === undefined) {
    return false;
  }

  dispatch(action);
  return true;
}
