import { TactileVideo } from '@introcloud/api-client';
import React from 'react';
import { useTheme } from 'react-native-paper';
import Animated from 'react-native-reanimated';

export function AnimatedVideo({
  style,
  source,
  kind,
  shouldPlay,
}: {
  style?: React.ComponentProps<typeof Animated.View>['style'];
  source: string;
  kind: TactileVideo['kind'];
  shouldPlay?: boolean;
}) {
  const { roundness } = useTheme();

  return (
    <Animated.View
      style={[{ overflow: 'hidden', borderRadius: roundness }, style]}
    >
      {kind === 'url' ? (
        // autoPlay={shouldPlay}
        <video
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            width: '100%',
            height: '100%',
          }}
          src={source}
          autoPlay
        />
      ) : (
        <iframe
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            width: '100%',
            height: '100%',
            border: 0,
          }}
          frameBorder={0}
          src={source}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </Animated.View>
  );
}
