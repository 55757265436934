import {
  mergeTranslations,
  PrimaryButton,
  TextButton,
} from '@introcloud/blocks';
import {
  getPathFromState,
  NavigationContainerRef,
  Route,
  useRoute,
} from '@react-navigation/native';
import Constants from 'expo-constants';
import { makeUrl, openURL } from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { NotificationContent } from 'expo-notifications';
import { t } from 'i18n-js';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, ScrollView } from 'react-native';
import {
  Card,
  Dialog,
  Paragraph,
  Portal,
  Snackbar,
  useTheme,
} from 'react-native-paper';
import {
  useAddChannel,
  useSelfMessageListener,
} from '../chats/ProvideInAppChats';
import { useChatUserInfo } from '../chats/useChatUserInfo';
import { SelfMessage } from '../hooks/useChats';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { useUser } from '../hooks/useUser';
import { openExternalUrl } from '../open';
import { linkTo as gotoInternal, navigate } from './RootNavigation';

if (Platform.OS === 'android') {
  Notifications.setNotificationChannelAsync('priority', {
    name: 'Priority Pushes',
    description:
      'Pushes when there are calendar changes last-minute or other important notifications',
    sound: 'defaultCritical',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
  });

  Notifications.setNotificationChannelAsync('partners', {
    name: 'Partner Notifications',
    sound: null,
    importance: Notifications.AndroidImportance.DEFAULT,
  });
}

Notifications.setNotificationHandler({
  handleError: console.error,
  handleSuccess: (id) => {},
  handleNotification: async (notification) => {
    return {
      shouldShowAlert: false,
      shouldPlaySound: false,
      shouldSetBadge: false,
    };
  },
});

mergeTranslations({
  en: {
    app: {
      notifications: {
        close: 'Close',
        view: 'View',
      },
    },
  },
  nl: {
    app: {
      notifications: {
        close: 'Sluit',
        view: 'Bekijk',
      },
    },
  },
});

export function sendLocalNotification(title: string, message: string) {
  Notifications.scheduleNotificationAsync({
    content: { title, body: message },
    trigger: null,
  });
}

export function InAppNotifications({
  navigationRef,
}: {
  navigationRef: undefined | RefObject<NavigationContainerRef>;
}) {
  const pubnub = usePubNub();
  return (
    <Portal>
      <PubNubProvider client={pubnub}>
        <InAppNotifications_ navigationRef={navigationRef} />
        <ChatSubscriptionsNotifier />
      </PubNubProvider>
    </Portal>
  );
}

const manifest = Constants.manifest || { extra: {} };
const extra = manifest.extra || {};

const DOMAIN_TACTILE = extra['tactile-domain'];
const DOMAIN_INTROCLOUD = extra['introcloud-domain'];
const DOMAIN_WHITELABEL = extra['whitelabel-domain'];

const INTERNAL_PREFIXES = [makeUrl('/')].concat(
  [DOMAIN_TACTILE, DOMAIN_INTROCLOUD, DOMAIN_WHITELABEL]
    .filter(Boolean)
    .map((domain) => 'https://' + domain)
    .map((domain) => domain.toLocaleUpperCase())
);

function InAppNotifications_({
  navigationRef,
}: {
  navigationRef: undefined | RefObject<NavigationContainerRef>;
}) {
  const pubnub = usePubNub();
  const {
    colors: { primary },
  } = useTheme();

  const [routePath, setRoutePath] = useState('');
  const lastFollowedOrDismissed = useRef<string | null>(null);
  const lastReceivedNotification = useRef<string | null>(null);
  const lastNotification = Notifications.useLastNotificationResponse();
  const [notification, setNotification] =
    useState<null | Notifications.NotificationRequest>(
      lastNotification?.notification?.request || null
    );

  if (notification) {
    lastReceivedNotification.current = notification.identifier;
  }

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!navigationRef) {
      return;
    }

    const { current } = navigationRef;
    if (!current) {
      return;
    }

    return current.addListener('state' as any, (event) => {
      if (!event.data?.state) {
        return;
      }

      setRoutePath(getPathFromState(event.data!.state!));
    });
  }, [navigationRef]);

  // Figure out if this should skip a notification
  const isChatRoute = (routePath || '').toLocaleUpperCase().includes('/CHAT');
  const url = notification?.content.data?.url as string | undefined;
  const isChatNotification =
    notification?.content.data?.kind === 'chat-notification' ||
    String(url || '').includes('/chat/');
  const shouldSkipNotification = isChatRoute && isChatNotification;

  const addChatChannel = useAddChannel(pubnub);

  useEffect(() => {
    if (
      !shouldSkipNotification ||
      !notification ||
      !lastReceivedNotification.current
    ) {
      return;
    }

    lastFollowedOrDismissed.current = identifier;
    lastReceivedNotification.current = null;
  }, [notification, shouldSkipNotification]);

  useEffect(() => {
    if (!isChatNotification || !url) {
      return;
    }

    const [, id] = url.split('chat/');

    addChatChannel(id);
  }, [isChatNotification, url]);

  // When something is dropped
  useEffect(() => {
    const listener = Notifications.addNotificationsDroppedListener(() =>
      console.log('[dropped] notification')
    );
    return () => listener.remove();
  }, []);

  // When something is received
  useEffect(() => {
    const listener = Notifications.addNotificationReceivedListener(
      (notification) => {
        console.log('[received] notification', notification);
        const identifier = notification.request.identifier;

        if (
          !lastReceivedNotification.current ||
          lastReceivedNotification.current !== identifier
        ) {
          if (
            !lastFollowedOrDismissed.current ||
            lastFollowedOrDismissed.current !== identifier
          ) {
            setNotification(notification?.request);
          }
        }
      }
    );
    return () => listener.remove();
  }, []);

  useEffect(() => {
    const listener = Notifications.addNotificationResponseReceivedListener(
      (notification) => {
        if (
          notification.actionIdentifier !==
          Notifications.DEFAULT_ACTION_IDENTIFIER
        ) {
          return;
        }

        if (
          !lastReceivedNotification.current ||
          lastReceivedNotification.current !==
            notification.notification.request.identifier
        ) {
          lastFollowedOrDismissed.current = null;
          setNotification(notification.notification.request);
        }
      }
    );

    return () => {
      listener.remove();
    };
  }, []);

  const identifier = notification?.identifier || null;
  const deeplink = (notification?.content.data?.url as string) || null;

  const doHideNotification = () => {
    lastFollowedOrDismissed.current = identifier;
    lastReceivedNotification.current = null;
    forceUpdate();
  };

  const internalPath = useMemo(() => {
    if (!deeplink) {
      return null;
    }

    const internalPrefix = INTERNAL_PREFIXES.find(
      (prefix) => deeplink.toLocaleUpperCase().indexOf(prefix) === 0
    );

    return internalPrefix ? deeplink.substring(internalPrefix.length) : null;
  }, [deeplink]);

  return (
    <PushNotificationDialog
      identifier={identifier || undefined}
      push={notification?.content}
      visible={
        lastFollowedOrDismissed.current !== identifier &&
        !shouldSkipNotification
      }
      onClose={doHideNotification}
      onFollow={() => {
        doHideNotification();

        if (deeplink) {
          if (internalPath || deeplink[0] === '/') {
            try {
              gotoInternal(internalPath || deeplink) ||
                openExternalUrl(deeplink, primary);
            } catch (_) {
              openExternalUrl(deeplink, primary);
            }
          } else {
            openExternalUrl(deeplink, primary);
          }
        } else {
          const { screen, params } = notification?.content.data || {};
          if (screen) {
            navigate(screen as string, (params as any) || {});
          }
        }
      }}
    />
  );
}

function PushNotificationDialog({
  identifier,
  push,
  visible,
  onClose: doClose,
  onFollow: doFollow,
}: {
  identifier: string | undefined;
  push: NotificationContent | undefined;
  visible: boolean;
  onClose(): void;
  onFollow(): void;
}) {
  const title = (push?.data?.title || push?.title || '') as string;
  const body = (push?.data?.body || push?.body || '') as string;
  const imageUrl = (push?.data?.image || null) as string | null;
  const action = (push?.data?.action || 'Go') as string;
  const canActivate = !!(
    (push?.data && (push.data.screen || push.data.url)) ||
    false
  );

  return (
    <Dialog
      key={identifier}
      visible={visible}
      onDismiss={doClose}
      style={{
        alignSelf: 'center',
        maxWidth: 720,
        maxHeight: '40%',
        width: '90%',
      }}
    >
      <Card>
        <Card.Title title={title} />
        {(imageUrl && <Card.Cover source={{ uri: imageUrl }} />) || null}
        <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
          <ScrollView
            contentContainerStyle={{ margin: 0, paddingHorizontal: 16 }}
          >
            <Paragraph style={{ paddingVertical: 16 }}>{body}</Paragraph>
          </ScrollView>
        </Dialog.ScrollArea>
        <Card.Actions>
          <TextButton onPress={doClose} style={{ marginLeft: 'auto' }}>
            {t('app.notifications.close')}
          </TextButton>
          {(canActivate && (
            <PrimaryButton
              onPress={doFollow}
              style={{ marginLeft: 8 }}
              mode="contained"
            >
              {action}
            </PrimaryButton>
          )) ||
            null}
        </Card.Actions>
      </Card>
    </Dialog>
  );
}

function ChatSubscriptionsNotifier() {
  const { data: user, reload } = useUser();
  const [subscriptions, setSubscriptions] = useState<SelfMessage[]>([]);

  const uuid = user?._id;

  useSelfMessageListener(
    useCallback(
      (message) =>
        message.t === 'subscribe' &&
        message.s !== uuid &&
        setSubscriptions((prev) => prev.concat([message])),
      [setSubscriptions, uuid]
    )
  );

  const [first] = subscriptions;

  const markAsDone = useCallback(
    () =>
      setSubscriptions((prev) =>
        prev.filter((m) => m.t !== first.t || m.c !== first.c)
      ),
    [first]
  );

  if (subscriptions.length === 0 || !uuid) {
    return null;
  }

  return <NewChatSubscriptionSnackbar trigger={first} onDone={markAsDone} />;
}

function NewChatSubscriptionSnackbar({
  trigger,
  onDone,
}: {
  trigger: SelfMessage;
  onDone(): void;
}) {
  const [shouldGoAway, setShouldGoAway] = useState(false);
  const userId = trigger.t === 'subscribe' && trigger.s;
  const info = useChatUserInfo(userId ? { id: userId } : null);

  // const {} = useBlockNavigation()

  const onGotoChat = useCallback(() => {
    try {
      gotoInternal(`/chat/${trigger.c}`) ||
        openURL(makeUrl(`/chat/${trigger.c}`));
    } catch (_) {
      openURL(makeUrl(`/chat/${trigger.c}`));
    }
  }, [trigger.c, gotoInternal]);

  const action = useMemo(
    () => ({ label: t('app.notifications.view'), onPress: onGotoChat }),
    [onGotoChat]
  );

  const markAsDone = useCallback(() => setShouldGoAway(true), []);

  // Auto dismiss if userId was not passed
  useEffect(() => {
    if (userId || !onDone) {
      return;
    }

    onDone();
  }, [userId, onDone]);

  // Dismiss
  useEffect(() => {
    if (!shouldGoAway) {
      return;
    }

    const timeout = setTimeout(() => {
      onDone();
      setShouldGoAway(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldGoAway]);

  const title = t('app.chats.notification_new', {
    name: info?.name.full || t('app.chats.anonymous'),
  });

  return (
    <Snackbar
      visible={!shouldGoAway}
      onDismiss={markAsDone}
      action={action}
      duration={Snackbar.DURATION_LONG}
    >
      {title}
    </Snackbar>
  );
}
