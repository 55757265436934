import { TactileLocation } from '@introcloud/api-client';
import { LocationCard } from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import React from 'react';

export function SelectedMapMarker({ item }: { item: TactileLocation | null }) {
  const { getImageUrl } = useBlockData();
  if (!item) {
    return null;
  }

  const {
    _id: id,
    image,
    name: { full: name, description },
    street,
    number,
    addition,
    geojson,
    city,
    url,
  } = item;

  const address = {
    city: city || undefined,
    number: number || undefined,
    addition: addition || undefined,
    street: street || undefined,
  };

  const illustration = getImageUrl(image?.profile || '', 'icon_128') || '';

  const [latitude, longitude] =
    geojson?.type === 'Point' ? geojson.coordinates : [];

  return (
    <LocationCard
      source={{
        id,
        title: name,
        illustration,
        description: description || '',
        address: address.street ? address : undefined,
        coordinates:
          latitude !== undefined && longitude !== undefined
            ? [Number(latitude), Number(longitude)]
            : undefined,
        url,
      }}
      style={{
        margin: 2,
        marginBottom: 24,
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
        elevation: 4,
      }}
      directionsStyle={{ elevation: 1 }}
    />
  );
}
