import { Divider } from '@introcloud/blocks';
import { BlockOptions } from '@introcloud/page';
import React from 'react';
import { StyleSheet, PixelRatio } from 'react-native';
import {
  Caption,
  DarkTheme,
  Paragraph,
  Surface,
  Text,
  ThemeProvider,
} from 'react-native-paper';

import { SHOULD_ALLOW_DEBUG } from '../utils';

export function ErrorBlock({ error }: BlockOptions & { error: Error }) {
  if (!SHOULD_ALLOW_DEBUG) {
    return null;
  }

  return (
    <ThemeProvider theme={DarkTheme}>
      <Divider
        style={{
          height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        }}
      />

      <Surface
        style={{
          elevation: 1,
          backgroundColor: 'rgb(176, 0, 32)',
          paddingHorizontal: 18,
          paddingVertical: 12,
          top: 0,
          width: '100%',
        }}
      >
        <Paragraph>
          Unfortunately there was a problem when trying to show this block. In
          production, this block will be hidden, if the problem persists.
        </Paragraph>

        <Caption>
          <Text style={{ fontWeight: 'bold' }}>{error.name}:</Text>{' '}
          {error.message}
        </Caption>

        <Caption>{error.stack}</Caption>
      </Surface>
    </ThemeProvider>
  );
}
