import { TabParamList } from '@introcloud/tabs';
import { useRoute } from '@react-navigation/native';
import React, { useMemo } from 'react';

import { BlockProvision } from '../core/BlockProvision';
import { RouteProp } from '../core/Routes';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGoals } from '../hooks/useGoals';
import { LoadingPage, Page } from '../page/Page';

export function GoalScreen() {
  const route = useRoute<RouteProp<'Goal'>>();
  const goal = route.params?.id;

  return (
    <BlockProvision screen="GoalScreen">
      <GoalPage id={goal} />
    </BlockProvision>
  );
}

function GoalPage({ id }: { id: string }) {
  const { data: goals } = useGoals();
  const { values } = useCompanyTabs();

  const fallBack = useMemo(
    () => ({
      screen: 'Tabs' as const,
      params: {
        screen: values.some((tab) => tab.tab === 'goals')
          ? 'Goals'
          : values.some((tab) => tab.tab === 'home')
          ? 'Home'
          : ((values[0].tab[0].toLocaleUpperCase() +
              values[0].tab.slice(1)) as keyof TabParamList),
      },
    }),
    [values]
  );

  const goal = useMemo(
    () => goals?.find((goal) => goal.id || (goal as any)._id === id),
    [goals]
  );

  if (!goal) {
    // TODO loading/error
    return <LoadingPage fallBack={fallBack} />;
  }

  if (goal && (goal as any).pageRef && (goal as any).pageRef.pageId) {
    return <Page page={(goal as any).pageRef.pageId} fallBack={fallBack} />;
  }

  // nothing to show, TODO: go back to goals
  return null;
}
