import { t } from 'i18n-js';
import React, { useMemo } from 'react';
import { View } from 'react-native';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { useCompanyTabs } from '../hooks/useCompanyTabs';

export function ScannerScreen({ asTab }: { asTab?: boolean }) {
  const { values } = useCompanyTabs();
  const tab = useMemo(
    () => values.find((tab) => tab.tab === 'scanner'),
    [values]
  );
  const title = useMemo(() => tab?.title, [tab]);
  const icon = useMemo(() => (tab ? tab.icon.name : 'qrcode-scan'), [tab]);

  return (
    <View style={{ flex: 1 }}>
      <EmptyState
        title={title || t('app.scanner.title')}
        texts={{
          en: 'The scanner is only available in the Android and iOS app.',
          nl: 'De scanner is alleen beschikbaar in de Android en iOS app.',
        }}
        icon={icon}
        hidden={false}
      />{' '}
      <Header
        hideBack={asTab}
        title={title || t('app.scanner.title')}
        subTitle={undefined}
        style={{ elevation: 2, zIndex: 2 }}
      />
    </View>
  );
}
