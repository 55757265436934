import { PrimaryButton, TextButton } from '@introcloud/blocks';
import React, { Fragment } from 'react';
import { View } from 'react-native';
import { Paragraph } from 'react-native-paper';

import { DialogContentProps } from './types';

export function ManualDialogContent({ onDismiss }: DialogContentProps) {
  return (
    <Fragment>
      <Paragraph>
        You can't submit an answer. Someone needs to award you this item.
      </Paragraph>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          disabled
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Submit
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          Close
        </TextButton>
      </View>
    </Fragment>
  );
}
