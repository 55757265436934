import { fetchChatName } from '@introcloud/api-client';
import { useCallback } from 'react';

import { NotReady } from '../core/errors/NotReady';
import { useAbortController } from '../hooks/useAbortController';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { SHOULD_DEBUG_FETCH } from '../utils';

export function useNameFetcher(modelName: 'page' | 'group' | 'user') {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const abortable = useAbortController();

  return useCallback(
    (id: string) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }
      const ac = abortable();
      const cancellable = fetchChatName(
        modelName,
        id,
        endpoint,
        authorization,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      // This is a non-standard property on a promise, so the error here needs to
      // be ignored. However, react-query will check this non-standard property
      // and use it if it's available.
      //
      // @ts-ignore
      cancellable.cancel = () => {
        ac && ac.abort();
      };

      return cancellable;
    },
    [authorization, endpoint, modelName]
  );
}
