export {};

// eslint-disable-next-line no-extend-native
Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    finally<T>(fn: any): Promise<T> {
      const onFinally = (callback: any) => Promise.resolve(fn()).then(callback);
      return (this as Promise<T>).then(
        (result) => onFinally(() => result),
        (reason) => onFinally(() => Promise.reject(reason))
      );
    },
  }.finally;
