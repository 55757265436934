import { fetchApplication } from '@introcloud/api-client';
import {
  AccentButton,
  Divider,
  mergeTranslations,
  PrimaryButton,
  TextButton,
} from '@introcloud/blocks';
import { fetchMedia } from 'fetch-media';
import { t } from 'i18n-js';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  PixelRatio,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import {
  ActivityIndicator,
  Avatar,
  Card,
  Dialog,
  HelperText,
  List,
  Paragraph,
  Portal,
  Surface,
  TextInput,
  Title,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Circle, Ellipse, Path, Svg } from 'react-native-svg';
import { useFocusInput } from 'react-native-use-focus-input';
import { useHasContrastOnLight } from 'use-color-luminance';
import { permitKey } from '../core/Authentication';
import { MULTI_COMPANY_ENABLED } from '../features';
import {
  AuthenticationPortal,
  Permit,
  SelectCompany,
  useAuthenticateEmail,
  useAuthentication,
  useEndpoint,
  useValidateEmail,
} from '../hooks/useAuthentication';
import { COMPANY, useCompany, useRemoteCompany } from '../hooks/useCompany';
import { useUser } from '../hooks/useUser';
import { SHOULD_ALLOW_DEBUG, SHOULD_DEBUG_FETCH } from '../utils';

mergeTranslations({
  en: {
    app: {
      login: {
        primary_action: 'Login',
        continue_action: 'Continue',
        forgot_password_action: 'Forgot password?',
        other_company_action: 'Select other',
      },
      forgot_password: {
        title: 'Forgot password',
        primary_action: 'Reset',
        negative_action: 'Cancel',
        instructions:
          "Use the e-mail you used to register. We'll send you instructions how to recover your account.",
      },
    },
  },

  nl: {
    app: {
      login: {
        primary_action: 'Inloggen',
        continue_action: 'Doorgaan',
        forgot_password_action: 'Wachtwoord vergeten?',
        other_company_action: 'Kies andere',
      },
      forgot_password: {
        title: 'Wachtwoord vergeten',
        primary_action: 'Resetten',
        negative_action: 'Annuleren',
        instructions:
          'Gebruik het e-mail adres dat je hebt gebruikt om te registeren. Wij sturen je instructies om weer toegang te krijgen tot jouw account.',
      },
    },
  },
});

export function LoginScreen() {
  const {
    dark,
    colors: { background },
  } = useTheme();

  useLayoutEffect(() => {
    if (Platform.OS === 'web') {
      document.body.style.backgroundColor = background;
    }
  }, [background]);

  return (
    <View
      style={{
        backgroundColor: Platform.OS !== 'web' ? background : undefined,
        height: '100%',
        width: '100%',
        flex: 1,
      }}
    >
      <StatusBar barStyle={dark ? 'light-content' : 'dark-content'} />
      <People />
      <Form />
    </View>
  );
}

function Form() {
  const endpoint = useEndpoint();
  const insets = useSafeAreaInsets();

  const {
    validate,
    isLoading: isValidating,
    error: validationError,
    options,
    reset: resetValidation,
  } = useValidateEmail();
  const {
    attempt,
    isLoading: isAuthenticating,
    error: authenticationError,
    reset: resetAuthentication,
  } = useAuthenticateEmail();

  const [selectedCompany, setSelectedCompany] = useState<SelectCompany | null>(
    null
  );

  // Select first option if there's only one
  if (!selectedCompany && options && options.length === 1) {
    setSelectedCompany(options[0]);
  }

  useEffect(() => {
    if (!selectedCompany || !MULTI_COMPANY_ENABLED) {
      return;
    }

    let isMounted = true;

    fetchApplication(
      selectedCompany.domainFull + '/api',
      undefined,
      SHOULD_DEBUG_FETCH
    ).then((result) => {
      // debugger;
      if (isMounted && result) {
        return COMPANY.emit(result);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [selectedCompany]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailRef] = useFocusInput();
  const [passwordRef, focusPassword] = useFocusInput();
  const [showingForgotPassword, setShowForgotPassword] = useState(false);

  const showForgotPassword = useCallback(() => setShowForgotPassword(true), []);
  const hideForgotPassword = useCallback(() => {
    setShowForgotPassword(false);
    resetAuthentication();
  }, []);

  const resetPassword = () => {
    fetchMedia(
      `${
        selectedCompany?.domainFull
          ? `${selectedCompany?.domainFull}/api`
          : endpoint
      }/public/auth/forgot`,
      {
        headers: {
          accept: 'application/json',
          contentType: 'application/json',
        },
        method: 'POST',
        body: { email },
      }
    ).finally(hideForgotPassword);
  };

  const loading = isAuthenticating || isValidating;
  const error = authenticationError || validationError;
  const companySelected = !MULTI_COMPANY_ENABLED || selectedCompany;

  if ((options || []).length > 1 && !selectedCompany) {
    return (
      <SelectLoginOption
        email={email}
        options={options!}
        doLogin={(_?: unknown, option?: SelectCompany) => {
          setSelectedCompany(option || null);
          // attempt({ username: email, password, option });
        }}
      />
    );
  }

  return (
    <Wrapper>
      <KeyboardAvoidingView
        // eslint-disable-next-line react/jsx-curly-brace-presence
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1, maxHeight: '100%' }}
        enabled
      >
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            maxWidth: 720,
            paddingBottom: insets.bottom,
            alignSelf: 'center',
            width: '100%',
          }}
        >
          <View style={[styles.form]}>
            <View
              style={{
                width: 192 + 24,
                height: 192 + 24,
                padding: 12,
                margin: 48,
                alignSelf: 'center',
              }}
            >
              <Logo selectedCompany={selectedCompany} />
            </View>

            <View
              style={{
                marginHorizontal: 'auto',
                width: '100%',
                maxWidth: 400,
                alignSelf: 'center',
              }}
            >
              <HelperText type="error" visible>
                {error ? error.message : ' '}
              </HelperText>

              <TextInput
                disabled={loading}
                ref={emailRef}
                placeholder="Email"
                style={styles.input}
                textContentType="emailAddress"
                keyboardType="email-address"
                onSubmitEditing={
                  companySelected
                    ? focusPassword
                    : () => validate(email).catch(() => {})
                }
                returnKeyType="next"
                value={email}
                onChangeText={setEmail}
                blurOnSubmit={false}
                autoCapitalize="none"
                error={!!error}
                {...Platform.select({
                  android: { autoCompleteType: 'email' },
                })}
              />
              {companySelected ? (
                <Fragment>
                  <TextInput
                    disabled={loading}
                    ref={passwordRef}
                    placeholder="Password"
                    style={styles.input}
                    textContentType="password"
                    secureTextEntry
                    onSubmitEditing={() =>
                      attempt({
                        username: email,
                        password,
                        option: selectedCompany || undefined,
                      }).catch(() => {})
                    }
                    returnKeyType="done"
                    value={password}
                    onChangeText={setPassword}
                    blurOnSubmit={false}
                    autoCapitalize="none"
                    error={!!error}
                    {...Platform.select({
                      android: { autoCompleteType: 'email' },
                    })}
                  />

                  <PrimaryButton
                    disabled={loading}
                    style={styles.submit}
                    loading={loading}
                    onPress={() =>
                      attempt({
                        username: email,
                        password,
                        option: selectedCompany || undefined,
                      }).catch(() => {})
                    }
                  >
                    {t('app.login.primary_action')}
                  </PrimaryButton>

                  <TextButton onPress={showForgotPassword} disabled={loading}>
                    {t('app.login.forgot_password_action')}
                  </TextButton>

                  {MULTI_COMPANY_ENABLED ? (
                    <TextButton
                      onPress={() => {
                        if (options?.length === 1) {
                          resetValidation();
                        }

                        setSelectedCompany(null);
                      }}
                      disabled={loading}
                    >
                      {t('app.login.other_company_action')}
                    </TextButton>
                  ) : null}
                </Fragment>
              ) : (
                <Fragment>
                  <PrimaryButton
                    disabled={loading}
                    loading={loading}
                    style={styles.submit}
                    onPress={() => validate(email).catch(() => {})}
                  >
                    {t('app.login.continue_action')}
                  </PrimaryButton>
                  <SelectPreviousSession />
                </Fragment>
              )}
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      <Portal>
        <Dialog
          visible={showingForgotPassword}
          dismissable
          onDismiss={hideForgotPassword}
          style={{
            maxWidth: Platform.select({ web: 720 }),
            alignSelf: 'center',
            maxHeight: '70%',
            margin: 'auto',
          }}
        >
          <Dialog.Title>{t('app.forgot_password.title')}</Dialog.Title>
          <Dialog.Content style={{ maxHeight: 200 }}>
            <View style={{ maxWidth: 480 }}>
              <TextInput
                disabled={loading}
                ref={emailRef}
                placeholder="email"
                style={styles.input}
                textContentType="emailAddress"
                keyboardType="email-address"
                onSubmitEditing={resetPassword}
                returnKeyType="go"
                value={email}
                onChangeText={setEmail}
                blurOnSubmit={false}
                error={!!error}
                {...Platform.select({
                  android: { autoCompleteType: 'email' },
                })}
              />

              <Paragraph>{t('app.forgot_password.instructions')}</Paragraph>
            </View>
          </Dialog.Content>
          <Dialog.Actions style={{ padding: 12, paddingTop: 0 }}>
            <TextButton style={{ marginRight: 8 }} onPress={hideForgotPassword}>
              {t('app.forgot_password.negative_action')}
            </TextButton>
            <AccentButton onPress={resetPassword}>
              {t('app.forgot_password.primary_action')}
            </AccentButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Wrapper>
  );
}

function Logo({ selectedCompany }: { selectedCompany: SelectCompany | null }) {
  if (MULTI_COMPANY_ENABLED && selectedCompany) {
    return <RemoteLogo domain={selectedCompany.domainFull} />;
  }

  return <LocalLogo />;
}

function RemoteLogo({ domain }: { domain: string | undefined }) {
  const { data: company } = useRemoteCompany(domain);
  const endpoint = domain + '/api';
  const logo = company?.image?.profile
    ? [endpoint, 'image', company.image.profile, 'icon_720'].join('/')
    : '';

  if (!logo) {
    return <View style={{ width: 192, height: 192 }} />;
  }

  return (
    <Image
      source={{ uri: logo }}
      style={{
        width: 192,
        height: 192,
        alignSelf: 'center',
      }}
      width={192}
      height={192}
      resizeMode="contain"
    />
  );
}

function LocalLogo() {
  const company = useCompany();
  const endpoint = useEndpoint();
  if (!company) {
    return <View style={{ width: 192, height: 192, alignSelf: 'center' }} />;
  }

  const logo = company.image.profile
    ? [endpoint, 'image', company.image.profile, 'icon_720'].join('/')
    : '';

  if (!logo) {
    return <View style={{ width: 192, height: 192, alignSelf: 'center' }} />;
  }

  return (
    <Image
      source={{ uri: logo }}
      style={{
        width: 192,
        height: 192,
        alignSelf: 'center',
      }}
      width={192}
      height={192}
      resizeMode="contain"
    />
  );
}

function SelectLoginOption({
  email,
  options,
  doLogin: attemptLogin,
}: {
  email: string;
  options: readonly SelectCompany[];
  doLogin: (_?: unknown, option?: SelectCompany) => void;
}) {
  const insets = useSafeAreaInsets();

  const showExtraOptions =
    SHOULD_ALLOW_DEBUG || email.endsWith('@tactile.events');

  return (
    <View
      style={[
        styles.form,
        {
          marginTop: insets.top,
          maxWidth: 420,
          marginHorizontal: 'auto',
          width: '100%',
        },
      ]}
    >
      <Title
        style={{
          marginTop: showExtraOptions ? 0 : 48 + 48 - 20,
          marginBottom: 12,
        }}
      >
        Select
      </Title>
      <Card
        style={{
          width: '100%',
        }}
      >
        <ScrollView style={{ maxHeight: showExtraOptions ? 200 : 300 }}>
          <LoginOptions options={options} doLogin={attemptLogin} />

          {showExtraOptions ? (
            <Fragment>
              <Divider
                style={{
                  height: PixelRatio.roundToNearestPixel(
                    StyleSheet.hairlineWidth
                  ),
                }}
              />
              <List.Item
                title="Tactile App (release)"
                onPress={() => {
                  attemptLogin(undefined, {
                    domainFull: 'https://tactileapp.release.tactile.events',
                    domain: 'tactileapp.release.tactile.events',
                    name: { full: 'Tactile App (release)' },
                  });
                }}
              />
              <Divider
                style={{
                  height: PixelRatio.roundToNearestPixel(
                    StyleSheet.hairlineWidth
                  ),
                }}
              />
              <List.Item
                title="Clean (release)"
                onPress={() => {
                  attemptLogin(undefined, {
                    domainFull: 'https://clean.release.tactile.events',
                    domain: 'clean.release.tactile.events',
                    name: { full: 'Clean (release)' },
                  });
                }}
              />
              <List.Item
                title="Clean (test)"
                onPress={() => {
                  attemptLogin(undefined, {
                    domainFull: 'https://clean.test.tactile.events',
                    domain: 'clean.test.tactile.events',
                    name: { full: 'Clean (test)' },
                  });
                }}
              />
              <List.Item
                title="Tactile Training (release)"
                onPress={() => {
                  attemptLogin(undefined, {
                    domainFull: 'https://training.release.tactile.events',
                    domain: 'training.release.tactile.events',
                    name: { full: 'Tactile Training (release)' },
                  });
                }}
              />
              <List.Item
                title="Tactile Training (test)"
                onPress={() => {
                  attemptLogin(undefined, {
                    domainFull: 'https://training.test.tactile.events',
                    domain: 'training.test.tactile.events',
                    name: { full: 'Tactile Training (test)' },
                  });
                }}
              />
            </Fragment>
          ) : null}
        </ScrollView>
      </Card>

      {showExtraOptions ? <QuickLoginOptions doLogin={attemptLogin} /> : null}
    </View>
  );
}

function QuickLoginOptions({
  doLogin: attemptLogin,
}: {
  doLogin: (_?: unknown, option?: SelectCompany) => void;
}) {
  return (
    <Fragment>
      <List.Subheader style={{ marginTop: 12 }}>Test domains</List.Subheader>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <PrimaryButton
          compact
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://tactileapp.test.tactile.events',
              domain: 'tactileapp.test.tactile.events',
              name: { full: 'Tactile App (test)' },
            })
          }
        >
          App
        </PrimaryButton>

        <PrimaryButton
          compact
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://clean.test.tactile.events',
              domain: 'clean.test.tactile.events',
              name: { full: 'Clean (test)' },
            })
          }
        >
          Clean
        </PrimaryButton>

        <PrimaryButton
          compact
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://training.test.tactile.events',
              domain: 'training.test.tactile.events',
              name: { full: 'Tactile Training (test)' },
            })
          }
        >
          Training
        </PrimaryButton>
      </View>

      <List.Subheader style={{ marginTop: 12 }}>
        Quick domains (release)
      </List.Subheader>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://bce.release.tactile.events',
              domain: 'bce.release.tactile.events',
              name: { full: 'Betá Career Event' },
            })
          }
        >
          BCE
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://elcid.release.tactile.events',
              domain: 'elcid.release.tactile.events',
              name: { full: 'ELCid' },
            })
          }
        >
          ELCid
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://fintax.release.tactile.events',
              domain: 'fintax.release.tactile.events',
              name: { full: 'FinTax' },
            })
          }
        >
          FinTax
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://kei.release.tactile.events',
              domain: 'kei.release.tactile.events',
              name: { full: 'KEI-Week' },
            })
          }
        >
          KEI
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://kuleuven.release.tactile.events',
              domain: 'kuleuven.release.tactile.events',
              name: { full: 'KU Leuven' },
            })
          }
        >
          KU Leuven
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://mfas.release.tactile.events',
              domain: 'mfas.release.tactile.events',
              name: { full: 'MFAS' },
            })
          }
        >
          MFAS
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://owee.release.tactile.events',
              domain: 'owee.release.tactile.events',
              name: { full: 'OntvangstWeek' },
            })
          }
        >
          OWee
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://ru.release.tactile.events',
              domain: 'ru.release.tactile.events',
              name: { full: 'Raboud Events' },
            })
          }
        >
          RU
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://rijkswaterstaat.release.tactile.events',
              domain: 'rijkswaterstaat.release.tactile.events',
              name: { full: 'Rijkswaterstaat' },
            })
          }
        >
          RWS
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://top.release.tactile.events',
              domain: 'top.release.tactile.events',
              name: { full: 'TOP-Week' },
            })
          }
        >
          TOP
        </AccentButton>

        <AccentButton
          style={{ marginRight: 4, marginBottom: 4 }}
          onPress={() =>
            attemptLogin(undefined, {
              domainFull: 'https://uit.release.tactile.events',
              domain: 'uit.release.tactile.events',
              name: { full: 'Utrechtsche Introductie Tijd' },
            })
          }
        >
          UIT
        </AccentButton>
      </View>
    </Fragment>
  );
}

function LoginOptions({
  options,
  doLogin: attemptLogin,
}: {
  options: readonly SelectCompany[];
  doLogin: (_?: unknown, option?: SelectCompany) => void;
}) {
  return (
    <Fragment>
      {options
        .filter(
          (option, index, self) =>
            self.findIndex((o) => o.domain === option.domain) === index &&
            option.domain &&
            option.domainFull !== 'https://app.tactile.events'
        )
        .sort((a, b) =>
          (a.name.full || '')
            .trim()
            .toLocaleUpperCase()
            .localeCompare((b.name.full || '').trim().toLocaleUpperCase())
        )
        .map((option) => (
          <LoginOption
            key={option.domain}
            option={option}
            doSelect={() => attemptLogin(undefined, option)}
          />
        ))}
    </Fragment>
  );
}

function LoginOption({
  option,
  doSelect,
}: {
  option: SelectCompany;
  doSelect: () => void;
}) {
  const actualDomain =
    option.domainFull === 'https://app.tactile.events'
      ? 'https://api.tactile.events'
      : option.domainFull;
  const { data } = useRemoteCompany(actualDomain);
  const logo = data?.image?.profile
    ? [actualDomain, 'api', 'image', data?.image?.profile, 'icon_64'].join('/')
    : null;

  if (!actualDomain) {
    return null;
  }

  return (
    <List.Item
      key={option.domain}
      left={(props) =>
        logo ? (
          <Avatar.Image
            source={{ uri: logo, width: 64, height: 64 }}
            size={36}
            {...props}
            style={{ backgroundColor: 'transparent' }}
          />
        ) : (
          <Avatar.Text
            label={option.name.full[0]}
            size={36}
            {...props}
            style={{ backgroundColor: '#f6f6f6' }}
          />
        )
      }
      title={option.name.full || '<Nameless>'}
      onPress={doSelect}
    />
  );
}

function Wrapper({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  if (Platform.OS !== 'web') {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <form
      action="#"
      onSubmit={(e) => e.preventDefault()}
      style={{ maxHeight: '100vh' }}
    >
      {children}
    </form>
  );
}

function People() {
  // Use the dimensions to position the element form the top of the screen so
  // that the content won't shift when the keyboard is activated.
  const dimensions = Dimensions.get(
    Platform.OS === 'web' ? 'window' : 'screen'
  );
  const theme = useTheme();

  const darkColor = '#2f2e41';
  const lightFaceColor = '#ffb8b8';
  const primaryColor = theme.colors.primary;
  const backgroundColor = theme.dark ? '#3e3e3e' : '#f2f2f2';
  const shirtColor = theme.dark ? '#918f9d' : '#d0cde1';

  return (
    <Svg
      style={[
        styles.decoration,
        {
          top:
            dimensions.height - 330 + Platform.select({ web: 0, default: 24 }),
        },
      ]}
      width="533"
      height="412"
      viewBox="0 0 1066.106 824.2"
    >
      <Path
        fill={backgroundColor}
        d="M962.551 394.71l-15.879 71.42-12.89 57.94-10.274 46.21-12.882 57.94-42.095 189.29-521.617-241.03-38.342-17.72-48.533-22.43-38.356-17.72-46.132-21.32 26.153-38.05 21.748-31.64 27.513-40.03 21.742-31.62L517.369 0 776.55 229.8l46.153 40.92 36.802 32.63 46.161 40.92 56.885 50.44z"
      />
      <Path
        d="M204.354 604.831c-34.88-19.444-56.594-45.991-64.54-78.902-5.899-24.436-4.204-51.933 5.039-81.728a218.888 218.888 0 0130.439-61.484c70.638-99.986 77.013-165.138 69.923-202.187-7.774-40.623-33.031-57.494-33.286-57.66l1.09-1.676c.261.17 26.184 17.433 34.145 58.88 4.59 23.894 2.43 51.14-6.417 80.98-11.012 37.139-32.485 78.46-63.822 122.817a216.904 216.904 0 00-30.162 60.922c-15.158 48.867-17.303 115.997 58.564 158.292z"
        fill={darkColor}
      />
      <Path
        d="M221.127 604.831c34.88-19.444 56.594-45.991 64.54-78.902 5.898-24.436 4.203-51.933-5.039-81.728a218.888 218.888 0 00-30.439-61.484c-70.638-99.986-77.014-165.138-69.923-202.187 7.774-40.623 33.03-57.494 33.285-57.66l-1.09-1.676c-.26.17-26.184 17.433-34.145 58.88-4.589 23.894-2.43 51.14 6.418 80.98 11.012 37.139 32.484 78.46 63.821 122.817a216.904 216.904 0 0130.163 60.922c15.158 48.867 17.303 115.997-58.565 158.292zM981.354 520.831c-34.88-19.444-56.594-45.991-64.54-78.902-5.899-24.436-4.204-51.933 5.039-81.728a218.888 218.888 0 0130.439-61.484c70.638-99.986 77.013-165.138 69.923-202.187-7.774-40.623-33.031-57.494-33.286-57.66l1.09-1.676c.261.17 26.184 17.433 34.145 58.88 4.59 23.894 2.43 51.14-6.417 80.98-11.012 37.139-32.485 78.46-63.822 122.817a216.904 216.904 0 00-30.162 60.922c-15.158 48.867-17.303 115.997 58.564 158.292z"
        fill={darkColor}
      />
      <Path
        d="M998.127 520.831c34.88-19.444 56.594-45.991 64.54-78.902 5.898-24.436 4.203-51.933-5.039-81.728a218.888 218.888 0 00-30.439-61.484c-70.638-99.986-77.014-165.138-69.923-202.187 7.774-40.623 33.03-57.494 33.285-57.66l-1.09-1.676c-.26.17-26.184 17.433-34.145 58.88-4.589 23.894-2.43 51.14 6.418 80.98 11.012 37.139 32.484 78.46 63.821 122.817a216.904 216.904 0 0130.163 60.922c15.158 48.867 17.303 115.997-58.565 158.292zM467.815 299.945c-26.207-44.282-78.055-46.346-78.055-46.346s-50.523-6.46-82.934 60.98c-30.209 62.862-71.9 123.555-6.712 138.27L311.89 416.2l7.293 39.378a255.068 255.068 0 0027.892.477c69.812-2.254 136.297.659 134.157-24.393-2.846-33.302 11.8-89.11-13.416-131.717z"
        fill={darkColor}
      />
      <Path
        d="M360.247 392.622c9.03 19.975 14.725 43.786 18.125 70.234l72.5-4.531c-16.669-23.62-22.203-50.729-15.86-81.562z"
        fill={lightFaceColor}
      />
      <Path
        d="M360.247 392.622c9.03 19.975 14.725 43.786 18.125 70.234l72.5-4.531c-16.669-23.62-22.203-50.729-15.86-81.562z"
        opacity=".2"
      />
      <Path
        d="M23.872 772.944S-13.65 793.042 5.39 810.202s44.193-22.714 44.193-22.714z"
        fill={lightFaceColor}
      />
      <Path
        fill={darkColor}
        d="M482.591 714.334l-.586 27.458-1.251 59.33v.01l-.364 17.182H240.438l9.752-54.151.01-.04 8.732-48.467 3.897-21.705v-.01l197.108-27.186 22.654 47.579z"
      />
      <Path
        d="M482.59 714.337l-.59 27.46c-69.735-1.79-177.826-18.624-223.07-26.146l3.896-21.704 197.107-27.188z"
        fill="#3f3d56"
      />
      <Circle cx="395.364" cy="355.24" r="56.64" fill={lightFaceColor} />
      <Path
        d="M504.113 744.923s6.796 61.17-33.984 56.64l-6.797-61.172-36.25-36.25-101.952-2.265-74.764 70.234s-2.266-72.5 6.796-79.296 29.453-22.656 29.453-22.656V658.83l-61.17-124.608s36.249-74.765 65.701-77.03l38.516-2.266s13.593-6.797 13.593-9.062-2.265-20.39 9.063-15.86c0 0 1.019-23.245 12.959-16.153 0 0 37.586 29.453 75.739 1.291 0 0 17.785-3.262 15.519 5.8s0 15.86 4.531 15.86 24.922 6.796 24.922 11.327c0 .975 1.473 10.354 3.783 24.469v.022c8.406 51.452 27.935 165.82 27.935 165.82s20.39 29.452-13.593 106.483z"
        fill={primaryColor}
      />
      <Path
        d="M241.303 522.894l-15.859 11.328-144.998 185.78-77.03 61.17s43.046-6.797 33.983 29.453L302.474 658.83z"
        fill={primaryColor}
      />
      <Path
        d="M489.771 472.598v.022c-3.013 17.717-101.204 14.024-114.797 14.024s-24.922-2.265-38.516-11.328-6.796-20.39-6.796-20.39 11.574-8.816 11.574-11.081-2.265-20.39 9.063-15.86c0 0 1.019-23.245 12.959-16.153 0 0 39.605 31.472 77.758 3.31 0 0 17.785-3.262 15.519 5.8s0 15.86 4.531 15.86 24.922 6.796 24.922 11.327c0 .975 1.473 10.354 3.783 24.469z"
        fill="#3f3d56"
      />
      <Path
        d="M478.058 388.091s-18.125-38.515-36.25-20.39 20.391 45.312 20.391 45.312z"
        fill={lightFaceColor}
      />
      <Path
        d="M478.058 379.029s-27.987 35.37-36.65 38.075c0 0 25.322 100.126 57.04 127.313l18.125 92.89s77.03-31.719 58.906-83.827l-.541-1.554a513.985 513.985 0 00-62.099-122.409z"
        fill={primaryColor}
      />
      <Path
        opacity=".2"
        d="M463.102 483.933l55.737 153.374-20.39-92.89-35.347-60.484z"
      />
      <Path
        fill={darkColor}
        d="M456.229 295.676l-51.224-26.831-70.737 10.976-14.636 64.639 36.432-1.401 10.178-23.748v23.357l16.81-.647 9.757-37.808 6.098 40.247 59.761-1.219-2.439-47.565z"
      />
      <Path
        d="M940.129 644.338l29.062 103.67s-5.989 91.828 21.959 73.862c28.6-18.386 17.966-95.82 17.966-95.82l-16.752-93.007z"
        fill="#a0616a"
      />
      <Path
        d="M955.658 353.515l24 24v114.352s14.118 21.177 11.294 26.824-5.647 5.647-2.823 9.882 5.647 7.059 2.823 9.882-2.823 9.883 0 12.706 8.47 7.06 4.235 11.294-9.882 1.412-4.235 16.942 15.53 19.764 12.706 25.411-2.823 19.765-2.823 19.765l-60.706 8.47-12.706-159.529z"
        fill={shirtColor}
      />
      <Path
        fill="#3f3d56"
        d="M1003.658 623.161l-70.588 2.824 2.823 29.647 62.118-11.294 5.647-21.177z"
      />
      <Path
        fill="#a0616a"
        d="M809.541 205.986l-8.471 84.705 86.118 12.706 6.353-48.705 6.353-48.706h-90.353z"
      />
      <Path
        opacity=".1"
        d="M809.541 205.527l-8.471 84.706 86.118 12.706 6.353-48.706 6.353-48.706h-90.353z"
      />
      <Path
        d="M622.483 609.044l48 103.058s8.47 48 21.176 49.412 25.412-49.412 25.412-49.412l-16.942-26.823-19.764-76.235z"
        fill="#a0616a"
      />
      <Path
        d="M951.056 819.314H679.687c2.15-34.01 3.503-54.979 3.503-54.979l3.604-5.673 17.475-27.459 12.478-19.605 4.35-6.844 1.616-2.534 17.091-3.12 176.323-32.173a215.426 215.426 0 0012.326 38.11 154.098 154.098 0 003.876 8.176c4.432 8.652 9.843 16.85 16.274 22.886 1.464 1.373 2.524 5.038 3.21 10.67 1.696 13.801 1.211 39.463-.757 72.545z"
        fill={darkColor}
      />
      <Circle cx="856.835" cy="165.751" r="67.765" fill="#a0616a" />
      <Path
        d="M705.776 304.103l-9.882-5.647s-43.764 29.647-57.882 96-24 83.294-24 83.294-14.118 2.823-4.235 12.706 9.882 8.47 1.411 14.117-14.117-5.647-8.47 5.647 12.706 8.47 7.059 15.53-15.53 7.058-9.883 12.705-5.647 18.353 1.412 24 9.882 7.06 9.882 16.942a49.993 49.993 0 002.824 16.94h73.412s-14.118-26.823-9.883-32.47 5.647-11.294 2.824-14.117-11.294 2.823-2.824-4.236 11.294-2.823 8.47-11.294-8.47-4.235-2.823-8.47 9.883-2.824 7.06-7.06-8.471-4.234-2.824-7.058 9.882 0 8.47-7.059-2.823-4.235 0-7.059 32.47-98.823 32.47-101.646-22.588-91.765-22.588-91.765z"
        fill={shirtColor}
      />
      <Path
        fill="#3f3d56"
        d="M691.659 590.691l-88.941 5.647 22.588 21.176 62.118 1.412 4.235-28.235zM717.07 696.573l-18.352 33.882s55.058 7.059 74.823 14.118 70.588 16.94 70.588 16.94l12.706-49.41zM878.011 712.102l15.53 46.588 62.117-12.706-7.059-43.764-70.588 9.882z"
      />
      <Path
        d="M903.423 256.103s-4.235-2.823-14.118 2.824-42.352 8.47-57.882-8.47-26.823-31.06-31.059-28.236-7.058 35.294-7.058 35.294l-14.118 11.294-83.294 29.647 7.059 190.588 12.706 43.764s-9.883 33.883 2.823 53.647-7.058 26.824-7.058 26.824-4.236 5.647-1.412 19.764-2.824 66.353-2.824 66.353 120 53.647 245.647 7.06l-14.118-53.648-7.059-120s7.059-19.764 5.647-28.235 42.353-127.058 42.353-127.058-7.059-59.294-43.764-72-42.353-25.412-42.353-25.412 16.94-18.353 9.882-24z"
        fill={shirtColor}
      />
      <Path
        d="M788.6 174.431s-7.037-10.555-4.33-21.923a66.444 66.444 0 001.895-19.757s5.683-18.405 11.367-28.69 2.977-14.615 20.84-17.863 10.556-26.524 57.65-1.353a13.811 13.811 0 0014.344 5.413c9.744-1.894 13.262 11.638 13.262 11.638s6.496-3.789 8.932 1.624 19.804-3.249 19.804 33.29-19.765 69.176-19.765 69.176 1.45-61.462-30.758-67.687-66.852-24.359-75.242-1.083-17.999 37.215-17.999 37.215z"
        fill={darkColor}
      />
      <Ellipse cx="913.305" cy="180.574" rx="6.353" ry="8.471" fill="#a0616a" />
    </Svg>
  );
}

const styles = StyleSheet.create({
  form: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: 30,
    width: '100%',
    position: 'relative',
  },
  decoration: {
    position: 'absolute',
    right: 0,
  },
  input: {
    marginBottom: 12,
    maxWidth: '100%',
  },
  submit: {
    marginBottom: 12,
    maxWidth: '100%',
  },
});

function SelectPreviousSession() {
  const { resolve, available, login, authentication } = useAuthentication();
  const theme = useTheme();

  if (!MULTI_COMPANY_ENABLED) {
    return null;
  }

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
        }}
      >
        {available.map((key) => {
          return (
            <ResolveAvailableSessionItem
              key={key}
              resolveKey={permitKey(key)}
              resolve={resolve}
              login={login}
              current={
                permitKey(authentication?.domainFull || '') === permitKey(key)
              }
            />
          );
        })}
      </Surface>
    </View>
  );
}

function ResolveAvailableSessionItem({
  resolveKey,
  resolve,
  login,
  current,
}: {
  resolveKey: string;
  resolve(key: string): Promise<Permit | null>;
  login(permit: Permit): Promise<unknown>;
  current: boolean;
}) {
  const [resolved, setResolved] = useState<Permit | undefined | null>();

  useEffect(() => {
    let mounted = true;
    resolve(resolveKey).then((resolved) => {
      if (!mounted) {
        return;
      }

      setResolved(resolved);
    });

    return () => {
      mounted = false;
    };
  }, [resolveKey]);

  if (!resolved) {
    return null;
  }

  return (
    <AuthenticationPortal key={resolveKey} permit={resolved}>
      <ActiveSessionItem
        key={resolveKey}
        {...resolved}
        current={current}
        set={() => login(resolved)}
      />
    </AuthenticationPortal>
  );
}

function ActiveSessionItem({
  current,
  set,
  ...permit
}: { current: boolean; set(): void } & Permit) {
  const { data: company, isLoading } = useRemoteCompany(permit.domainFull);
  const { data: user } = useUser();

  const initials = (company?.name.full?.split(' ') || [])
    .slice(0, 2)
    .map((l) => (l || '')[0])
    .join('');

  const logo = company?.image?.profile
    ? [
        permit.domainFull,
        'api',
        'image',
        company?.image?.profile,
        'icon_64',
      ].join('/')
    : null;

  const {
    colors: { primary },
  } = useTheme();
  const textColor = useHasContrastOnLight(primary) ? '#fff' : '#121212';

  const renderIllustration = useCallback(
    () =>
      logo ? (
        <Avatar.Image
          size={40}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 8,
            marginBottom: 4,
            backgroundColor: 'transparent',
          }}
          source={{ uri: logo, width: 40, height: 40 }}
        />
      ) : (
        <Avatar.Text
          color={textColor}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 8,
            marginBottom: 4,
          }}
          size={40}
          label={initials}
        />
      ),
    [logo, initials, textColor]
  );

  return (
    <List.Item
      title={company?.name.full}
      description={user?.email.value}
      onPress={current ? undefined : set}
      left={
        isLoading
          ? () => (
              <ActivityIndicator
                style={{
                  marginLeft: 8,
                  marginRight: 16,
                  marginTop: 8,
                  marginBottom: 4,
                  width: 40,
                  height: 40,
                }}
              />
            )
          : renderIllustration
      }
      right={current ? () => <List.Icon icon="check" /> : undefined}
    />
  );
}
