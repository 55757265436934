import { useMemo } from 'react';

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24 - 1;
const DAY_ENDS_AT = 4;

export function useDayBoundaries(day: Date | number | string) {
  const startDayId = useMemo(() => getMidDayId(day), [day]);
  return useMemo(() => getDayUnixBoundaries(startDayId), [startDayId]);
}

export function getMidDayId(day: Date | number | string): string {
  const midDay = new Date(
    typeof day === 'string' || typeof day === 'number' ? day : day.getTime()
  );

  midDay.setHours(12);
  midDay.setMinutes(0);
  midDay.setSeconds(0);
  midDay.setMilliseconds(0);

  return midDay.toISOString();
}

export function getDayUnixBoundaries(dayId: string): [number, number] {
  const dateBoundaryStart = new Date(dayId);
  dateBoundaryStart.setHours(DAY_ENDS_AT);
  dateBoundaryStart.setMinutes(0);
  dateBoundaryStart.setSeconds(0);
  dateBoundaryStart.setMilliseconds(0);

  const dayBoundaryStart = dateBoundaryStart.getTime();
  const dayBoundaryEnd = dayBoundaryStart + DAY_IN_MILLISECONDS;

  return [dayBoundaryStart, dayBoundaryEnd];
}
