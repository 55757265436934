import {
  OrientationChangeEvent,
  removeOrientationChangeListener,
} from 'expo-screen-orientation';
import React from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import { PlayerContext, PlayerVideo } from './PlayerContext';
import { VideoModal } from './VideoModal';

type PlayerProviderProps = {
  children: React.ReactNode;
  primary: string;
};

type PlayerProviderState = {
  video: PlayerVideo | undefined;
  height: number | null;
};

export class ProvideLiveStream extends React.PureComponent<
  PlayerProviderProps,
  PlayerProviderState
> {
  constructor(props: PlayerProviderProps) {
    super(props);
    this.state = {
      video: undefined,
      height: null,
    };
  }

  onDimensionsMightHaveChanged!: (event: OrientationChangeEvent) => void;
  orientationListener!: { remove: () => void };

  componentDidMount() {
    // Dimensions.addEventListener('change', this.onDimensionsChanged);

    if (Platform.OS !== 'web') {
      return;

      // TODO: maybe remove?
      // this.orientationListener = addOrientationChangeListener(
      // this.onDimensionsMightHaveChanged
      //);
    }
  }

  componentWillUnmount() {
    if (this.orientationListener) {
      removeOrientationChangeListener(this.orientationListener);
    }
  }

  onLaidOut = (event: LayoutChangeEvent) => {
    if (this.state.height === null) {
      this.setState({ height: event.nativeEvent.layout.height });
    }
  };

  setVideo = (video: PlayerVideo | undefined) => {
    this.setState({ video });
  };

  render() {
    const { setVideo } = this;
    const { children, primary } = this.props;
    const { video, height } = this.state;

    return (
      <PlayerContext.Provider value={{ video, setVideo }}>
        <View style={styles.container} onLayout={this.onLaidOut}>
          <View style={StyleSheet.absoluteFill}>{children}</View>
          {video && (
            <VideoModal
              video={video}
              primary={primary}
              withoutKeyboardHeight={height || Dimensions.get('screen').height}
            />
          )}
        </View>
      </PlayerContext.Provider>
    );
  }
}

const ENABLED_TRANSLATION = false; // Platform.OS === 'ios';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: '100%',
    width: '100%',
    maxHeight: Platform.select({ web: '100vh', default: '100%' }),
    overflow: 'hidden',
  },
});
