import { PrimaryButton } from '@introcloud/blocks';
import { useWindowWidth } from '@introcloud/blocks-interface';
import { AllHtmlEntities } from 'html-entities';
import React from 'react';
import { Image } from 'react-native';
import { Card, Paragraph, Title } from 'react-native-paper';
import { GelegraafItem } from './useGelegraaf';
import { useSafe } from './useSafeHtmlContent';

const entities = new AllHtmlEntities();

export interface ImportantHeadlineProps {
  article: GelegraafItem;
  onGotoArticle(): void;
}

export function ImportantHeadline({
  article,
  onGotoArticle,
}: ImportantHeadlineProps) {
  const { title, featured_media, content } = article;
  const image =
    (featured_media &&
      featured_media.media_details &&
      featured_media.media_details.sizes &&
      featured_media.media_details.sizes['blog-full'] &&
      featured_media.media_details.sizes['blog-full'].source_url) ||
    null;

  const safeDescription = useSafe((content || { rendered: '' }).rendered);
  const width = Math.min(720, useWindowWidth());
  const height = (width / 16) * 10;
  return (
    <Card onPress={onGotoArticle}>
      {image && (
        <Image
          source={{ uri: image, width: 1140, height: 500 }}
          style={{ width, height }}
          resizeMode="cover"
        />
      )}
      <Card.Content>
        <Title style={{ paddingTop: 16 }}>
          {entities.decode((title || { rendered: '' }).rendered)}
        </Title>
        <Paragraph>
          {safeDescription.split(' ').slice(0, 30).join(' ') + '...'}
        </Paragraph>
      </Card.Content>
      <Card.Actions>
        <PrimaryButton onPress={onGotoArticle}>Read more...</PrimaryButton>
      </Card.Actions>
    </Card>
  );
}
