import React from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';

import { PlayerContext } from './PlayerContext';

type PlayerControlsProps = {
  title: string;
  onPress: () => void;
};

export default class PlayerControls extends React.PureComponent<PlayerControlsProps> {
  render() {
    const { title, onPress } = this.props;
    return (
      <View style={styles.container}>
        <Text style={styles.title} numberOfLines={1}>
          {title}
        </Text>
        <IconButton onPress={onPress} icon="play" />
        <PlayerContext.Consumer>
          {({ setVideo }) => (
            <IconButton onPress={() => setVideo(undefined)} icon="close" />
          )}
        </PlayerContext.Consumer>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    flexWrap: 'wrap',
    paddingLeft: 8,
    fontWeight: 'bold',
  },
});
