import {
  eventHasInterview,
  TactileInterviewAnswer,
  TactileInterviewQuestion,
  TactileModuleEventInterview,
} from '@introcloud/api-client';
import { t } from 'i18n-js';
import cloneDeep from 'lodash.clonedeep';
import React, { useMemo, useRef, useState } from 'react';
import { Keyboard, View } from 'react-native';
import {
  Button,
  HelperText,
  List,
  Switch,
  TextInput,
} from 'react-native-paper';
import { PreparedEvent } from '../hooks/useEvents';
import { useSwipeInterview } from '../hooks/useSwipeInterview';
import {
  useSubmitSwipeInterview,
  useSwipeInterviewGuest,
} from '../hooks/useSwipeInterviewGuest';

function useProfileMatchingData() {
  const event = useSwipeInterview();

  const questions = useSwipeInterviewToggles(event);
  const { data: answers, loading } = useSwipeInterviewAnswers(event);
  const { mutateAsync, isLoading: mutating } = useSubmitSwipeInterview(
    event?._id ?? ''
  );

  if (!event || !questions || !answers) {
    return {
      data: undefined,
      loading,
    };
  }

  return {
    data: {
      event,
      questions,
      answers,
      bio: answers.find(
        (value) =>
          value.questionId ===
          (event.module.interview as TactileModuleEventInterview).question[0]
            ._id
      ),
    },
    loading: loading || mutating,
    change: mutateAsync,
  };
}

function useSwipeInterviewToggles(event: PreparedEvent | undefined) {
  return useMemo(() => {
    if (!eventHasMatchingInterview(event)) {
      return [];
    }

    return event.module.interview.question.filter((q) => q.type === 'toggle');
  }, [event]);
}

function useSwipeInterviewAnswers(event: PreparedEvent | undefined) {
  const isReady = eventHasMatchingInterview(event);

  const { data: guest, loading } = useSwipeInterviewGuest(event?._id ?? '', {
    enabled: isReady,
  });

  return useMemo(() => {
    if (!isReady || !guest) {
      return { loading };
    }

    return { data: guest.module.interview.answer, loading };
  }, [guest, isReady, loading]);
}

function eventHasMatchingInterview(
  event: PreparedEvent | undefined
): event is PreparedEvent & {
  module: {
    interview: TactileModuleEventInterview;
  };
} {
  return Boolean(
    event &&
      eventHasInterview(event) &&
      event.module.interview.question.length > 0 &&
      event.module.interview.question[0].type === 'textarea'
  );
}

export function ProfileMatching() {
  const { data, loading, change } = useProfileMatchingData();

  if (!data) {
    return null;
  }

  return (
    <View style={{ marginVertical: 16 }}>
      <List.Subheader>{t('app.profile.matching.title')}</List.Subheader>

      <MatchingBioInput
        defaultValue={String(data.bio?.value || '')}
        disabled={Boolean(loading)}
        onChange={
          change && data.bio
            ? (next) => {
                const result = cloneDeep(data.answers || []) as {
                  questionId: string;
                  value: boolean | string;
                }[];
                const copy = result.find(
                  (answer) => answer.questionId === data.bio!.questionId
                );

                if (copy) {
                  copy.value = next;
                } else {
                  result.push({
                    questionId: data.bio!.questionId,
                    value: next,
                  });
                }

                change(result);
              }
            : undefined
        }
      />

      {data.questions.map((question) => {
        const answer = data.answers.find(
          (answer) => answer.questionId === question._id
        );

        return (
          <MatchingPreference
            key={question._id}
            question={question}
            answer={data.answers.find(
              (answer) => answer.questionId === question._id
            )}
            disabled={Boolean(loading)}
            onChange={
              change
                ? (next) => {
                    const result = cloneDeep(data.answers || []) as {
                      questionId: string;
                      value: boolean;
                    }[];
                    const copy = result.find(
                      (answer) => answer.questionId === question._id
                    );

                    if (copy) {
                      copy.value = next;
                    } else {
                      result.push({ questionId: question._id, value: next });
                    }

                    change(result);
                  }
                : undefined
            }
          />
        );
      })}
    </View>
  );
}

function MatchingBioInput({
  defaultValue,
  onChange,
  disabled,
}: {
  defaultValue: string;
  disabled: boolean;
  onChange: undefined | ((next: string) => void);
}) {
  const [chosenText, setChosenText] = useState(defaultValue);

  return (
    <View style={{ paddingHorizontal: 6 }}>
      <TextInput
        multiline
        label="About me"
        style={{ height: 150 }}
        value={chosenText}
        onChangeText={setChosenText}
        maxLength={200}
        returnKeyType="done"
        disabled={disabled}
        onSubmitEditing={
          onChange
            ? () => {
                Keyboard.dismiss();
                onChange(chosenText);
              }
            : undefined
        }
      />
      <HelperText style={{ textAlign: 'right' }} type="info">
        {chosenText.length} / 200
      </HelperText>

      {chosenText !== defaultValue && onChange ? (
        <Button
          style={{ marginRight: 'auto', marginLeft: 10, marginBottom: 12 }}
          mode="contained"
          disabled={disabled}
          onPress={() => {
            Keyboard.dismiss();
            onChange(chosenText);
          }}
        >
          {t('app.actions.change_about')}
        </Button>
      ) : null}
    </View>
  );
}

function MatchingPreference({
  question,
  answer,
  disabled,
  onChange,
}: {
  question: TactileInterviewQuestion;
  answer: TactileInterviewAnswer | undefined;
  disabled: boolean;
  onChange: undefined | ((next: boolean) => void);
}) {
  const showValue = useRef(Boolean(answer?.value));

  return (
    <List.Item
      disabled={disabled || !onChange}
      title={question.description}
      descriptionStyle={{ marginTop: 4 }}
      description={question.options.helpText || question.options.subquestion}
      right={() => (
        <Switch
          style={{ marginRight: 6, marginTop: 16 }}
          disabled={disabled || !onChange}
          value={showValue.current}
          onValueChange={() => {
            showValue.current = !showValue.current;
            onChange!(showValue.current);
          }}
        />
      )}
    />
  );
}
