import { TextButton } from '@introcloud/blocks';
import { StackActionType, useLinkProps } from '@react-navigation/native';
import React from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';

export function LinkButton({
  to,
  action,
  children,
  style,
  Button = TextButton,
  ...rest
}: React.PropsWithChildren<{
  to: string;
  action?: StackActionType;
  style?: StyleProp<ViewStyle>;
  Button?: React.ComponentType<any>;
}>) {
  const { onPress, ...props } = useLinkProps({ to, action });

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View style={style} {...{ onClick: onPress }} {...props} {...rest}>
        <Button onPress={onPress}>{children}</Button>
      </View>
    );
  }

  return (
    <Button onPress={onPress as any} style={style} {...props} {...rest}>
      {children}
    </Button>
  );
}
