import {
  CURRENT_LOCALE,
  useTranslationAction,
} from '@introcloud/blocks/dist/TextBlock';
import color from 'color';
import * as SplashScreen from 'expo-splash-screen';
import { useMemoryValue } from 'expo-use-memory-value';
import React, { useReducer } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { Provider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { AuthenticatedApp } from './core/AuthenticatedApp';
import { Connectivity } from './core/Connectivity';
import { OtaUpdater } from './core/OtaUpdater';
import { QueryCacheProvider, queryClient } from './core/QueryCache';
import {
  AuthenticationProvider,
  runOnLogout,
  useAuthenticateEmail,
  useAuthentication,
  useCurrentDomain,
  useEndpoint,
  useSafeAuthorization,
} from './hooks/useAuthentication';
import {
  useCachedResources,
  useSplashResources,
} from './hooks/useCachedResources';
import { useCompany } from './hooks/useCompany';
import { LoginScreen } from './login';
import { SplashOrganisation } from './splash/Splasher';

runOnLogout(() => {
  queryClient.clear();
});

let hiddenSplash = false;

export function Root() {
  const [isBlockingSplashPointerEvents, markBlockPointerEvents] = useReducer(
    () => true,
    false
  );
  const [isSplashReady, markReady] = useReducer(() => true, false);
  const [isSplashDone, markDone] = useReducer(() => true, false);

  const canSplashShow = useSplashResources();
  const isLoadingComplete = useCachedResources();

  useMemoryValue(CURRENT_LOCALE);
  useTranslationAction();

  if (!isLoadingComplete && !canSplashShow) {
    return null;
  }

  return (
    <AuthenticationProvider>
      <QueryCacheProvider>
        <View style={{ position: 'relative', flex: 1 }}>
          {isLoadingComplete && isSplashReady ? <App /> : null}
          {!isSplashDone && canSplashShow && (
            <View
              pointerEvents={isBlockingSplashPointerEvents ? 'none' : 'auto'}
              style={StyleSheet.absoluteFillObject}
            >
              <SplashOrganisation
                onReady={markReady}
                onDone={markDone}
                onAlmostDone={markBlockPointerEvents}
                onLoaded={() => {
                  if (hiddenSplash) {
                    return;
                  }

                  SplashScreen.hideAsync().then(
                    () => (hiddenSplash = true),
                    () => (hiddenSplash = true)
                  );
                }}
              />
            </View>
          )}
        </View>
      </QueryCacheProvider>
    </AuthenticationProvider>
  );
}

function App() {
  return (
    <SafeAreaProvider>
      <PreloadedApp />
    </SafeAreaProvider>
  );
}

function PreloadedApp() {
  const company = useCompany();
  // const domain = useCurrentDomain();
  // const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  // const authentication = useAuthentication();

  if (!company) {
    return null;
  }

  const { dark, light, default: chosen } = company.application.themes;

  // TODO switching logic
  const theme = chosen === 'dark' ? dark : light;

  // console.log(
  //  `company is now ${company.name.full}, theme primary: ${theme.colors.primary} // ${authentication.authentication?.domainFull} // ${domain} // ${endpoint}`
  // );

  const primaryIsDark = color(theme.colors.primary).isDark();
  return (
    <SafeAreaProvider>
      <Provider
        theme={theme}
        key={authorization ? 'authorized' : 'not-authorized'}
      >
        <StatusBar
          barStyle={
            primaryIsDark || (theme.dark && theme.mode !== 'exact')
              ? 'light-content'
              : 'dark-content'
          }
          translucent
          backgroundColor="#00000000"
        />
        {authorization ? <AuthenticatedApp /> : <LoginScreen />}
        <Connectivity />
        {Platform.OS !== 'web' ? <OtaUpdater /> : null}
      </Provider>
    </SafeAreaProvider>
  );
}
