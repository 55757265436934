import {
  fetchApplicationPage,
  TactileGroup,
  TactileGroupUser,
  TactilePage,
} from '@introcloud/api-client';
import { Spacer, TextBlock } from '@introcloud/blocks';
import { useBlockNavigation } from '@introcloud/blocks-interface';
import {
  ContactBlock,
  ContactBlockOptions,
  PageDataProvider,
  VideoStreamBlock,
  VideoStreamBlockOptions,
} from '@introcloud/page';
import React, { Fragment, useCallback, useMemo } from 'react';
import { Caption, Card, IconButton } from 'react-native-paper';
import { WithBlockNavigation } from '../core/BlockProvision';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { ChatMembership } from '../hooks/useChats';
import { useGroups } from '../hooks/useGroup';
import { usePage } from '../hooks/usePage';
import { useUser } from '../hooks/useUser';
import { GroupUser } from '../profile/Group';
import { SHOULD_DEBUG_FETCH } from '../utils';

export function ChatContext({
  kind,
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const Context = getContextComponent(kind as any);
  return <Context kind={kind} refs={refs} />;
}

function getContextComponent(
  kind:
    | 'page'
    | 'group'
    | 'page-group'
    | 'page-1-on-1'
    | '1-on-1'
    | 'self'
    | undefined
): React.ComponentType<{
  kind: string;
  refs: ChatMembership['custom']['refs'];
}> {
  switch (kind) {
    case 'page-1-on-1':
    case 'page':
    case 'page-group':
      return PageContext;
    case '1-on-1':
      return UserContext;
    default:
      return () => null;
  }
}

function PageContext({
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const pageRef = useMemo(
    () => refs.find((ref) => ref.model === 'page'),
    [refs]
  );
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  const getInfoById = useCallback(
    (pageId: string) =>
      fetchApplicationPage(
        pageId,
        endpoint,
        authorization || '',
        undefined,
        SHOULD_DEBUG_FETCH
      ),
    [endpoint, authorization]
  );

  const { page } = usePage(pageRef?.id, getInfoById);

  if (!page) {
    return null;
  }

  return (
    <WithBlockNavigation>
      <PageInformation page={page} />
    </WithBlockNavigation>
  );
}

function UserContext({
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const { data: self } = useUser();
  const { groups } = useGroups();

  const userRef = useMemo(
    () =>
      self?._id
        ? refs.find((ref) => ref.model === 'user' && ref.id !== self._id)
        : undefined,
    [self?._id, refs]
  );

  const [group, groupUser] = useMemo(() => {
    if (!userRef?.id || !groups) {
      return [];
    }

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const matchingUser = group.users.find(
        (groupUser) => groupUser._id === userRef.id
      );
      if (matchingUser) {
        return [group, matchingUser];
      }
    }

    return [];
  }, [userRef, groups]);

  if (!groupUser || !group) {
    return null;
  }

  return <GroupUserInformation group={group} user={groupUser} />;
}

function PageInformation({ page }: { page: TactilePage }) {
  const { gotoInfo } = useBlockNavigation();

  const liveStreamBlock = page.content?.find(
    (block) => block.kind === 'live'
  ) as (VideoStreamBlockOptions & { _id: string }) | undefined;

  const contactBlock = page.content?.find(
    (block) => block.kind === 'contact'
  ) as (ContactBlockOptions & { _id: string }) | undefined;

  const hasExtraContext = liveStreamBlock || contactBlock;

  return (
    <Fragment>
      <Card
        style={{
          position: 'relative',
          borderBottomRightRadius: hasExtraContext ? 0 : undefined,
          borderBottomLeftRadius: hasExtraContext ? 0 : undefined,
        }}
        elevation={1}
      >
        <Card.Title
          title={page.name.full}
          subtitle={page.subTitle}
          style={{ marginRight: 52 }}
          titleStyle={{ fontSize: 15 }}
        />
        <IconButton
          style={{ position: 'absolute', right: 0, top: 1 }}
          icon="eye"
          accessibilityLabel="View page"
          onPress={() => gotoInfo(page._id)}
        />
      </Card>
      {page.description ? (
        <TextBlock
          text={page.description}
          round={{ bottom: !hasExtraContext }}
        />
      ) : null}
      <PageDataProvider
        value={{
          page,
          pageEvent: null,
          pageLocation: null,
          currentGroup: null,
          currentUser: null,
        }}
      >
        {contactBlock ? (
          <ContactBlock
            id={contactBlock._id}
            {...contactBlock}
            value={{ ...contactBlock.value, chat: false }}
            next={liveStreamBlock}
            previous={
              page.description
                ? { kind: 'text', value: { text: page.description } }
                : undefined
            }
            options={{ ...contactBlock.options, round: true, spacing: 0 }}
          />
        ) : null}
        {liveStreamBlock ? (
          <VideoStreamBlock
            id={liveStreamBlock._id}
            {...liveStreamBlock}
            previous={
              contactBlock ||
              (page.description
                ? { kind: 'text', value: { text: page.description } }
                : undefined)
            }
            options={{ ...liveStreamBlock.options, round: true, surface: true }}
          />
        ) : null}
      </PageDataProvider>
      <Spacer space={1} />
    </Fragment>
  );
}

function GroupUserInformation({
  group,
  user,
}: {
  group: TactileGroup;
  user: TactileGroupUser;
}) {
  return (
    <Card style={{ marginBottom: 16 }}>
      <Caption style={{ padding: 16, paddingBottom: 4 }}>
        You're chatting with {user.name.first}, one of your group {user.role.id}
        s in {group.name.full}
      </Caption>
      <GroupUser {...user} hideChat />
    </Card>
  );
}
