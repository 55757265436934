import { useTranslationAction } from '@introcloud/blocks';
import { useNavigation } from '@react-navigation/native';
import React, { memo, useCallback } from 'react';
import {
  Platform,
  StatusBar,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Appbar, Avatar, ThemeProvider, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { RouteParamList } from './Routes';

export type ValidRoutes = Exclude<keyof RouteParamList, 'NotFound'>;

type RootRoute<
  T extends ValidRoutes = Exclude<keyof RouteParamList, 'NotFound'>
> = {
  screen: T;
  params: RouteParamList[T];
};

export type FallbackRoute<T extends ValidRoutes> = RootRoute<T>;
export interface HeaderProps<T extends ValidRoutes> {
  title: string | null | undefined;
  subTitle?: string | null | undefined;
  backFallback?: FallbackRoute<T> | null;
  hideProfile?: boolean;
  showTranslate?: boolean;
  hideBack?: boolean;
  colorOverride?: string;
  style?: StyleProp<ViewStyle>;
  image?: string | null;
  children?: React.ReactNode;
}

function Header_<T extends ValidRoutes = ValidRoutes>({
  title,
  subTitle,
  backFallback,
  hideProfile,
  hideBack,
  showTranslate,
  colorOverride,
  style,
  children,
  image,
}: HeaderProps<T>) {
  const theme = useTheme();
  const [translated, translate] = useTranslationAction();
  const { canGoBack, goBack, navigate, replace } = useNavigation<any>();

  const { top } = useSafeAreaInsets();
  const showBack = !hideBack && (canGoBack() || backFallback);

  const onGoBack = useCallback(() => {
    if (canGoBack()) {
      goBack();
      return;
    }

    const b = backFallback!;

    replace(b.screen, b.params);
  }, [canGoBack, goBack, replace, backFallback]);

  const onGotoProfile = useCallback(() => {
    navigate('Profile', undefined);
  }, [navigate]);

  const actualTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colorOverride || theme.colors.primary,
    },
  };

  return (
    <ThemeProvider theme={actualTheme}>
      <Appbar.Header
        style={[styles.header, style]}
        statusBarHeight={Platform.select({
          ios: StatusBar.currentHeight || top || 20,
          default: undefined,
        })}
      >
        {showBack && <Appbar.BackAction onPress={onGoBack} />}
        {image ? (
          <Avatar.Image
            source={{ uri: image, width: 64, height: 64 }}
            size={36}
            style={{ marginLeft: 6, marginRight: -6 }}
          />
        ) : null}
        <Appbar.Content title={title} subtitle={subTitle} />

        {children}

        {showTranslate && (
          <Appbar.Action
            icon={translated ? 'translate-off' : 'translate'}
            onPress={translate}
          />
        )}
        {!hideProfile && (
          <Appbar.Action icon="account-circle" onPress={onGotoProfile} />
        )}
      </Appbar.Header>
    </ThemeProvider>
  );
}

const StableBackAction = memo(Appbar.BackAction);

export function DetachedHeader({
  title,
  subTitle,
  colorOverride,
  style,
}: {
  title: string | null | undefined;
  subTitle?: string | null | undefined;
  colorOverride?: string;
  style?: StyleProp<ViewStyle>;
}) {
  const theme = useTheme();
  const { top } = useSafeAreaInsets();

  const actualTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colorOverride || theme.colors.primary,
    },
  };

  return (
    <ThemeProvider theme={actualTheme}>
      <Appbar.Header
        style={[styles.header, style]}
        statusBarHeight={Platform.select({
          ios: StatusBar.currentHeight || top || 20,
          default: undefined,
        })}
      >
        <Appbar.Content title={title} subtitle={subTitle} />
      </Appbar.Header>
    </ThemeProvider>
  );
}

export const Header = memo(Header_);

const styles = StyleSheet.create({
  header: {
    zIndex: 1,
    maxWidth: Platform.OS !== 'ios' || !Platform.isPad ? 720 : '100%',
    margin: 'auto',
    width: '100%',
  },
});
