import { TactileChatInfo } from '@introcloud/api-client';
import { TactileImageSize, useBlockData } from '@introcloud/blocks-interface';
import { useMemo } from 'react';

export function useChatImage(
  info: TactileChatInfo | undefined,
  size: TactileImageSize = 'icon_32'
) {
  const { getImageUrl } = useBlockData();

  return useMemo(
    () => getImageUrl(info?.image?.profile || '', size),
    [info?.image?.profile]
  );
}
