import { TactileGameMap } from '@introcloud/api-client';
import { useDimensions } from '@introcloud/blocks-interface';
import React, { createContext, useContext, useMemo } from 'react';
import { Platform } from 'react-native';
import { Card } from 'react-native-paper';
import Animated, {
  max,
  min,
  multiply,
  Node,
  sub,
  Value,
} from 'react-native-reanimated';

const ViewPortContext = createContext({
  displayWidth: 1440,
  displayHeight: 1440,

  center: {
    x: new Value(1440 / 2),
    y: new Value(1440 / 2),
  },

  shape: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    ratio: 1,
  },

  ratio: 1,

  transform: [
    {
      translateX: new Animated.Value<number>(0) as Animated.Node<number>,
    },
    {
      translateY: new Animated.Value<number>(0) as Animated.Node<number>,
    },
  ] as Animated.AnimatedTransform,

  displayFromCoords(x: number, y: number) {
    return { top: y, left: x };
  },

  translateCoord(coord: Node<number> | number): Node<number> {
    return multiply(coord, 1);
  },

  displayFromSize(width: number, height: number) {
    return { width, height };
  },
});

export function useViewport() {
  return useContext(ViewPortContext);
}

export type ViewPortProps = React.PropsWithChildren<{
  shape: { width: number; height: number };
  initialCenter: { x: number; y: number };
  gameMap: TactileGameMap;
  MapOverlay?: React.ElementType<{ gameMap: TactileGameMap }>;
}>;

export function ViewPort({
  shape,
  initialCenter,
  children,
  gameMap,
  MapOverlay = React.Fragment,
}: ViewPortProps): JSX.Element {
  const baseSize = 1440;
  const displayRatio = 1;

  const displaySize = baseSize * displayRatio;
  const ratio = (baseSize / Math.min(shape.height, shape.width)) * displayRatio;

  const { width: windowWidth, height: windowHeight } = useDimensions();
  const mapRatio = shape.width / shape.height;

  const cameraPosition = useMemo(
    () => {
      return {
        x: new Value(initialCenter.x),
        y: new Value(initialCenter.y),
      };
    },

    // This memoisation takes care of generating a semi-stable value for the
    // viewport center that can be passed around later. It doesn't matter if the
    // initial values update; as this map is already rendered and the user is
    // already placed.
    //
    [] /* purposefully empty */
  );

  const viewport = useMemo(
    () => ({
      displayHeight: ratio * shape.height,
      displayWidth: ratio * shape.width,

      center: cameraPosition,
      ratio,

      shape: {
        top: 0,
        left: 0,
        right: shape.width - 1,
        bottom: shape.height - 1,
        ratio: mapRatio,
      },

      displayFromCoords(x: number, y: number) {
        return { left: x * ratio, top: y * ratio };
      },

      translateCoord(
        coord: Animated.Node<number> | number
      ): Animated.Node<number> {
        return multiply(coord, ratio);
      },

      displayFromSize(width: number, height: number) {
        return { width: width * ratio, height: height * ratio };
      },
    }),

    // X and Y are not coordinates but relatively stable values. This means that
    // this value will not update when the actual center x and y update, but
    // rather when the wrapper object (Animated.Value) that holds them does.
    [displaySize, ratio, mapRatio, cameraPosition, shape]
  );

  // This is the size of the viewport, aka what can be seen on the screen. The
  // origin (0, 0) is the top-left of the viewport, not the map
  const viewportShape = useMemo(() => {
    const viewportWidth = Math.min(720, windowWidth * 1.0);
    const viewportHeight = Math.min(720, windowHeight * 0.8);

    return {
      width: Math.round(viewportWidth / ratio),
      height: Math.round(viewportHeight / ratio),
    };
  }, [ratio, windowWidth, windowHeight]);

  // These are the coordinates of the map center, with the origin (0,0) in the
  // top-left corner.
  /*
  const mapCenterCoords = useMemo(() => {
    return {
      x: shape.width / 2,
      y: shape.height / 2,
    };
  }, [shape.width, shape.height]);
  */

  // This is the actual transformation of pixels where it centers the
  // coordinates viewport.center in the middle of the viewport itself.
  //
  // The min and max calculations are done so that the map edge is flush with
  // the viewport edge when the viewport.center is at one or two of the edges.
  //
  const transform = useMemo(
    (): Animated.AnimatedTransform => [
      {
        translateX: min(
          0,
          max(
            sub(0, viewport.translateCoord(shape.width - viewportShape.width)),
            sub(
              -ratio / 2,
              viewport.translateCoord(
                sub(viewport.center.x, viewportShape.width / 2)
              )
            )
          )
        ),
      },
      {
        translateY: min(
          0,
          max(
            sub(
              0,
              viewport.translateCoord(shape.height - viewportShape.height)
            ),
            sub(
              -ratio / 2,
              viewport.translateCoord(
                sub(viewport.center.y, viewportShape.height / 2)
              )
            )
          )
        ),
      },
    ],
    [viewport, viewportShape, shape, ratio]
  );

  const value = useMemo(
    () => ({ ...viewport, transform: Platform.OS === 'web' ? [] : transform }),
    [viewport, transform]
  );

  return (
    <ViewPortContext.Provider value={value}>
      <Card
        nativeID="map-viewport"
        style={[
          {
            maxWidth: 720,
            width: windowWidth * 1.0,
            maxHeight: 720,
            height: windowHeight * 0.8,
            overflow: 'hidden',
            backgroundColor: '#222',
          },
          windowWidth <= 736 ? { borderRadius: 0 } : {},
        ]}
      >
        {Platform.OS === 'web' ? (
          <Animated.View style={{ transform, width: '100%', height: '100%' }}>
            {children}
          </Animated.View>
        ) : (
          children
        )}
        <MapOverlay gameMap={gameMap} />
      </Card>
    </ViewPortContext.Provider>
  );
}
