import {
  fetchApplicationSponsors,
  TactileSponsor,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useAbortController } from './useAbortController';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

export const SPONSORS: AnyMemoryValue<
  readonly Omit<TactileSponsor, 'pageRef'>[] | null
> = new StoredMemoryValue<readonly Omit<TactileSponsor, 'pageRef'>[]>(
  'application.sponsors.v1'
);

export const SPONSOR_RANDOMIZER: StoredMemoryValue<Record<
  string,
  number
> | null> = new StoredMemoryValue('application.sponsors.randomizer.v1');

export function useSponsors({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedSponsors, setStoredSponsors] = useMutableMemoryValue(SPONSORS);

  const abortable = useAbortController();

  const fetcher = useCallback(() => {
    if (!authorization || !endpoint) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      const result = await fetchApplicationSponsors(
        endpoint,
        authorization!,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      isMounted.current && setStoredSponsors(result);

      return result;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [authorization, endpoint, setStoredSponsors, abortable]);

  const {
    data: sponsors,
    error,
    ...others
  } = useQuery<readonly TactileSponsor[] | null, FetchMediaError | NotReady>(
    [endpoint, 'application', 'sponsor'],
    fetcher,
    {
      placeholderData: storedSponsors as readonly TactileSponsor[],
      enabled: enabled && isFocused && !!(authorization && endpoint),
      staleTime: 5 * 60 * 1000,
    }
  );

  return {
    data: sponsors,
    error,
    reload: others.refetch,
    loading: others.isLoading,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}

export function useStoredSponsors() {
  return useMemoryValue(SPONSORS);
}
