import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';

import { ChatsScreen } from './ChatsScreen';

mergeTranslations({
  en: {
    app: {
      time: {
        today: 'Today',
        yesterday: 'Yesterday',
      },

      chats: {
        title: 'Chats',
        empty_state:
          "There are various opportunities to talk to others using the chat functionality. Chats with your group, organizations, or associations. They'll show up here when you've participated at least once.",

        kinds: {
          'page-1-on-1': 'Page chat (private)',
          page: 'Page chat (everyone)',
          'page-group': 'Page chat (group)',
          group: 'Group chat',
          '1-on-1': 'Private chat',
          default: 'Chat',
        },

        anonymous: 'Someone',
        placeholder: 'Say something...',
        action_send: 'Send message',
        privacy:
          'To protect your privacy, we only store and retrieve the last 100 messages, per chat room, on your device.',

        resolve: {
          resolving: 'Loading chat...',
          pick_group: 'Pick a group',
          and_others: {
            other: 'and {{count}}} others',
            one: 'and 1 other',
          },
        },

        notification_new: '{{name}} started a conversation',
      },
    },
  },

  nl: {
    app: {
      time: {
        today: 'Vandaag',
        yesterday: 'Gisteren',
      },

      chats: {
        title: 'Chats',
        empty_state:
          'Er zijn verschillende kansen om met anderen te praten via de chat functionaliteit. Chats met je groepje, organisaties, of verenigingen en andere instanties. Je vindt ze hier zodra je hebt deelgenomen aan een gesprek.',

        kinds: {
          'page-1-on-1': 'Pagina chat (privé)',
          page: 'Pagina chat (iedereen)',
          'page-group': 'Pagina chat (groepje)',
          group: 'Groepsgesprek',
          '1-on-1': 'Privégesprek',
          default: 'Chat',
        },

        anonymous: 'Iemand',
        placeholder: 'Vertel iets...',
        action_send: 'Stuur bericht',
        privacy:
          'Om jouw privacy te beschermen slaan we alleen de laatste 100 berichten, per chat gesprek, op.',

        resolve: {
          resolving: 'Gesprek aan het laden...',
          pick_group: 'Kies een groepje',
          and_others: {
            other: 'en {{count}}} anderen',
            one: 'en 1 andere',
          },
        },

        notification_new: '{{name}} wil met je chatten',
      },
    },
  },
});

export function ChatsTab() {
  return <ChatsScreen asTab />;
}
