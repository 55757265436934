import { useMemo } from 'react';

export function useChatMessageTime(timeToken: string): Date;
export function useChatMessageTime(timeToken: undefined): null;
export function useChatMessageTime(timeToken: string | undefined): Date | null;

export function useChatMessageTime(timeToken: string | undefined): Date | null {
  return useMemo(
    () =>
      timeToken
        ? new Date(Number(timeToken.slice(0, timeToken.length - 4)))
        : null,
    [timeToken]
  );
}
