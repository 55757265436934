import {
  fetchApplication,
  TactileCompany,
  TactileCompanyStoreSubmission,
} from '@introcloud/api-client';
import { DEFAULT_TABS } from '@introcloud/tabs';
import Constants from 'expo-constants';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { generateUUID } from 'pubnub';
import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { DarkTheme, DefaultTheme } from 'react-native-paper';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MULTI_COMPANY_ENABLED } from '../features';
import { StoredMemoryValue, useMutableMemoryValue } from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useAbortController } from './useAbortController';
import {
  useAuthentication,
  useCurrentDomain,
  useEndpoint,
} from './useAuthentication';

const manifest = Constants.manifest!;
const extra = manifest.extra || {};

export const COMPANY = new StoredMemoryValue<TactileCompany>(
  'application.company.v3'
);

type Theme = typeof DarkTheme | typeof DefaultTheme;

declare type Mutable<T extends Record<string, unknown>> = {
  -readonly [K in keyof T]: T[K] extends Record<string, any>
    ? Mutable<T[K]>
    : T[K];
};

export type LocalCompany = Mutable<
  Omit<TactileCompany, 'application'> & {
    application: Omit<TactileCompany['application'], 'themes'> & {
      themes: Omit<
        TactileCompany['application']['themes'],
        'light' | 'dark'
      > & {
        light: Theme;
        dark: Theme;
      };
    };
  }
>;

const DEFAULT_COMPANY: LocalCompany = {
  application: {
    splash: {
      backgroundColor: manifest.splash!.backgroundColor!,
      resizeMode: manifest.splash!.resizeMode!,
      imageId: null,
      image: null,
    },
    map: {
      center: {
        latitude: 0,
        longitude: 0,
      },
    },
    store: {} as TactileCompanyStoreSubmission,
    themes: {
      default: 'light',
      allowSwitching: false,
      light: {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          primary: manifest.primaryColor || DefaultTheme.colors.primary,
        },
      },
      dark: {
        ...DarkTheme,
        colors: {
          ...DarkTheme.colors,
          primary: manifest.primaryColor || DarkTheme.colors.primary,
        },
      },
    },
    standalone: {
      ios: { icon: null },
      android: { icon: null },
      adaptive: {
        foregroundImage: null,
        backgroundColor: manifest.android?.adaptive?.backgroundColor || null,
      },
      notification: {
        icon: null,
      },
    },
    advertisements: {
      defaultRatio: {
        x: '16',
        y: '9',
      },
    },
    events: {
      type: 'description-focus',
      tagsEnabled: true,
      imageEnabled: true,
      lines: 3,
    },
    tabs: {
      neutral: true,
      fallback: null,
      configuration: {
        information: {
          destination: {
            kind: 'info',
            value: null,
          },
        },
        'event-days': {
          duration: {
            start: {
              unix: 0,
            },
            end: {
              unix: 0,
            },
          },
          images: {},
        },
        custom: {
          destination: {
            kind: 'external',
            value: null,
          },
          passAuth: false,
          immersive: false,
        },
      },
      values: DEFAULT_TABS.map((tab) => ({ ...tab, _id: generateUUID() })),
    },
    settings: {
      application: {
        userCanAccess: null,
      },
      pubnub: {
        active: null,
      },
    },
  },
  name: {
    full: manifest.name || '',
    abbr: manifest.name || '',
    id:
      (extra['tactile-domain'] || '')
        .replace('app.', '')
        .replace('.tactile.events', '') || manifest.slug,
  },
  image: {
    banner: null,
    profile: null,
  },
};

function useCompanyPlaceholder(domain: string) {
  const [placeHolder, setPlaceholder] = useState(() => COMPANY.current);

  useEffect(() => {
    let mounted = true;

    if (MULTI_COMPANY_ENABLED) {
      return;
    }

    try {
      const seed = JSON.parse(extra['company:seed']);

      if (!mounted || !seed) {
        return;
      }

      setPlaceholder((prev) => prev ?? seed);
    } catch {}

    return () => {
      mounted = false;
    };
  }, []);

  if (!placeHolder?.name.id) {
    return undefined;
  }

  if (!domain.includes(placeHolder.name.id)) {
    return undefined;
  }

  return placeHolder;
}

export function useCompany({
  enabled = true,
  cacheTime = 1000 * 60,
  ...options
}: UseQueryOptions<TactileCompany, FetchMediaError> = {}):
  | LocalCompany
  | undefined {
  const domain = useCurrentDomain();
  const endpoint = useEndpoint();
  const authentication = useAuthentication();
  const abortable = useAbortController();

  useDebugValue(domain);

  /*
  console.log(
    `usecompany ${authentication.noContext} // ${domain} // ${endpoint}`
  );
  */

  const { data: remoteCompany } = useQuery<TactileCompany, FetchMediaError>(
    ['api', 'company', endpoint],
    useCallback(() => {
      console.log('useCompany', endpoint, domain);
      return fetchCompany(endpoint, abortable);
    }, [endpoint, abortable, domain]),
    {
      enabled: Boolean(endpoint) && enabled,
      placeholderData: useCompanyPlaceholder(domain),
      staleTime: cacheTime,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // isDataEqual: isEqual,
      notifyOnChangeProps: ['data'],
      onSuccess: useCallback(
        (result) =>
          result &&
          COMPANY.current?.name.id !== result.name.id &&
          COMPANY.emit(result),
        []
      ),
      ...options,
    }
  );

  return useMemo(() => {
    if (remoteCompany === undefined) {
      return undefined;
    }

    // console.log({ remoteCompany });

    const merged: LocalCompany = merge<LocalCompany, Partial<TactileCompany>>(
      { ...JSON.parse(JSON.stringify(DEFAULT_COMPANY)) },
      remoteCompany || ({} as Partial<TactileCompany>)
    );

    if ((remoteCompany?.application?.tabs?.values?.length || 0) > 2) {
      merged.application.tabs.values = remoteCompany!.application.tabs.values;
    }

    // console.log('merged', domain, merged.name.full, remoteCompany.name.full);

    return merged;
  }, [remoteCompany, domain]);
}

function fetchCompany(
  endpoint: string,
  abortable: ReturnType<typeof useAbortController>
): Promise<TactileCompany> {
  if (endpoint === 'https://app.tactile.events/api') {
    throw new Error("Can't grab the generic app.tactile.events.");
  }

  if (endpoint === 'https://api.tactile.events/api') {
    return Promise.resolve(COMPANY.current! || null);
  }

  const ac = abortable();

  const cancellable = fetchApplication(endpoint, ac.signal, SHOULD_DEBUG_FETCH);

  // This is a non-standard property on a promise, so the error here needs to
  // be ignored. However, react-query will check this non-standard property
  // and use it if it's available.
  //
  // @ts-ignore
  cancellable.cancel = () => {
    ac && ac.abort();
  };

  return cancellable;
}

export function useRemoteCompany(
  domainFull: string | undefined
): UseQueryResult<TactileCompany, FetchMediaError | Error> {
  const abortable = useAbortController();

  const endpoint = [domainFull || '', 'api'].join('/');

  return useQuery(
    ['api', 'company', endpoint],
    () => {
      if (!domainFull) {
        throw new Error('Need a domain to grab the app');
      }

      if (domainFull === 'https://app.tactile.events') {
        throw new Error("Can't grab the generic app.tactile.events.");
      }

      const ac = abortable();

      const cancellable = fetchApplication(
        endpoint,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      // This is a non-standard property on a promise, so the error here needs to
      // be ignored. However, react-query will check this non-standard property
      // and use it if it's available.
      //
      // @ts-ignore
      cancellable.cancel = () => {
        ac && ac.abort();
      };

      return cancellable;
    },
    { enabled: !!domainFull }
  );
}

// Only update company when the app restarts
export function useMutableCompany() {
  return useMutableMemoryValue(COMPANY);
}
