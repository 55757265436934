import {
  submitApplicationEventGoal,
  TactileGoal,
} from '@introcloud/api-client';
import {
  AccentButton,
  Divider,
  PrimaryButton,
  TextButton,
} from '@introcloud/blocks';
import { useBlockData, useBlockNavigation } from '@introcloud/blocks-interface';
import { useRoute } from '@react-navigation/native';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import {
  FlatList,
  KeyboardAvoidingView,
  ListRenderItemInfo,
  PixelRatio,
  StyleSheet,
  View,
  Platform,
} from 'react-native';
import {
  Dialog,
  Headline,
  IconButton,
  List,
  Paragraph,
  Portal,
  Surface,
  useTheme,
} from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useIsMounted } from 'use-is-mounted';

import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { RouteProp } from '../core/Routes';
import { useAbortController } from '../hooks/useAbortController';
import { useAuthorization, useEndpoint } from '../hooks/useAuthentication';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGoals } from '../hooks/useGoals';
import { usePage } from '../hooks/usePage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { FileDialogContent } from './FileDialogContent';
import { ManualDialogContent } from './ManualDialogContent';
import { MenuDialogContent } from './MenuDialogContent';
import { RadioDialogContent } from './RadioDialogContext';
import { Ranking } from './Ranking';
import { TextDialogContent } from './TextDialogContent';

const EMPTY_TEXT = {
  en: `
  Complete tasks, compete against other groups and climb the leaderboard! During
  the week you and the rest of your group get a list of goals for you to
  complete.
  `
    .trim()
    .replace(/\n/g, ''),
};

export function SpecificGoalsScreen() {
  const { id } = useRoute<RouteProp<'EventGoals'>>().params;

  return <GoalsScreen specificId={id} />;
}

export function GoalsScreen({
  asTab,
  colorOverride,
  specificId,
}: {
  asTab?: boolean;
  colorOverride?: string;
  specificId?: string;
}) {
  const { values } = useCompanyTabs();
  const tab = useMemo(
    () => values.find((tab) => tab.tab === 'goals'),
    [values]
  );
  const icon = useMemo(() => (tab ? tab.icon.name : 'trophy'), [tab]);
  const title = useMemo(() => tab?.title, [tab]);

  const { data: goalEvents, reload } = useGoals();
  const actualGoal = useMemo(() => {
    if (specificId) {
      return goalEvents?.find(
        (event) => event.id === specificId && event.goals.length > 0
      );
    }

    // Main
    return (
      goalEvents?.find((event) => event.main && event.goals.length > 0) ||
      (goalEvents?.length === 1 ? goalEvents[0] : undefined)
    );
  }, [goalEvents, specificId]);

  const hasGoals = !!actualGoal;

  const reloadForGoal = useCallback(
    (id: string) => {
      if (!actualGoal || !goalEvents) {
        return reload();
      }

      const nextActualGoal = {
        ...actualGoal,
        goals: actualGoal.goals.map((goal) =>
          goal._id === id
            ? { ...goal, submissionState: 'created' as const }
            : goal
        ),
      };

      // TODO use as prefetch?
      const next = goalEvents.map((e) =>
        e.id === actualGoal.id ? nextActualGoal : e
      );

      return reload().then((result) => result.data);
    },
    [goalEvents, actualGoal]
  );

  return (
    <BlockProvision screen="GoalsScreen">
      <View
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          title={title || actualGoal?.title || 'Crazy88'}
          subTitle={undefined}
          hideBack={asTab}
          colorOverride={colorOverride}
          style={{ elevation: hasGoals ? undefined : 2, zIndex: 2 }}
        />
        {hasGoals ? (
          <Goals
            goals={actualGoal!.goals!}
            reloadForGoal={reloadForGoal}
            eventId={actualGoal!.id}
          />
        ) : null}
        <EmptyState
          hidden={hasGoals}
          icon={icon}
          title={title || actualGoal?.title || 'Crazy88'}
          colorOverride={colorOverride}
          texts={EMPTY_TEXT}
        />
      </View>
    </BlockProvision>
  );
}

type DialogContent = {
  page?: string;
  future?: boolean;
  past?: boolean;
  item?: GoalItem;
};

function Goals({
  goals,
  reloadForGoal,
  eventId,
}: {
  goals: readonly TactileGoal[];
  reloadForGoal: (id: string) => Promise<unknown>;
  eventId: string;
}) {
  const { gotoInfo } = useBlockNavigation();

  const data = useMemo(() => {
    const items = goals.map((goal) => ({ type: 'goal' as const, ...goal }));
    return (
      [
        /*{ type: 'ranking' as const }*/
      ] as Item[]
    ).concat(items);
  }, [goals]);

  const dialogContentRef = useRef<DialogContent | null>(null);
  const [dialogActive, setDialogActive] = useState(false);

  const hideDialog = useCallback(() => setDialogActive(false), []);

  const startSubmission = useCallback(
    (args: DialogContent) => {
      dialogContentRef.current = args;
      setDialogActive(true);
    },
    [setDialogActive]
  );

  const endSubmission = useCallback(
    (id: string) => {
      hideDialog();
      reloadForGoal(id);
    },
    [hideDialog, reloadForGoal]
  );

  const renderItem_ = useMemo(
    () => makeRenderItem(eventId, startSubmission),
    [startSubmission]
  );

  return (
    <Fragment>
      <Ranking eventId={eventId} key="ranking" />
      <FlatList
        initialNumToRender={15}
        nativeID="scroller"
        style={{ flex: 1 }}
        contentContainerStyle={{
          maxWidth: 720,
          alignSelf: 'center',
          width: '100%',
          paddingBottom: 32,
        }}
        data={data}
        keyExtractor={extractKey}
        renderItem={renderItem_}
      />
      <Portal>
        <SubmissionDialog
          visible={dialogActive}
          onDismiss={hideDialog}
          onSubmitted={endSubmission}
          content={dialogContentRef.current}
          gotoInfo={gotoInfo}
        />
      </Portal>
    </Fragment>
  );
}

function extractKey(item: Item) {
  return item.type === 'goal' ? item._id : item.type;
}

type RankingItem = {
  type: 'ranking';
};

type GoalItem = { type: 'goal' } & TactileGoal;
type Item = RankingItem | GoalItem;

function makeRenderItem(
  eventId: string,

  startSubmission: (args: {
    page?: string;
    future?: boolean;
    past?: boolean;
    item?: GoalItem;
  }) => void
) {
  return function renderItem(info: ListRenderItemInfo<Item>) {
    switch (info.item.type) {
      /*case 'ranking': {
        return (
          <Ranking
            eventId={eventId}
            item={info.item}
            index={info.index}
            separators={info.separators}
            key="ranking"
          />
        );
      }*/
      case 'goal': {
        if (info.item.options.submitDuration) {
          return (
            <GatedGoal
              item={info.item}
              index={info.index}
              separators={info.separators}
              key={info.item._id}
              startSubmission={startSubmission}
            />
          );
        }
        return (
          <Goal
            item={info.item}
            index={info.index}
            separators={info.separators}
            key={info.item._id}
            startSubmission={startSubmission}
          />
        );
      }
    }

    return null;
  };
}
function GatedGoal({
  index,
  item,
  separators,
  startSubmission,
}: ListRenderItemInfo<GoalItem> & {
  startSubmission: (args: {
    page?: string;
    future?: boolean;
    past?: boolean;
    item?: GoalItem;
  }) => void;
}) {
  const timestamp = useInaccurateTimestamp({ every: 60 * 1000 });
  const { start, end } = item.options.submitDuration || {};

  if (start && start.unix > timestamp) {
    // future
    return (
      <Goal
        item={item}
        index={index}
        separators={separators}
        key={item._id}
        disabled
        future
        startSubmission={startSubmission}
      />
    );
  }

  if (end && end.unix < timestamp) {
    // past
    return (
      <Goal
        item={item}
        index={index}
        separators={separators}
        key={item._id}
        disabled
        past
        startSubmission={startSubmission}
      />
    );
  }

  return (
    <Goal
      item={item}
      index={index}
      separators={separators}
      key={item._id}
      startSubmission={startSubmission}
    />
  );
}

function Goal({
  disabled,
  future,
  past,
  index,
  item,
  startSubmission,
}: ListRenderItemInfo<GoalItem> & {
  disabled?: boolean;
  future?: boolean;
  past?: boolean;
  startSubmission(args: {
    page?: string;
    future?: boolean;
    past?: boolean;
    item?: GoalItem;
  }): void;
}) {
  const { gotoInfo } = useBlockNavigation();
  const { getInfoById } = useBlockData();
  const pageId = item.pageRef.pageId || null;

  const { page } = usePage(pageId, getInfoById);

  const waiting = item.submissionState === 'created';
  const approved = item.submissionState === 'approved';
  const rejected = item.submissionState === 'rejected';

  const done = approved || rejected;

  const hasPage = !!page;

  const gotoPage = useCallback(() => {
    if (!pageId) {
      return;
    }

    gotoInfo(pageId);
  }, [pageId]);

  const {
    dark,
    colors: { text, placeholder },
  } = useTheme();

  const disabledColor = dark ? 'rgba(255, 255, 255, .2)' : 'rgba(0, 0, 0, .2)';

  return (
    <Surface
      style={{
        elevation: 1,
        paddingTop: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        width: '100%',
        position: 'relative',
      }}
    >
      {index !== 0 ? (
        <Divider
          style={{
            left: 54,
            right: 0,
            position: 'absolute',
            top: 0,
            height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
            width: 'auto',
          }}
        />
      ) : null}
      <List.Item
        title={item.name.full}
        description={item.name.description}
        titleNumberOfLines={5}
        descriptionNumberOfLines={5}
        titleStyle={{
          fontSize: 16,
          marginBottom: 4,
          color: disabled ? disabledColor : text,
        }}
        descriptionStyle={{
          color: disabled ? disabledColor : placeholder,
        }}
        onPress={() =>
          startSubmission({
            page: hasPage ? pageId || undefined : undefined,
            future,
            past,
            item,
          })
        }
        disabled={done || waiting}
        left={(props) => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Headline
              style={{
                ...props.style,
                width: 32,
                marginLeft: 10,
                marginRight: 0,
              }}
            >
              {index + 1}
            </Headline>
            {future ? (
              <List.Icon icon="clock-start" />
            ) : waiting ? (
              <List.Icon icon="timer-sand" />
            ) : approved ? (
              <List.Icon icon="check" color="green" />
            ) : (
              <List.Icon
                icon="close"
                color={rejected ? 'red' : 'transparent'}
              />
            )}
          </View>
        )}
        right={(props) =>
          page ? (
            <IconButton
              icon="chevron-right"
              style={{ alignSelf: 'center' }}
              onPress={gotoPage}
              disabled={future || past}
            />
          ) : null
        }
      />
    </Surface>
  );
}

function SubmissionDialog({
  visible,
  content,
  onDismiss,
  onSubmitted,
  gotoInfo,
}: {
  visible: boolean;
  content: DialogContent | null;
  onDismiss: () => void;
  onSubmitted: (id: string) => void;
  gotoInfo: (id: string) => void;
}) {
  return (
    <Dialog
      visible={visible}
      onDismiss={onDismiss}
      style={{
        maxWidth: 720,
        alignSelf: 'center',
        minWidth: 300,
        overflow: 'hidden',
      }}
    >
      <KeyboardAvoidingView behavior="position">
        <Dialog.Title>{content?.item?.name?.full}</Dialog.Title>
        {content?.page ? (
          <PageLink
            page={content.page}
            gotoInfo={(id: string) => {
              gotoInfo(id);
              onDismiss();
            }}
            disabled={content.future || content.past || false}
          />
        ) : null}
        {content?.future ? (
          <Future onDismiss={onDismiss} />
        ) : content?.past ? (
          <Past onDismiss={onDismiss} />
        ) : content?.item ? (
          <AnyDialogContent
            {...content.item}
            page={content.page}
            onDismiss={onDismiss}
            onSubmitted={onSubmitted}
          />
        ) : null}
      </KeyboardAvoidingView>
    </Dialog>
  );
}

function PageLink({
  page: pageId,
  gotoInfo,
  disabled,
}: {
  page: string;
  gotoInfo: (id: string) => void;
  disabled: boolean;
}) {
  const { getInfoById } = useBlockData();
  const { page, loading } = usePage(pageId, getInfoById);

  return (
    <View style={{ marginBottom: 6 }}>
      <AccentButton
        loading={loading}
        disabled={loading || disabled}
        onPress={() => page?._id && gotoInfo(page._id)}
        style={{ marginHorizontal: 24, marginBottom: 6 }}
      >
        {page ? page.name.full : 'More...'}
      </AccentButton>
    </View>
  );
}

function Future({ onDismiss }: { onDismiss: () => void }) {
  return (
    <Dialog.Content>
      <Paragraph>
        This goal can not be submitted yet. Come back later!
      </Paragraph>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          disabled
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Submit
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          Close
        </TextButton>
      </View>
    </Dialog.Content>
  );
}

function Past({ onDismiss }: { onDismiss: () => void }) {
  return (
    <Dialog.Content>
      <Paragraph>This goal can no longer be submitted. Sorry!</Paragraph>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          disabled
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Submit
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          Close
        </TextButton>
      </View>
    </Dialog.Content>
  );
}

function AnyDialogContent({
  _id,
  kind,
  name,
  page,
  answers,
  onDismiss,
  onSubmitted,
}: NonNullable<DialogContent['item']> & {
  page: DialogContent['page'];
  onDismiss(): void;
  onSubmitted: (id: string) => void;
}) {
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const abortable = useAbortController();
  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const onSubmit = useCallback(
    (content: string | File | Blob | FormData) => {
      if (!endpoint || !authorization) {
        return;
      }

      setLoading(true);

      const { signal } = abortable();

      submitApplicationEventGoal(
        _id,
        kind,
        content,
        endpoint,
        authorization,
        signal,
        SHOULD_DEBUG_FETCH
      )
        .then(() => isMounted.current && onSubmitted(_id))
        .catch((error) => {
          if (!isMounted.current) {
            return;
          }

          console.error(error, new FormData().append('a', 'b'));

          setError(error);
          setLoading(false);
        });
    },
    [endpoint, authorization]
  );

  return (
    <Dialog.Content>
      {name.description ? (
        <Paragraph style={{ marginVertical: 8 }}>{name.description}</Paragraph>
      ) : null}
      {getDialogContent(kind, onDismiss, onSubmit, loading, error, answers)}
    </Dialog.Content>
  );
}

function getDialogContent(
  kind: TactileGoal['kind'] | 'video',
  onDismiss: () => void,
  onSubmit: (content: string | File | Blob | FormData) => void,
  loading: boolean,
  error: Error | null,
  answers: TactileGoal['answers']
) {
  switch (kind) {
    case 'image': {
      return (
        <FileDialogContent
          kind={kind}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
        />
      );
    }

    case 'video': {
      return (
        <FileDialogContent
          kind={kind}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
        />
      );
    }

    case 'input': {
      return (
        <TextDialogContent
          multiline={false}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
        />
      );
    }

    case 'textarea': {
      return (
        <TextDialogContent
          multiline
          onDismiss={onDismiss}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
        />
      );
    }

    case 'dropdown': {
      return (
        <MenuDialogContent
          answers={answers}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
        />
      );
    }

    case 'radio': {
      return (
        <RadioDialogContent
          answers={answers}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
        />
      );
    }

    case 'manual': {
      <ManualDialogContent
        onDismiss={onDismiss}
        onSubmit={onSubmit}
        loading={loading}
        error={error}
      />;
    }
  }

  return null;
}

/*
function base64ToBlob(
  b64Data: string,
  contentType = '',
  sliceSize = 512
): Blob {
  if (b64Data.startsWith('data:')) {
    const [pre, data] = b64Data.split(';base64,');
    return base64ToBlob(data, pre.slice(5));
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
*/
