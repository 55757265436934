import { TactileCompanyTabs } from '@introcloud/api-client';
import { mergeTranslations, useTranslationAction } from '@introcloud/blocks';
import Tabs, {
  RemoteTabConfiguration,
  RemoteTabsConfiguration,
  RemoteTabsProvider,
  tabName,
  TabParamList,
} from '@introcloud/tabs';
import {
  LinkingOptions,
  NavigationContainer,
  PathConfig,
  PathConfigMap,
  useLinkTo,
} from '@react-navigation/native';
import Constants from 'expo-constants';
import {
  addEventListener as addLinkingEventListener,
  getInitialURL as getExpoInitialUrl,
  makeUrl,
  removeEventListener as removeLinkingEventListener,
  useUrl,
} from 'expo-linking';
import I18n, { t } from 'i18n-js';
import cloneDeep from 'lodash.clonedeep';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { Platform, ScrollView, StatusBar } from 'react-native';
import {
  Appbar,
  Caption,
  HelperText,
  Paragraph,
  ThemeProvider,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Analytics } from '../analytics';
import { ChatScreen } from '../chats/ChatScreen';
import { ChatsScreen } from '../chats/ChatsScreen';
import { ChatsTab } from '../chats/ChatsTab';
import { ProvideInAppChats } from '../chats/ProvideInAppChats';
import { ResolveChatScreen } from '../chats/ResolveChatScreen';
import { CustomAltTab, CustomTab, ExperienceScreen } from '../custom/CustomTab';
import { EventDayScreen } from '../events/EventDayScreen';
import { EventDaysTab } from '../events/EventDaysTab';
import { EventScreen } from '../events/EventScreen';
import { EventsScreen } from '../events/EventsScreen';
import { EventsTab } from '../events/EventsTab';
import { EXPERIENCE_ENABLED, GELEGRAAF_ENABLED } from '../features';
import { GameMapTab } from '../gamemap/GameMapTab';
import { GelegraafArticleScreen } from '../gelegraaf/GelegraafArticleScreen';
import { GelegraafEditionScreen } from '../gelegraaf/GelegraafEditionScreen';
import { GelegraafScreen } from '../gelegraaf/GelegraafScreen';
import { GoalScreen } from '../goals/GoalScreen';
import { GoalsScreen, SpecificGoalsScreen } from '../goals/GoalsScreen';
import { GoalsTab } from '../goals/GoalsTab';
import { HomeScreen } from '../home/HomeScreen';
import { HomeTab } from '../home/HomeTab';
import { useCompany } from '../hooks/useCompany';
import { useCompanyTabs, validate } from '../hooks/useCompanyTabs';
import { useNavigationTheme } from '../hooks/useNavigationTheme';
import {
  InformationSecondaryTab,
  InformationTab,
} from '../information/InformationTab';
import { LiveTab } from '../live/LiveTab';
import { ProvideLiveStream } from '../live/ProvideLiveStream';
import { LocationScreen } from '../locations/LocationScreen';
import { LocationsScreen } from '../locations/LocationsScreen';
import { LocationsTab } from '../locations/LocationsTab';
import { MatchingScreen } from '../matching/MatchingScreen';
import { MatchingTab } from '../matching/MatchingTab';
import { NewsScreen } from '../news/NewsScreen';
import { NewsTab } from '../news/NewsTab';
import { PageStack } from '../page/PageStack';
import { PaymentScreen } from '../payment/PaymentScreen';
import { PaymentTab } from '../payment/PaymentTab';
import { ProfileScreen } from '../profile/ProfileScreen';
import { ProfileTab } from '../profile/ProfileTab';
import { ScannerScreen } from '../scanner/ScannerScreen';
import { ScannerTab } from '../scanner/ScannerTab';
import { setLocalDeeplinkListener, SHOULD_ALLOW_DEBUG } from '../utils';
import { Stack } from './CoreStack';
import { ErrorBoundary } from './ErrorBoundary';
import { Header } from './Header';
import { InAppNotifications } from './InAppNotifications';
import { ResolveAccount } from './ResolveAccount';
import {
  isReadyRef,
  navigationConfigRef,
  navigationRef,
} from './RootNavigation';

mergeTranslations({
  en: {
    app: {
      not_found: {
        title: 'Not found',
      },
      info: {
        title: 'Info',
      },
      experience: {
        title: 'Experience',
      },
      goals: {
        title: 'Goals',
      },
      goal: {
        title: 'Goal',
      },
      profile: {
        title: 'Your Profile',
      },
    },
  },

  nl: {
    app: {
      not_found: {
        title: 'Niet gevonden',
      },
      info: {
        title: 'Info',
      },
      experience: {
        title: 'Ervaring',
      },
      goals: {
        title: 'Doelen',
      },
      goal: {
        title: 'Doel',
      },
      profile: {
        title: 'Jouw profiel',
      },
    },
  },
});

const prefix = makeUrl('/');
type DuplicateScreens = Pick<
  TabParamList,
  'Information2' | 'Information3' | 'Information4' | 'Information5'
>;
type UniqueScreens = Omit<TabParamList, keyof DuplicateScreens>;

const TAB_INFORMATION_WITH_ID = {
  path: 'knowledge',
} as const;

const tabScreensLinking: Record<keyof UniqueScreens, string> &
  Record<keyof DuplicateScreens, typeof TAB_INFORMATION_WITH_ID> = {
  Home: 'now',
  Live: 'live',
  Events: 'calendar',
  EventDays: 'calendar',
  Locations: 'locations',
  Information: 'knowledge-base',
  Information2: TAB_INFORMATION_WITH_ID,
  Information3: TAB_INFORMATION_WITH_ID,
  Information4: TAB_INFORMATION_WITH_ID,
  Information5: TAB_INFORMATION_WITH_ID,
  Matching: 'matching',
  Profile: 'profile',
  News: 'news',
  GameMap: 'game-map',
  Goals: 'goals',
  Chats: 'chats',
  Custom: 'special',
  Custom2: 'special-alt',
  Scanner: 'scan',
  Payment: 'payment',
  Incorrect: '*',
};

const lastInitialUrl = { current: null as null | string };

const manifest = Constants.manifest || { extra: {} };
const extra = manifest.extra || {};

const DOMAIN_TACTILE = extra['tactile-domain'];
const DOMAIN_INTROCLOUD = extra['introcloud-domain'];
const DOMAIN_WHITELABEL = extra['whitelabel-domain'];

const linking: LinkingOptions = {
  prefixes: [prefix].concat(
    [DOMAIN_TACTILE, DOMAIN_INTROCLOUD, DOMAIN_WHITELABEL]
      .filter(Boolean)
      .map((domain) => 'https://' + domain)
  ),
  config: {
    initialRouteName: 'Tabs',
    screens: {
      ResolveAccount: {
        path: '~/:companyId/:part_1?/:part_2?/:part_3?/:part_4?/:part_5?/:part_6?',
      },
      Tabs: {
        path: '',
        screens: tabScreensLinking,
      },
      EventDay: 'calendar/:day',
      EventGoals: 'events/:id/goals',
      Event: 'events/:id',
      Location: 'locations/:id',
      Info: {
        path: 'knowledge',
        screens: {
          Embed: ':id/embed/:blockId',
          Page: ':id',
        },
      },

      ...(GELEGRAAF_ENABLED
        ? {
            GelegraafArticle: 'gelegraaf/:day/article/:id',
            GelegraafEdition: 'gelegraaf/:day',
            Gelegraaf: 'gelegraaf',
          }
        : {}),

      ...(EXPERIENCE_ENABLED ? { Experience: 'experience/:id' } : {}),

      // Some tabs as screens
      Home: 'now',
      Live: 'live',
      Events: 'calendar',
      Locations: 'locations',
      Matching: 'matching',
      Profile: 'profile',
      News: 'news',
      Goal: 'goals/:id',
      Goals: 'goals',
      Chats: 'chats',
      ResolveChat: {
        path: 'chat/resolve/:scopes/:context?',
        parse: {
          scopes: (scopes: string) => decodeURIComponent(scopes).split('-'),
          context: (context: string) => {
            const { page, user, group } = JSON.parse(
              decodeURIComponent(context || '{}')
            );
            return { page, user, group };
          },
        },
        stringify: {
          scopes: (scopes: string[]) => scopes.sort().join('-'),
          context: (
            context: string | { page?: string; user?: string; group?: string }
          ) => {
            if (typeof context === 'string') {
              return context;
            }

            return JSON.stringify(context);
          },
        },
      },
      Chat: 'chat/:id',
      Scanner: 'scan',
      Payment: 'payment',

      NotFound: '*',
    },
  },

  // Custom function to get the URL which was used to open the app
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await getExpoInitialUrl();

    // Prevent re-login to re-navigate to initial URL
    if (lastInitialUrl.current === url) {
      return null;
    }

    lastInitialUrl.current = url;

    console.warn('[url] initial url', url);

    if (url !== null) {
      return url;
    }

    return undefined;
  },

  // Custom function to subscribe to incoming links
  subscribe(listener: (url: string) => void) {
    // First, you may want to do the default deep link handling
    const onReceiveURL = ({ url }: { url: string }) => {
      console.warn('[url] received url', url);
      listener(url);
    };

    setLocalDeeplinkListener((url) => {
      listener(url);
      return Promise.resolve(true);
    });

    // Listen to incoming links from deep linking
    addLinkingEventListener('url', onReceiveURL);

    return () => {
      // Clean up the event listeners
      removeLinkingEventListener('url', onReceiveURL);
      setLocalDeeplinkListener(null);
    };
  },
} as const;

Platform.OS !== 'web' &&
  Constants.appOwnership === 'expo' &&
  Analytics.setDebugModeEnabled(true);

const EXTRA_INFORMATION_TABS = [
  'information2',
  'information3',
  'information4',
  'information5',
] as const;

function isExtraInformationTab(
  tab: RemoteTabConfiguration
): tab is RemoteTabConfiguration & {
  tab: typeof EXTRA_INFORMATION_TABS[number];
} {
  return (EXTRA_INFORMATION_TABS as readonly string[]).includes(tab.tab);
}

export function AuthenticatedApp() {
  const routeNameRef = useRef<string | null>(null);
  const routeNameIdRef = useRef<string | null>(null);
  const company = useCompany();
  const companyTabs = useMemo(
    () =>
      company
        ? validate(company.application.tabs)
        : {
            neutral: true,
            fallback: null,
            configuration: {} as TactileCompanyTabs['configuration'],
            values: [] as RemoteTabsConfiguration,
          },
    [company, company?.name.id]
  );
  const navigationTheme = useNavigationTheme();
  const theme = useTheme();

  const configuredTabNames = useMemo(
    () => companyTabs.values.map((tab) => tabName(tab.tab)),
    [companyTabs.values]
  );

  const informationTabs = useMemo(
    () =>
      companyTabs.values.reduce((result, tab) => {
        if (isExtraInformationTab(tab)) {
          const name = tabName(tab.tab);
          const configuration = companyTabs.configuration[tab.tab];

          if (name) {
            result[name] = configuration?.destination?.value;
          }
        }

        return result;
      }, {} as Record<string, string | null | undefined>),
    [companyTabs.values, companyTabs.configuration]
  );

  const finalLinking = useMemo(() => {
    const result = cloneDeep(linking);

    result.getInitialURL = linking.getInitialURL;
    result.subscribe = linking.subscribe;

    const tabConfig = result.config!.screens['Tabs'] as PathConfig;
    const availableTabsWithRoute = tabConfig.screens!;
    const tabs: PathConfigMap = {};

    // The configuration (Tactile remote configuration) contains a list of tab
    // names that may or may not be supported. This section stores the tabs that
    // are supported.
    configuredTabNames.forEach((keepName) => {
      if (keepName && availableTabsWithRoute[keepName]) {
        // information extra tab
        if (keepName in informationTabs) {
          const infoPageId = informationTabs[keepName];

          if (infoPageId) {
            tabs[keepName] = {
              path: `knowledge/${infoPageId}`,
            } as PathConfig;
          } // else: skip tab if its an information tab but config is missing

          // regular tab
        } else {
          tabs[keepName] = availableTabsWithRoute[keepName];
        }
      }
    });

    tabConfig.screens = tabs;

    // Next, determine which screens are available (NOT as tabs, but as the
    // top-level entries). The configuration has the format { [screen]: path }
    // and this turns it into { [path]: screen }
    const availableScreensWithRoute = result.config!.screens;
    const availableScreensByRoute = Object.keys(
      availableScreensWithRoute
    ).reduce((result, name) => {
      const route = availableScreensWithRoute[name];
      if (typeof route === 'string') {
        result[route] = name;
      } else {
        result[route.path!] = name;
      }
      return result;
    }, {} as Record<string, string>);

    // Finally, those tabs that made it to the actual configuration also have
    // a path. Those paths that are occupied by tabs, those screens are removed
    // from the "all screens" list.
    //
    // This ensures there is only one "path" that maps to one "screen" and also
    // that tabs aren't available as standalone screens (or vice versa).
    Object.keys(tabs).forEach((tabName) => {
      const tabRoute = tabs[tabName];
      const realTabRoute =
        typeof tabRoute === 'string' ? tabRoute : tabRoute.path!;

      const removable = availableScreensByRoute[realTabRoute];
      if (removable) {
        delete availableScreensWithRoute[removable];
      }
    });

    return result;
  }, [configuredTabNames, informationTabs]);

  navigationConfigRef.current = finalLinking.config;

  if (companyTabs.values.length < 2) {
    return null;
  }

  return (
    <RemoteTabsProvider value={companyTabs.values} key={company?.name.id}>
      <NavigationContainer
        theme={navigationTheme}
        linking={finalLinking}
        ref={navigationRef}
        onReady={() => {
          isReadyRef.current = true;
          routeNameRef.current =
            navigationRef.current?.getCurrentRoute()?.name || null;

          routeNameIdRef.current = (
            (navigationRef.current?.getCurrentRoute()?.params || {
              id: null,
            }) as any
          )['id'];
        }}
        onStateChange={() => {
          const previousRouteName = routeNameRef.current;
          const previousRouteId = routeNameIdRef.current;

          const currentRouteName =
            navigationRef.current?.getCurrentRoute()?.name || null;

          const currentRouteId = (
            (navigationRef.current?.getCurrentRoute()?.params || {
              id: null,
            }) as any
          )['id'];

          if (
            previousRouteName !== currentRouteName ||
            previousRouteId !== currentRouteId
          ) {
            Analytics.setCurrentScreen(
              [currentRouteName, currentRouteId].filter(Boolean).join(':') ||
                undefined
            );
          }

          routeNameRef.current = currentRouteName;
          routeNameIdRef.current = currentRouteId;
        }}
        fallback={<LoadingLink />}
      >
        <ThemeProvider theme={theme}>
          <ProvideInAppChats>
            <ProvideLiveStream primary={theme.colors.primary}>
              <NavigatedApp />
            </ProvideLiveStream>
            <InAppNotifications
              navigationRef={navigationRef}
              key={
                navigationRef.current ? 'with-navigation' : 'without-navigation'
              }
            />
          </ProvideInAppChats>
        </ThemeProvider>
      </NavigationContainer>
    </RemoteTabsProvider>
  );
}

function LoadingLink() {
  const { top } = useSafeAreaInsets();
  return (
    <Appbar.Header
      statusBarHeight={Platform.select({
        ios: StatusBar.currentHeight || top || 20,
        default: undefined,
      })}
    >
      <Appbar.Content title="" />
    </Appbar.Header>
  );
}

function NotFound() {
  const linkTo = useLinkTo();
  const url = useUrl();

  useEffect(() => {
    if (!SHOULD_ALLOW_DEBUG) {
      linkTo('/');
    }
  }, []);

  return (
    <Fragment>
      <Header
        title="Not Found"
        subTitle={undefined}
        showTranslate={false}
        backFallback={{ screen: 'Tabs', params: { screen: 'Home' } }}
      />
      <ScrollView>
        <Paragraph>{url}</Paragraph>
        <Caption>
          {JSON.stringify(navigationRef.current?.getRootState(), undefined, 2)}
        </Caption>
      </ScrollView>
    </Fragment>
  );
}

function NavigatedApp() {
  const company = useCompany();
  if (!company) {
    return null;
  }

  const {
    name: { full },
  } = company;

  return (
    <Stack.Navigator
      initialRouteName="Tabs"
      screenOptions={{
        headerShown: false,
        // stackAnimation: 'fade',
      }}
    >
      <Stack.Screen name="Tabs" component={TabsScreen} />
      <Stack.Screen
        name="ResolveAccount"
        component={ResolveAccount}
        options={{ title: 'Resolving session...' }}
      />
      <Stack.Screen
        name="Event"
        component={EventScreen}
        options={{ title: `${t('app.event.title')} · ${full}` }}
      />
      <Stack.Screen
        name="EventDay"
        component={EventDayScreen}
        options={{ title: `${t('app.calendar.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Info"
        component={PageStack}
        options={{ title: `${t('app.info.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Location"
        component={LocationScreen}
        options={{ title: `${t('app.location.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{ title: `${t('app.home.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Live"
        component={LiveTab}
        options={{ title: `Live · ${full}` }}
      />
      <Stack.Screen
        name="Events"
        component={EventsScreen}
        options={{ title: `${t('app.calendar.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Locations"
        component={LocationsScreen}
        options={{ title: `${t('app.locations.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Matching"
        component={MatchingScreen}
        options={{ title: `${t('app.matching.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ title: `${t('app.profile.title')} · ${full}` }}
      />
      <Stack.Screen
        name="News"
        component={NewsScreen}
        options={{ title: `${t('app.news.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Goals"
        component={GoalsScreen}
        options={{ title: `${t('app.goals.title')} · ${full}` }}
      />
      <Stack.Screen
        name="EventGoals"
        component={SpecificGoalsScreen}
        options={{ title: `${t('app.goals.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Goal"
        component={GoalScreen}
        options={{ title: `${t('app.goal.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Chats"
        component={ChatsScreen}
        options={{ title: `${t('app.chats.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Chat"
        component={ChatScreen}
        options={{ title: `${t('app.chats.kinds.default')} · ${full}` }}
      />
      <Stack.Screen
        name="ResolveChat"
        component={ResolveChatScreen}
        options={{ title: `${t('app.chats.kinds.default')} · ${full}` }}
      />
      <Stack.Screen
        name="Scanner"
        component={ScannerScreen}
        options={{ title: `${t('app.scanner.title')} · ${full}` }}
      />
      <Stack.Screen
        name="Payment"
        component={PaymentScreen}
        options={{ title: `${t('app.payment.title')} · ${full}` }}
      />
      {GELEGRAAF_ENABLED && (
        <Stack.Screen
          name="Gelegraaf"
          component={GelegraafScreen}
          options={{ title: `Intree Magazine · ${full}` }}
        />
      )}
      {GELEGRAAF_ENABLED && (
        <Stack.Screen
          name="GelegraafEdition"
          component={GelegraafEditionScreen}
          options={{ title: `Intree Magazine edition · ${full}` }}
        />
      )}
      {GELEGRAAF_ENABLED && (
        <Stack.Screen
          name="GelegraafArticle"
          component={GelegraafArticleScreen}
          options={{ title: `Intree Magazine article · ${full}` }}
        />
      )}
      {EXPERIENCE_ENABLED && (
        <Stack.Screen
          name="Experience"
          component={ExperienceScreen}
          options={{ title: `${t('app.experience.title')} · ${full}` }}
        />
      )}
      <Stack.Screen
        name="NotFound"
        component={NotFound}
        options={{ title: `${t('app.not_found.title')} · ${full}` }}
      />
    </Stack.Navigator>
  );
}

function TabsScreen() {
  const { neutral, fallback } = useCompanyTabs();
  const company = useCompany();

  useTranslationAction();
  const locale = I18n.currentLocale();

  if (!company) {
    return null;
  }

  const {
    name: { full },
  } = company;

  return (
    <Tabs
      componentFor={getTabComponent}
      sceneAnimationEnabled={Platform.OS === 'ios'} //Platform.OS !== 'android'}
      neutralBackground={neutral}
      accentFallback={fallback || undefined}
      shifting
      locale={locale}
      companyName={full}
    />
  );
}

type TabName = keyof TabParamList;
type TabComponent = React.ComponentType<unknown>;

const TAB_TO_COMPONENT_MAPPING: Record<TabName, TabComponent> = {
  Home: HomeTab,
  Live: LiveTab,
  Events: EventsTab,
  EventDays: EventDaysTab,
  GameMap: GameMapTab,
  Locations: LocationsTab,
  Information: InformationTab,
  Information2: InformationSecondaryTab,
  Information3: InformationSecondaryTab,
  Information4: InformationSecondaryTab,
  Information5: InformationSecondaryTab,
  News: NewsTab,
  Matching: MatchingTab,
  Profile: ProfileTab,
  Goals: GoalsTab,
  Chats: ChatsTab,
  Custom: CustomTab,
  Custom2: CustomAltTab,
  Scanner: ScannerTab,
  Payment: PaymentTab,
  Incorrect: IncorrectTab,
};

function getTabComponent(tab: TabName): TabComponent {
  const component = TAB_TO_COMPONENT_MAPPING[tab];
  const Component = component || TAB_TO_COMPONENT_MAPPING['Incorrect'];

  const TabComponent = React.memo(() => (
    <BlockTabUpdates>
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    </BlockTabUpdates>
  ));

  TabComponent.displayName = `Tab(${tab})`;

  return TabComponent;
}

class BlockTabUpdates extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return this.props.children;
  }
}

function IncorrectTab() {
  return (
    <HelperText type="error" style={{ marginTop: 200 }}>
      This tab is incorrectly configured
    </HelperText>
  );
}
