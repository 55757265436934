import { fetchApplication } from '@introcloud/api-client';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { StoredMemoryValue } from 'expo-use-memory-value';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useAuthentication } from '../hooks/useAuthentication';
import { COMPANY } from '../hooks/useCompany';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { permitKey } from './Authentication';
import { RouteNavigation, RouteProp } from './Routes';

const WAITER = new StoredMemoryValue('storage.awaiter');

export function ResolveAccount() {
  const {
    params: { companyId, part_1, part_2, part_3, part_4, part_5, part_6 },
  } = useRoute<RouteProp<'ResolveAccount'>>();

  const { pop } = useNavigation<RouteNavigation<'ResolveAccount'>>();

  const path = [part_1, part_2, part_3, part_4, part_5, part_6]
    .filter(Boolean)
    .join('/');

  const linkTo = useLinkTo();
  const { available, authentication, login, logout, resolve } =
    useAuthentication();
  const companyKey = permitKey(companyId);
  const currentKey = permitKey(authentication?.domainFull || '');

  const goto = useCallback(() => {
    pop();

    const realPath = path[0] === '/' ? path.slice(1) : path;
    linkTo('/' + realPath);
  }, [pop, linkTo, path]);

  useEffect(() => {
    async function call() {
      if (available.includes(companyKey)) {
        if (companyKey !== currentKey) {
          // Logout when it can't resolve
          const next = await resolve(companyId);
          if (!next) {
            WAITER.emit(Math.random().toString(36), true, false)
              .catch(() => {})
              .then(() => logout());
            return;
          }

          // Fetch then switch
          fetchApplication(
            next.domainFull + '/api',
            undefined,
            SHOULD_DEBUG_FETCH
          )
            .then((company) => COMPANY.emit(company))
            .then(() => WAITER.emit(Math.random().toString(36), true, false))
            .then(() => login(next))
            .catch(() => logout());
        } else {
          // Already fine
          WAITER.emit(Math.random().toString(36), true, false)
            .catch(() => {})
            .then(() => goto());
        }
      } else {
        // No session available, go to login screen
        WAITER.emit(Math.random().toString(36), true, false)
          .catch(() => {})
          .then(() => logout())
          .then(() => WAITER.emit(Math.random().toString(36), true, false))
          .catch(() => {})
          .then(() => goto());
      }
    }
    call();
  }, [companyId, authentication, goto]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator size="large" />
    </View>
  );
}

//http://localhost:19006/~/elcid.tactile.events/events/60a5213a29c1df6a4c99d1f1
