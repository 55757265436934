import {
  fetchApplicationEventAttendance,
  submitSwipeInterview,
  TactileAttendanceResponse,
  TactileInterviewAnswer,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { NothingToFetch } from '../core/errors/NothingToFetch';
import { NotReady } from '../core/errors/NotReady';
import { queryClient } from '../core/QueryCache';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useAbortController } from './useAbortController';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

export function useSwipeInterviewGuest(
  event: string,
  { enabled = true }: { enabled?: boolean } = {}
) {
  const isFocused = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const abortable = useAbortController();
  const fetcher = useCallback(() => {
    if (!authorization || !endpoint) {
      throw new NotReady();
    }

    if (!event) {
      throw new NothingToFetch();
    }

    const ac = abortable();

    const cancellable = fetchApplicationEventAttendance(
      event,
      endpoint,
      authorization,
      ac.signal,
      SHOULD_DEBUG_FETCH
    );

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [authorization, endpoint, event, abortable]);

  const {
    data: eventGuest,
    error,
    ...others
  } = useQuery<TactileAttendanceResponse, FetchMediaError | NotReady>(
    [endpoint, 'application', 'swipe', 'guest', event],
    fetcher,
    {
      refetchOnWindowFocus: false,
      enabled: enabled && !!(event && authorization && endpoint) && isFocused,
    }
  );

  return {
    data: eventGuest,
    error,
    reload: others.refetch,
    loading: others.isLoading,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}

export function useSubmitSwipeInterview(event: string) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const cacheKey = [endpoint, 'application', 'swipe', 'guest', event];

  return useMutation(
    (answers: TactileInterviewAnswer[]) => {
      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (!event) {
        throw new NothingToFetch();
      }

      return submitSwipeInterview(
        event,
        answers,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );
    },
    {
      onMutate: async (answers: TactileInterviewAnswer[]) => {
        await queryClient.cancelQueries(cacheKey);

        // Snapshot the previous value
        const previous =
          queryClient.getQueryData<TactileAttendanceResponse>(cacheKey);

        if (previous) {
          // Optimistically update to the new value
          queryClient.setQueryData<TactileAttendanceResponse>(
            cacheKey,
            (old) => {
              const stale = old ?? previous;

              return {
                ...stale,
                module: {
                  ...stale.module,
                  interview: {
                    ...stale.module.interview,
                    answer: answers,
                  },
                },
              };
            }
          );
        }

        return { previous };
      },

      onError: (_, __, context) => {
        queryClient.setQueryData(cacheKey, context?.previous);
      },

      onSettled: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );
}
