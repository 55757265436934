import { Platform } from 'react-native';

export const ICONS = Platform.select({
  default: {
    'account-group': require('../../assets/icons/2x/account-group.png'),
    beer: require('../../assets/icons/2x/beer.png'),
    charity: require('../../assets/icons/2x/charity.png'),
    christianity: require('../../assets/icons/2x/christianity.png'),
    coffee: require('../../assets/icons/2x/coffee.png'),
    food: require('../../assets/icons/2x/food.png'),
    football: require('../../assets/icons/2x/football.png'),
    'image-frame': require('../../assets/icons/2x/image-frame.png'),
    'map-marker': require('../../assets/icons/2x/map-marker.png'),
    'map-marker-path': require('../../assets/icons/2x/map-marker-path.png'),
    music: require('../../assets/icons/2x/music.png'),
    park: require('../../assets/icons/2x/park.png'),
    parking: require('../../assets/icons/2x/parking.png'),
    pillar: require('../../assets/icons/2x/pillar.png'),
    school: require('../../assets/icons/2x/school.png'),
    'silverware-fork-knife': require('../../assets/icons/2x/silverware-fork-knife.png'),
    terrain: require('../../assets/icons/2x/terrain.png'),
  },
  android: {
    'account-group': require('../../assets/icons/3x/account-group.png'),
    beer: require('../../assets/icons/3x/beer.png'),
    charity: require('../../assets/icons/3x/charity.png'),
    christianity: require('../../assets/icons/3x/christianity.png'),
    coffee: require('../../assets/icons/3x/coffee.png'),
    food: require('../../assets/icons/3x/food.png'),
    football: require('../../assets/icons/3x/football.png'),
    'image-frame': require('../../assets/icons/3x/image-frame.png'),
    'map-marker': require('../../assets/icons/3x/map-marker.png'),
    'map-marker-path': require('../../assets/icons/3x/map-marker-path.png'),
    music: require('../../assets/icons/3x/music.png'),
    park: require('../../assets/icons/3x/park.png'),
    parking: require('../../assets/icons/3x/parking.png'),
    pillar: require('../../assets/icons/3x/pillar.png'),
    school: require('../../assets/icons/3x/school.png'),
    'silverware-fork-knife': require('../../assets/icons/3x/silverware-fork-knife.png'),
    terrain: require('../../assets/icons/3x/terrain.png'),
  },
});

export const DEFAULT_ICON = ICONS['map-marker'];
