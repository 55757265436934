import {
  VideoEmbedBlockOptions,
  BlockProps,
  PureBlock,
  usePageData,
} from '@introcloud/page';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';

export function FakeVideoEmbedBlock(props: BlockProps<VideoEmbedBlockOptions>) {
  const { page } = usePageData();
  const navigation = useNavigation();
  const gotoEmbed = useCallback(() => {
    navigation.navigate('Info', {
      screen: 'Embed',
      params: { blockId: props.id, block: props, id: page?._id },
    });
  }, [navigation]);

  return (
    <TouchableWithoutFeedback onPress={gotoEmbed}>
      <View style={{ position: 'relative' }}>
        <View pointerEvents="none">
          <PureBlock {...props} />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}
