import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';
import { ProfileScreen } from './ProfileScreen';

mergeTranslations({
  en: {
    app: {
      actions: {
        goto_group_chat: 'Chat',
        goto_chats: 'Your chats',
        logout: 'Logout',
        change_image: 'Change image',
        change_about: 'Save about me',
      },
      profile: {
        image: {
          caching:
            'It can take a while for your new image to show up everywhere.',
        },
        matching: {
          title: 'Your matching profile',
        },
        groups: {
          title: 'Your groups',
          no_groups:
            "Once you've been assigned to a group, everyone in it will appear here. Check back later!",
        },
      },
    },
  },

  nl: {
    app: {
      actions: {
        goto_group_chat: 'Chat',
        goto_chats: 'Jouw chats',
        logout: 'Uitloggen',
        change_image: 'Verander',
        change_about: 'Over mij opslaan',
      },
      profile: {
        image: {
          caching:
            'Het kan even duren voordat je wijziging overal zichtbaar is',
        },
        matching: {
          title: 'Jouw match profile',
        },
        groups: {
          title: 'Jouw groepje(s)',
          no_groups:
            'Als je eenmaal onderdeel bent van een groepje zul je al je groepsgenoten hier zien.',
        },
      },
    },
  },
});

export function ProfileTab() {
  return <ProfileScreen asTab />;
}
