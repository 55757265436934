import { TactileCompanyTabs } from '@introcloud/api-client/dist/fetch/types';

import { useCompanyTabs } from './useCompanyTabs';

export function useCompanyEventDays():
  | TactileCompanyTabs['configuration']['event-days']
  | null {
  const tabs = useCompanyTabs();
  return tabs.configuration['event-days'] || null;
}
