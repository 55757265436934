import React from 'react';

import { useTabColor } from '../hooks/useTabColor';
import { GoalsScreen } from './GoalsScreen';

export function GoalsTab() {
  const color = useTabColor('goals');

  return <GoalsScreen asTab colorOverride={color} />;
}
