import { useRef, useCallback } from 'react';

import { getSafeAbortController } from '../utils';

export function useAbortController() {
  const safeAbortControllerRef = useRef<null | ReturnType<
    typeof getSafeAbortController
  >>(null);

  return useCallback(() => {
    if (safeAbortControllerRef.current) {
      safeAbortControllerRef.current.abort();
    }

    safeAbortControllerRef.current = getSafeAbortController();
    return safeAbortControllerRef.current;
  }, [safeAbortControllerRef]);
}
