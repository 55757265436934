import { TactileLocation } from '@introcloud/api-client';
import {
  cleanTag,
  Divider,
  locationTagIconFor,
  normalizeTag,
} from '@introcloud/blocks';
import { useDimensions } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  InteractionManager,
  Keyboard,
  PixelRatio,
  Platform,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { List, Searchbar, Surface, useTheme } from 'react-native-paper';
import { EdgeInsets } from 'react-native-safe-area-context';
import { FilterMenu } from '../core/FilterMenu';
import { TAG_OVERRIDES } from '../features';

export function MapSearchOverlay({
  safeInsets,
  locations,
  selected,
  filter,
  doSelect,
  setFilter,
}: {
  safeInsets: EdgeInsets;
  locations: readonly TactileLocation[];
  selected: undefined | TactileLocation;
  filter: string | null;
  doSelect(item: TactileLocation): void;
  setFilter(tag: string | null): void;
}) {
  const [query, setQuery] = useState('');
  const [listActive, setListActive] = useState(false);

  const doShowList = useCallback(() => {
    setListActive(true);
  }, [setListActive]);
  const doHideList = useCallback(() => {
    setQuery('');
    setListActive(false);
    Keyboard.dismiss();
  }, [setListActive, setQuery]);

  const doSelectListItem = useCallback(
    (item: TactileLocation) => {
      doHideList();
      doSelect(item);
    },
    [doHideList, doSelect]
  );

  const updateQuery = useCallback(
    (next: string) => {
      if (listActive && next === '') {
        doHideList();
      }

      setQuery(next);
    },
    [setQuery, doHideList, listActive]
  );

  const searchVisible = listActive || query !== '';

  const tags = useMemo(
    () =>
      locations
        .reduce(
          (result, location) => result.concat(location.name.tag || []),
          locations.some(
            (location) =>
              !location.name.tag ||
              location.name.tag.length === 0 ||
              !location.name.tag[0]
          )
            ? ['']
            : ([] as string[])
        )
        .map(cleanTag)
        .filter(
          (item, index, self) =>
            self.indexOf(item) === index &&
            (locationTagIconFor(item) || item === '')
        )
        .sort(),
    [locations]
  );

  const showFilter = tags.length > 1 && !searchVisible;

  useEffect(() => {
    return () => {
      if (Platform.OS === 'web') {
        document.body.style['overflowX'] = '';
      }
    };
  }, []);

  return (
    <Fragment>
      <View
        style={[
          {
            zIndex: 3,
            position: 'absolute',
            padding: 6,
            alignSelf: 'center',
            width: '100%',
            maxWidth: 720,
            marginTop: safeInsets.top,
            marginBottom: safeInsets.bottom, //128,
            marginLeft: safeInsets.left,
            marginRight: safeInsets.right,
          },
        ]}
      >
        <MapSearch
          style={{
            zIndex: searchVisible ? 6 : 4,
            elevation: 4,
            marginRight: undefined,
          }}
          locations={locations}
          active={searchVisible}
          query={query}
          doUpdateQuery={updateQuery}
          doSelect={doSelectListItem}
          doToggleActive={searchVisible ? doHideList : doShowList}
          placeholder={
            selected ? selected.name.full : t('app.locations.search')
          }
        />
        {showFilter && !searchVisible ? (
          <View
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              backgroundColor: 'transparent',
              elevation: 4,
              maxWidth: 170,
            }}
          >
            <FilterMenu onSelect={setFilter} tags={tags} selected={filter} />
          </View>
        ) : null}
      </View>
    </Fragment>
  );
}

interface MapSearchProps {
  active: boolean;
  query: string;
  style?: ViewStyle;
  placeholder: string;
  locations: readonly TactileLocation[];
  doUpdateQuery: (next: string) => void;
  doToggleActive: () => void;
  doSelect: (id: TactileLocation) => void;
}

function MapSearch({
  active,
  doToggleActive,
  query,
  doUpdateQuery,
  doSelect,
  style,
  locations,
  placeholder,
}: MapSearchProps) {
  const [avoidAtBottom, setAvoidAtBottom] = useState(64);
  // const searchBarRef = useRef<typeof Searchbar>()

  // Track the keyboard
  useEffect(() => {
    const didShowSubscription = Keyboard.addListener('keyboardDidShow', (e) => {
      setAvoidAtBottom(Math.ceil(e.endCoordinates.height) + 30 + 12);
    });

    const didChangeSubscription = Keyboard.addListener(
      'keyboardDidChangeFrame',
      (e) => {
        setAvoidAtBottom(Math.ceil(e.endCoordinates.height) + 30 + 12);
      }
    );

    const didHideSubscription = Keyboard.addListener('keyboardDidHide', (_) => {
      setAvoidAtBottom(64);
    });

    return () => {
      didShowSubscription.remove();
      didChangeSubscription.remove();
      didHideSubscription.remove();
    };
  }, [setAvoidAtBottom]);

  return (
    <Fragment>
      <Searchbar
        // ref={searchBarRef}

        icon={active ? 'arrow-left' : 'map-search'}
        onIconPress={doToggleActive}
        placeholder={placeholder}
        onChangeText={doUpdateQuery}
        value={query}
        style={[
          style,
          {
            borderBottomEndRadius: active ? 0 : undefined,
            borderBottomStartRadius: active ? 0 : undefined,
            elevation: 4,
            zIndex: 0,
          },
        ]}
      />

      {active && (
        <SearchResults
          avoidAtBottom={Math.max(avoidAtBottom, 64 + 38)}
          visible={active}
          query={query}
          onSelect={doSelect}
          locations={locations}
        />
      )}
    </Fragment>
  );
}

interface SearchResultsProps {
  avoidAtBottom: number;
  visible: boolean;
  query: string;
  locations: readonly TactileLocation[];
  onSelect(item: TactileLocation): void;
}

function SearchResults({
  visible,
  query,
  avoidAtBottom,
  onSelect,
  locations,
}: SearchResultsProps) {
  const { roundness } = useTheme();
  const { height: windowHeight } = useDimensions('window');

  const searchQuery = useRef(query);

  const [results, setResults] = useState<{
    searched: boolean;
    locations: TactileLocation[];
  }>({
    searched: false,
    locations: [],
  });

  useEffect(() => {
    searchQuery.current = query;

    // Search events
    InteractionManager.runAfterInteractions(() => {
      if (searchQuery.current !== query) {
        return;
      }

      const eventMatches =
        query === ''
          ? locations.concat([])
          : locations.filter((item) => {
              return (
                item.name.full
                  .toLocaleUpperCase()
                  .indexOf(query.toLocaleUpperCase()) !== -1
              );
            });

      // Sort
      InteractionManager.runAfterInteractions(() => {
        if (searchQuery.current !== query) {
          return;
        }

        setResults({
          searched: true,
          locations: eventMatches.sort((a, b) =>
            a.name.full.localeCompare(b.name.full)
          ),
        });
      });
    });
  }, [query]);

  const resultsHeightLimit =
    windowHeight -
    12 - // padding on the outside
    48 - // the search bar size
    avoidAtBottom + // tab bar at the bottom
    24; // dunno

  // const spring = useSpringTransition(true)
  // const maxHeight = bInterpolate(spring, 0, resultsHeightLimit)

  const grouped = useMemo(
    () => groupLocations(results.locations),
    [results.locations]
  );

  return (
    <Surface
      style={{
        display: visible ? 'flex' : 'none',
        elevation: 4,
        zIndex: 0,
        minHeight: 48,
        borderBottomStartRadius: roundness,
        borderBottomEndRadius: roundness,
      }}
    >
      <Divider
        style={{
          height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        }}
      />
      <ScrollView
        style={{ maxHeight: resultsHeightLimit }}
        keyboardShouldPersistTaps="handled"
      >
        <View style={{ flex: 1 }}>
          {results.locations.length === 0 ? (
            <List.Item
              title={results.searched ? t('app.locations.no_results') : ''}
            />
          ) : (
            <Fragment>
              {grouped.map((group) => (
                <SearchResultsSection
                  key={group.title || 'events'}
                  icon={group.icon}
                  items={group.locations}
                  header={group.title || undefined}
                  onSelect={onSelect}
                />
              ))}
            </Fragment>
          )}
        </View>
      </ScrollView>
    </Surface>
  );
}

function groupLocations(location: readonly TactileLocation[]) {
  const groups = location.reduce(
    (groups, location) => {
      const tags = location.name.tag || [];
      if (tags.length === 0) {
        groups['events'].locations.push(location);
      } else {
        tags.forEach((tag) => {
          tag = cleanTag(tag) || 'events';
          tag = locationTagIconFor(tag) ? tag : 'events';

          if (!groups[tag]) {
            groups[tag] = {
              icon: locationTagIconFor(tag) || 'map-marker',
              title:
                (TAG_OVERRIDES ? TAG_OVERRIDES[tag] : undefined) ||
                normalizeTag(tag),
              locations: [],
            };
          }

          groups[tag].locations.push(location);
        });
      }

      return groups;
    },
    {
      events: {
        icon: 'map-marker',
        title: t('app.locations.default_label'),
        locations: [],
      },
    } as Record<
      string,
      { icon: string; title: string | null; locations: TactileLocation[] }
    >
  );

  return Object.keys(groups).map((group) => groups[group]);
}

interface SearchResultsSectionProps {
  header?: string;
  items: readonly TactileLocation[];
  icon: string;
  onSelect(item: TactileLocation): void;
}

function SearchResultsSection({
  icon,
  header,
  items,
  onSelect,
}: SearchResultsSectionProps) {
  return (
    <List.Section title={header}>
      {items.map((item, index, self) => {
        const {
          name: { full: title },
          street,
          number,
          addition,
        } = item;
        const description = street
          ? `${street} ${number}${addition}`.trim()
          : '';
        return (
          <Fragment key={item._id}>
            <List.Item
              key={item._id}
              onPress={() => {
                onSelect(item);
              }}
              title={title}
              style={{ paddingVertical: 4 }}
              description={description || t('app.locations.no_address')}
              left={(props) => <List.Icon {...props} icon={icon} />}
              right={(props) => <List.Icon {...props} icon="arrow-top-right" />}
            />
            {index < self.length - 1 && (
              <Divider
                style={{
                  height: PixelRatio.roundToNearestPixel(
                    StyleSheet.hairlineWidth
                  ),
                  marginLeft: 72,
                  marginRight: 8,
                }}
              />
            )}
          </Fragment>
        );
      })}
    </List.Section>
  );
}
