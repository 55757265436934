import { useIsFocused, useNavigation } from '@react-navigation/native';
import { MessageEvent, PresenceEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import {
  createContext,
  createElement,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Animated, {
  call,
  cond,
  onChange,
  round,
  set,
  useCode,
} from 'react-native-reanimated';
import { useValues } from 'react-native-redash';
import {
  useChannelMessageListener,
  useChannelPresenceListener,
} from '../chats/ProvideInAppChats';
import { sendLocalNotification } from '../core/InAppNotifications';
import {
  GameMapMessage,
  PositionMessage,
  RoomMessage,
} from '../hooks/useChats';

const REFRESH_STATE_EACH_IN_MS = 5 * 1000;

const GameMapInteractionContext = createContext({
  broadcastMove(x: number, y: number): Promise<void> {
    return Promise.reject(
      new Error('Should be inside ProvideGameMapInteraction, but is not')
    );
  },

  broadcastRoom(room: string | null): Promise<void> {
    return Promise.reject(
      new Error('Should be inside ProvideGameMapInteraction, but is not')
    );
  },

  broadcastPing(): Promise<void> {
    return Promise.reject(
      new Error('Should be inside ProvideGameMapInteraction, but is not')
    );
  },

  playersRef: { current: {} as PlayerRefs },

  blockedTiles: {} as Record<number, boolean>,

  localX: new Animated.Value<number>(0),
  localY: new Animated.Value<number>(0),

  selected: {
    select(calloutId: string | null) {},
    onSelected(listener: (calloutId: string | null) => void): () => void {
      return function unsubscribe() {};
    },
  },
});

function useBroadcasts(
  channel: string,
  initialState:
    | { x: number; y: number; room: string | null }
    | undefined
    | null,
  memoryLocation: { x: number; y: number }
) {
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();

  const lastState = useRef<{
    x: number | null;
    y: number | null;
    room: string | null;
    stale: boolean;
  }>(
    initialState
      ? {
          ...initialState,
          stale: true,
        }
      : {
          x: null,
          y: null,
          room: null,
          stale: false,
        }
  );

  // Periodically update the state in the channel presence state
  useEffect(() => {
    let refreshId: number;

    function schedule() {
      refreshId = setTimeout(() => {
        schedule();

        if (!lastState.current.stale || lastState.current.x === null) {
          return;
        }

        lastState.current.stale = false;

        // Broadcast new periodic state
        const { x, y, room } = lastState.current;
        pubnub.setState({ state: { x, y, room }, channels: [channel] }).then(
          () => {},
          () => {}
        );
      }, REFRESH_STATE_EACH_IN_MS) as unknown as number;
    }

    schedule();

    return () => {
      clearTimeout(refreshId);
    };
  }, [pubnub, lastState, channel]);

  const broadcastMove = useCallback(
    async (x: number, y: number) => {
      if (x === 0 || y === 0 || x === 0 || y === 0) {
        return;
      }

      // if (lastState.current.x === x && lastState.current.y === y) {
      //   return;
      // }

      lastState.current.x = x;
      lastState.current.y = y;
      lastState.current.stale = true;

      memoryLocation.x = x;
      memoryLocation.y = y;

      return pubnub
        .publish({
          channel,
          message: createPositionMessage(x, y, uuid),
          storeInHistory: false,
        })
        .then(
          () => {},
          (error) => console.error(error)
        );
    },
    [pubnub, channel, uuid]
  );

  const broadcastRoom = useCallback(
    async (room: string | null) => {
      if (lastState.current.room === room) {
        return;
      }

      lastState.current.room = room;
      lastState.current.stale = true;

      return pubnub
        .publish({
          channel,
          message: createChangeRoomMessage(room, uuid),
          storeInHistory: false,
        })
        .then(
          () => {},
          (error) => console.error(error)
        );
    },
    [pubnub, channel, uuid]
  );

  const broadcastPing = useCallback(async () => {}, []);

  return useMemo(
    () => ({ broadcastMove, broadcastRoom, broadcastPing }),
    [broadcastMove, broadcastRoom, broadcastPing]
  );
}

type PlayerRefs = Record<string, PlayerRef>;

export interface PlayerRef {
  chatId: string;
  position: {
    x: number;
    y: number;
    updatedAt: number;
  };
  room: {
    id: string | null;
    updatedAt: number;
  };
  presence: {
    joined: boolean;
    updatedAt: number;
  };
}

function usePresence(channel: string, max: number | undefined) {
  const pubnub = usePubNub();
  const playersRef = useRef<PlayerRefs>({});
  const { canGoBack, goBack, replace } = useNavigation<any>();

  const onMessageReceived = useCallback(
    (message: MessageEvent) => {
      if (!message.message || !message.message.t) {
        return;
      }

      const typed = message.message as GameMapMessage;
      const unix = Number(
        message.timetoken.slice(0, message.timetoken.length - 4)
      );
      const whomst = message.publisher;

      const current: PlayerRef = playersRef.current[whomst] || {
        chatId: whomst,
        position: { x: -1, y: -1, updatedAt: 0 },
        room: { id: null, updatedAt: 0 },
        presence: { joined: false, updatedAt: 0 },
      };

      switch (typed.t) {
        case 'position': {
          const lastPositionUpdate = current.position.updatedAt;
          if (lastPositionUpdate > unix) {
            return;
          }

          current.position = {
            ...current.position,
            x: typed.x,
            y: typed.y,
            updatedAt: unix,
          };

          __DEV__ &&
            console.debug(
              `[usePresence] ${whomst}: updated position from ${lastPositionUpdate} to ${unix}`,
              current,
              typed
            );
          break;
        }

        case 'room': {
          const lastRoomUpdate = current.room.updatedAt;
          if (lastRoomUpdate > unix) {
            return;
          }

          current.room = {
            ...current.room,
            id: typed.c,
            updatedAt: unix,
          };

          __DEV__ &&
            console.debug(
              `[usePresence] ${whomst}: updated room from ${lastRoomUpdate} to ${unix}`,
              current,
              typed
            );
          break;
        }
      }

      playersRef.current[whomst] = current;
    },
    [playersRef]
  );

  const onPresenceReceived = useCallback(
    (
      presence: Pick<PresenceEvent, 'uuid' | 'action' | 'timetoken' | 'state'>
    ) => {
      const state = presence.state as
        | null
        | undefined
        | {
            x: number;
            y: number;
            room: string | null;
          };
      const unix = Number(
        presence.timetoken.slice(0, presence.timetoken.length - 4)
      );
      const whomst = presence.uuid;
      const action = presence.action;

      const current: PlayerRef = playersRef.current[whomst] || {
        chatId: whomst,
        position: { x: -1, y: -1, updatedAt: 0 },
        room: { id: null, updatedAt: 0 },
        presence: { joined: false, updatedAt: 0 },
      };

      const lastPresenceUpdate = current.presence.updatedAt;

      if (lastPresenceUpdate > unix) {
        console.debug(
          `[usePresence] ${whomst}: skipping because ${lastPresenceUpdate} > ${unix} (${
            (lastPresenceUpdate - unix) / 1000
          }s)`,
          current,
          { action, state }
        );
        return;
      }

      current.presence = {
        ...current.presence,
        joined: action !== 'leave' && action !== 'timeout',
        updatedAt: unix,
      };

      if (state) {
        if (current.room.updatedAt === 0) {
          current.room = {
            ...current.room,
            id: state.room,
            updatedAt: unix,
          };
        }

        if (current.position.updatedAt === 0) {
          current.position = {
            ...current.position,
            x: state.x,
            y: state.y,
            updatedAt: unix,
          };
        }
      }

      playersRef.current[whomst] = current;

      console.debug(
        `[usePresence] ${whomst}: updated from ${lastPresenceUpdate} to ${unix}`,
        current,
        { action, state }
      );

      if (action === 'leave') {
        delete playersRef.current[whomst];
      }
    },
    [playersRef]
  );

  useChannelMessageListener(channel, onMessageReceived);
  useChannelPresenceListener(channel, onPresenceReceived);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    pubnub.subscribe({
      channels: [channel],
      withPresence: true,
    });

    function whoIsHereNow() {
      const unix = new Date().getTime();

      return pubnub.hereNow({ channels: [channel], includeState: true }).then(
        (hereNow) => {
          const channelHere = hereNow.channels[channel];

          if (!channelHere) {
            return;
          }

          console.log('[usePresence] Here now', channelHere);

          if (typeof window !== undefined) {
            (window as any).lastHereNow = channelHere;
          }

          if (max && channelHere.occupancy > max) {
            // Kick out
            if (canGoBack()) {
              goBack();
            } else {
              replace({ screen: 'Root' });
            }

            sendLocalNotification(
              'Sorry, occupancy reached.',
              'That room is very busy right now. Try again later.'
            );
            return;
          }

          channelHere.occupants.forEach((occupant) => {
            onPresenceReceived({
              uuid: occupant.uuid,
              state: occupant.state,
              timetoken: `${unix}0000`,
              action: 'state-change',
            });
          });
        },
        (err) => {
          console.error('[usePresence] Error Herenow failed', err);
        }
      );
    }

    whoIsHereNow();

    if (typeof window !== undefined) {
      (window as any).whoIsHereNow = whoIsHereNow;
    }

    return () => {
      console.warn('[usePresence] Goodbye (unsubscribe)', channel);

      pubnub.unsubscribe({
        channels: [channel],
      });
    };
  }, [channel, pubnub]);

  return playersRef;
}

function useSelected() {
  const listeners = useRef<((next: null | string) => void)[]>([]);

  const select = useCallback(
    (next: null | string) => {
      listeners.current.forEach((listener) => listener(next));
    },
    [listeners]
  );

  const onSelected = useCallback(
    (listener: (next: null | string) => void) => {
      listeners.current.push(listener);

      return function unsubscribe() {
        const index = listeners.current.indexOf(listener);
        if (index !== -1) {
          listeners.current.splice(index, 1);
        }
      };
    },
    [listeners]
  );

  return useMemo(
    () => ({
      select,
      onSelected,
    }),
    [select, onSelected]
  );
}

function createPositionMessage(
  x: number,
  y: number,
  me: string
): PositionMessage {
  return {
    t: 'position',
    x,
    y,
    f: me,
  };
}

function createChangeRoomMessage(room: string | null, me: string): RoomMessage {
  return {
    t: 'room',
    c: room,
    f: me,
  };
}

export function ProvideGameMapInteraction({
  id,
  initialState,
  max,
  blockedTiles,
  children,
  memoryLocation,
}: PropsWithChildren<{
  id: string;
  blockedTiles: Record<number, boolean>;
  max?: number;
  initialState?:
    | undefined
    | null
    | { x: number; y: number; room: null | string };
  memoryLocation: { x: number; y: number };
}>) {
  const channel = `game-map.${id}.v0`;
  const value = useBroadcasts(channel, initialState, memoryLocation);
  const playersRef = usePresence(channel, max);
  const selected = useSelected();

  const [localX, localY, intX, intY, changedPosition] = useValues(
    initialState?.x || 0,
    initialState?.y || 0,
    0,
    0,
    1
  );

  useCode(
    () => [
      set(changedPosition, 0),
      onChange(round(localX), [
        set(intX, round(localX)),
        set(changedPosition, 1),
      ]),
      onChange(round(localY), [
        set(intY, round(localY)),
        set(changedPosition, 1),
      ]),

      cond(
        changedPosition,
        call([intX, intY], ([x, y]) => value.broadcastMove(x, y))
      ),
    ],
    [value.broadcastMove, localX, localY]
  );

  return createElement(GameMapInteractionContext.Provider, {
    value: useMemo(
      () => ({ ...value, playersRef, localX, localY, selected, blockedTiles }),
      [value, playersRef, localX, localY, selected, blockedTiles]
    ),
    children,
  });
}

export function useGameMapInteraction() {
  return useContext(GameMapInteractionContext);
}
