import { useQuery } from 'react-query';
import { useNameFetcher } from '../hooks/useNameFetcher';

type ModelName = Parameters<typeof useNameFetcher>[0];

export function useChatName(
  item: undefined | null | { id: string },
  forceModel: ModelName
) {
  const nameFetcher = useNameFetcher(forceModel);
  const { data: name } = useQuery(
    ['chat', forceModel, item?.id, 'name'],
    () => nameFetcher(item!.id),
    { enabled: !!item?.id, staleTime: 15 * 60 * 1000 }
  );

  return name;
}
