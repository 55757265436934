import { Spacer } from '@introcloud/blocks';
import {
  useBlockData,
  useBlockDataSponsorByKind,
  useBlockSettings,
} from '@introcloud/blocks-interface';
import { SponsorBlock } from '@introcloud/page';
import { useIsFocused } from '@react-navigation/core';
import URLSearchParams from '@ungap/url-search-params';
import Constants from 'expo-constants';
import React, { Fragment, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { Card, useTheme } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';

const DEFAULT_SOURCE = Constants.manifest.scheme;
const DEFAULT_CONTENT = `NewsScreen-SponsorBlock`;

const FAILED_SPONSOR = { current: false };

function NewsSponsor_({ minimized }: { minimized?: boolean }) {
  const isFocused = useIsFocused();
  const { getImageUrl } = useBlockData();
  const {
    Advertisement: { ratio },
  } = useBlockSettings();
  const { data: sponsor, error } = useBlockDataSponsorByKind('news', 'news', {
    notifyOnChangeProps: ['data', 'error'],
    enabled: !FAILED_SPONSOR.current && isFocused,
  });

  useEffect(() => {
    if (sponsor || !error) {
      return;
    }

    FAILED_SPONSOR.current = true;
  }, [error, sponsor]);

  const src = getImageUrl(sponsor?.image.profile || '', 'icon_1440');
  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16
  const theme = useTheme();
  const finalTheme = useMemo(
    () => ({ ...theme, roundness: mobileView ? 0 : theme.roundness }),
    [theme, mobileView]
  );

  if (!src || !sponsor) {
    return null;
  }

  const [, search] = (sponsor.url || '').split('?');
  const searchParams = new URLSearchParams(search);

  return (
    <Fragment>
      <Spacer space={1} />
      <Card elevation={1} theme={finalTheme}>
        <View
          style={{
            borderRadius: finalTheme.roundness,
            overflow: 'hidden',
            maxHeight: minimized ? 64 : undefined,
          }}
        >
          <SponsorBlock
            kind="sponsor"
            value={{
              src: sponsor._id,
              utm: {
                source: searchParams.get('utm_source') || DEFAULT_SOURCE,
                content: searchParams.get('utm_content') || DEFAULT_CONTENT,
              },
            }}
            id="news"
            options={{ ratio, mode: 'cover' }}
          />
        </View>
      </Card>
      <Spacer space={1} />
    </Fragment>
  );
}

export const NewsSponsor = React.memo(NewsSponsor_);
