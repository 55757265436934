import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';

import { MatchingScreen } from './MatchingScreen';

mergeTranslations({
  en: {
    app: {
      matching: {
        title: 'Matching',
      },
    },
  },

  nl: {
    app: {
      matching: {
        title: 'Matchen',
      },
    },
  },
});

export function MatchingTab() {
  return <MatchingScreen asTab />;
}
