import Constants from 'expo-constants';
import merge from 'lodash.merge';
import Pubnub, {
  MessageEvent,
  ObjectsEvent,
  PresenceEvent,
  PubnubStatus,
  SignalEvent,
  StatusEvent,
} from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLogout } from '../hooks/useAuthentication';
import {
  ChatMembership,
  CHATS,
  SelfMessage,
  useMutableStoredChats,
} from '../hooks/useChats';
import { useUser } from '../hooks/useUser';
import { MemoryValue } from '../storage';
import { storeMessage } from './useChatHistory';

const SIGNAL_LISTENERS: ((signal: SignalEvent) => void)[] = [];
const STATUS_LISTENERS: ((status: StatusEvent) => void)[] = [];
const MESSAGE_LISTENERS: ((message: MessageEvent) => void)[] = [];
const PRESENCE_LISTENERS: ((presence: PresenceEvent) => void)[] = [];
const OBJECT_LISTENERS: ((object: ObjectsEvent) => void)[] = [];

const SELF_MESSAGE_LISTENERS: ((message: SelfMessage) => void)[] = [];

if (__DEV__) {
  SIGNAL_LISTENERS.push((signal) => console.debug('[pubnub][signal]', signal));
  STATUS_LISTENERS.push(
    (status) => false && console.debug('[pubnub][status]', status)
  );
  MESSAGE_LISTENERS.push(
    (message) =>
      !message.channel.startsWith('game-map.') &&
      console.debug('[pubnub][message]', message)
  );
  PRESENCE_LISTENERS.push(
    (presence) =>
      !presence.channel.startsWith('game-map.') &&
      console.debug('[pubnub][presence]', presence)
  );
  OBJECT_LISTENERS.push(
    (object) =>
      !object.channel.startsWith('game-map.') &&
      console.debug('[pubnub][object]', object)
  );
}

MESSAGE_LISTENERS.push(storeMessage);

const CONNECTED = new MemoryValue(false);
const NO_PUBNUB = new Pubnub({
  uuid: 'logged-out-ca21442d-86c4-456d-938b-9ccced58eccf',
  publishKey: '',
  subscribeKey: '',
  presenceTimeout: 300,
  autoNetworkDetection: false,
  restore: false,
  ssl: true,
});

export function ProvideInAppChats({ children }: { children: React.ReactNode }) {
  const { data: user } = useUser();

  if (!user) {
    return <PubNubProvider client={NO_PUBNUB}>{children}</PubNubProvider>;
  }

  return (
    <ConnectedInAppChats authKey={user.chatId} uuid={user._id}>
      {children}
      <GlobalPubnubListeners />
    </ConnectedInAppChats>
  );
}

const PUBLISH_KEY = Constants.manifest.extra['pubnub-publish-key'];
const SUBSCRIBE_KEY = Constants.manifest.extra['pubnub-subscribe-key'];

export function ConnectedInAppChats({
  authKey,
  uuid,
  children,
}: {
  authKey: string;
  uuid: string;
  children: React.ReactNode;
}) {
  return (
    <PubNubProvider client={useConnectedPubnub({ authKey, uuid })}>
      {children}
    </PubNubProvider>
  );
}

function useConnectedPubnub({
  authKey,
  uuid,
}: {
  authKey: string;
  uuid: string;
}) {
  const pubnub = useMemo(
    () =>
      new Pubnub({
        uuid,
        authKey,
        publishKey: PUBLISH_KEY,
        subscribeKey: SUBSCRIBE_KEY,
        presenceTimeout: 300,
        // enable for non-browser environment automatic reconnection
        autoNetworkDetection: true,
        restore: true,
        ssl: true,
        keepAlive: true,

        ...({ subscribeRequestTimeout: 60 * 1000 } as any),
      }),
    [] // make once
  );

  // Update UUID
  useEffect(() => {
    pubnub.setAuthKey(authKey);
    pubnub.setUUID(uuid);

    return () => {
      __DEV__ &&
        console.debug('[pubnub] unsubscribe all because uuid/authkey changed');
      pubnub.unsubscribeAll();
    };
  }, [uuid]);

  return pubnub;
}

export function useAddChannel(pubnub: Pubnub) {
  return useCallback(
    (conversationId: string) => {

      pubnub.objects
        .getChannelMetadata({
          channel: conversationId,
          include: { customFields: true },
        })
        .then((meta) => {
          __DEV__ &&
            console.debug('[pubnub] resolved channel', conversationId, meta);

          const channel = meta.data;
          const custom = channel.custom || { type: 'error', refs: '[]' };
          const chat = merge<
            Omit<ChatMembership, 'custom'>,
            Pick<ChatMembership, 'custom'>
          >(channel, {
            custom: {
              type: custom.type as ChatMembership['custom']['type'],
              refs: JSON.parse(custom.refs as string),
            },
          });

          if (
            !custom ||
            custom.type === 'error' ||
            channel.name?.startsWith('game-map.')
          ) {
            // ignore
            return;
          }

          // Add the new chat
          const prev = CHATS.current;
          const next = prev || [];
          next.unshift(chat);

          CHATS.emit(
            next.filter(
              (item, index, self) =>
                self.findIndex((i) => i.id === item.id) === index
            )
          );
        })
        .catch((error) => {
          __DEV__ &&
            console.debug(
              '[pubnub] failed to get new channel',
              conversationId,
              error,
              error.status
            );
          console.error(error, error.status);
        });
    },
    [pubnub]
  );
}

function GlobalPubnubListeners() {
  const logout = useLogout();
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const subscriptions = useRef<Record<string, boolean>>({});
  const [chats, setChats] = useMutableStoredChats();
  const selfChannel = useMemo(
    () => chats?.find((c) => c.custom?.type === 'self'),
    [chats]
  );

  const addChannel = useAddChannel(pubnub);

  const removeChannel = useCallback(
    (id: string) => {
      __DEV__ && console.debug('[pubnub] removing channel', id);

      pubnub.unsubscribe({ channels: [id] });
      setChats((prev) => (prev ? prev.filter((item) => item.id !== id) : []));

      subscriptions.current[id] = false;
    },
    [pubnub, setChats]
  );

  const syncMembership = useCallback(
    (object: ObjectsEvent) => {
      const conversationId = object.channel;

      if (
        object.message.type !== 'membership' ||
        object.message.data.uuid.id !== pubnub.getUUID()
      ) {
        return;
      }

      if (object.channel?.startsWith('game-map.')) {
        // ignore game-map messages
        return;
      }

      switch (object.message.event) {
        case 'set': {
          addChannel(conversationId);
          break;
        }
        case 'delete': {
          removeChannel(conversationId);
          break;
        }
      }
    },
    [addChannel, removeChannel, pubnub]
  );

  const ensureMembership = useCallback(
    (message: MessageEvent) => {
      if (message.channel?.startsWith('game-map.')) {
        // ignore game-map messages
        return;
      }

      const conversationId = message.channel;

      // Already have this
      if (chats?.find((chat) => chat.id === conversationId)) {
        return;
      }

      // New channel! Retrieve it
      addChannel(conversationId);
    },
    [addChannel]
  );

  const accessUnsubscriber = useCallback(
    (status: StatusEvent | PubnubStatus) => {
      if ('error' in status && status.error) {
        if (status.statusCode === 403) {
          if (status.errorData && (status.errorData as any).payload) {
            const channels = (status.errorData as any).payload.channels || [];
            pubnub.objects
              .removeMemberships({
                channels,
                uuid: pubnub.getUUID(),
              })
              .catch(() => {});

            channels.forEach(removeChannel);
          }
        }
      }
    },
    [pubnub]
  );

  const connectionStatus = useCallback((status: StatusEvent) => {
    switch (status.category) {
      case 'PNNetworkDownCategory': {
        CONNECTED.emit(false);
        return;
      }
      case 'PNNetworkUpCategory': {
        CONNECTED.emit(true);
        return;
      }
      case 'PNConnectedCategory': {
        CONNECTED.emit(true);
        return;
      }
    }
  }, []);

  const handleSelfMessages = useCallback(
    (message: MessageEvent) => {
      try {
        console.info('[pubnub] you got a self-message', message);
      } catch {}

      if (message.message && message.message.t) {
        const { message: typed } = message as { message: SelfMessage };

        SELF_MESSAGE_LISTENERS.forEach((listener) => listener(typed));

        switch (typed.t) {
          case 'subscribe': {
            addChannel(typed.c);

            return;
          }
          case 'invite': {
            addChannel(typed.c);
            // TODO: in-app notification?
            return;
          }
          case 'kick': {
            logout();
            return;
          }
          case 'block': {
            removeChannel(typed.c);
            return;
          }

          default: {
            console.warn('[pubnub] self: unrecognised message type', typed);
          }
        }
      }
    },
    [logout]
  );

  // This effect creates the global listeners
  useEffect(() => {
    const listeners = {
      signal: (signal: SignalEvent) =>
        SIGNAL_LISTENERS.forEach((listener) => listener(signal)),
      status: (status: StatusEvent) =>
        STATUS_LISTENERS.forEach((listener) => listener(status)),
      message: (message: MessageEvent) =>
        MESSAGE_LISTENERS.forEach((listener) => listener(message)),
      presence: (presence: PresenceEvent) =>
        PRESENCE_LISTENERS.forEach((listener) => listener(presence)),
      objects: (objects: ObjectsEvent) =>
        OBJECT_LISTENERS.forEach((listener) => listener(objects)),
    };

    pubnub.addListener(listeners);

    return () => {
      pubnub.removeListener(listeners);
    };
  }, [pubnub, uuid, ensureMembership]);

  useGlobalMessageListener(ensureMembership);
  useChannelMessageListener(selfChannel?.id, handleSelfMessages);
  useStatusListener(accessUnsubscriber);
  useStatusListener(connectionStatus);
  useObjectListener(syncMembership);

  // This effect ensures that the chats are fetched on mount
  useEffect(() => {
    let stillCareAboutThis = true;

    function getChats(next?: string, prev?: string) {
      return pubnub.objects
        .getMemberships({
          uuid: pubnub.getUUID(),
          page: next ? { next, prev: prev! } : undefined,
          include: {
            customChannelFields: true,
          },
        })
        .then((memberships): ChatMembership[] | Promise<ChatMembership[]> => {
          if (!stillCareAboutThis) {
            return [];
          }

          const next = memberships.data
            .map((data) => data.channel)
            .map(
              (
                channel: any // Pubnub.ChannelMetadataObject<Pubnub.ObjectCustom>) =>
              ) => {
                if (!channel) {
                  return null;
                }

                const custom = channel.custom || { type: 'error', refs: '[]' };

                try {
                  return merge<
                    Omit<ChatMembership, 'custom'>,
                    Pick<ChatMembership, 'custom'>
                  >(channel, {
                    custom: {
                      type: custom.type,
                      refs: JSON.parse(custom.refs as string),
                    },
                  });
                } catch {
                  return null;
                }
              }
            )
            .filter(Boolean) as ChatMembership[];

          if (memberships.next && next.length > 0) {
            return getChats(memberships.next, memberships.prev).then((result) =>
              next.concat(result)
            );
          }

          return next;
        })
        .catch(() => []);
    }

    getChats()
      .then((next) => {
        if (!stillCareAboutThis) {
          return;
        }

        setChats(next);
      })
      .catch(() => {});

    return () => {
      stillCareAboutThis = false;
      subscriptions.current = {};
    };
  }, [pubnub, uuid, setChats]);

  // This effect subscribes to all the channels not yet subscribed to
  useEffect(() => {
    chats?.forEach((chat) => {
      if (!chat.custom.type || (chat.custom.type as 'error') === 'error') {
        return;
      }

      if (chat.id.startsWith('game-map.')) {
        return;
      }

      // __DEV__ && console.debug('[pubnub] adding channel', chat.id, chat);

      if (!subscriptions.current[chat.id]) {
        subscriptions.current[chat.id] = true;
        pubnub.subscribe({ channels: [chat.id], withPresence: false });
      }
    });
  }, [pubnub, uuid, chats]);

  return null;
}

export function useGlobalMessageListener(
  listener: (message: MessageEvent) => void
) {
  useEffect(() => {
    MESSAGE_LISTENERS.push(listener);
    return () => {
      MESSAGE_LISTENERS.splice(MESSAGE_LISTENERS.indexOf(listener), 1);
    };
  }, [listener]);
}

export function useChannelMessageListener(
  channel: string | null | undefined,
  listener: (message: MessageEvent) => void
) {
  const realListener = useCallback(
    (message: MessageEvent) => {
      if (channel && message.channel === channel) {
        listener(message);
      }
    },
    [channel, listener]
  );

  useGlobalMessageListener(realListener);
}

export function useSelfMessageListener(
  listener: (message: SelfMessage) => void
) {
  useEffect(() => {
    SELF_MESSAGE_LISTENERS.push(listener);
    return () => {
      SELF_MESSAGE_LISTENERS.splice(
        SELF_MESSAGE_LISTENERS.indexOf(listener),
        1
      );
    };
  }, [listener]);
}

export function useChannelPresenceListener(
  channel: string | null | undefined,
  listener: (message: PresenceEvent) => void
) {
  const realListener = useCallback(
    (message: PresenceEvent) => {
      if (
        channel &&
        (message.channel === channel || message.channel === `${channel}-pnpres`)
      ) {
        listener(message);
      }
    },
    [channel, listener]
  );

  useEffect(() => {
    PRESENCE_LISTENERS.push(realListener);
    return () => {
      PRESENCE_LISTENERS.splice(PRESENCE_LISTENERS.indexOf(realListener), 1);
    };
  }, [listener]);
}

export function useStatusListener(listener: (status: StatusEvent) => void) {
  useEffect(() => {
    STATUS_LISTENERS.push(listener);
    return () => {
      STATUS_LISTENERS.splice(STATUS_LISTENERS.indexOf(listener), 1);
    };
  }, [listener]);
}

export function useObjectListener(listener: (object: ObjectsEvent) => void) {
  useEffect(() => {
    OBJECT_LISTENERS.push(listener);
    return () => {
      OBJECT_LISTENERS.splice(OBJECT_LISTENERS.indexOf(listener), 1);
    };
  }, [listener]);
}
