import Constants from 'expo-constants';
import { Platform } from 'react-native';

export * from 'expo-use-memory-value';

if (Platform.OS === 'web') {
  const manifest = Constants.manifest || { extra: {}, slug: '__unknown' };
  const extra = manifest.extra || {};

  const DOMAIN_TACTILE = extra['tactile-domain'];
  const DOMAIN_INTROCLOUD = extra['introcloud-domain'];
  const DOMAIN_WHITELABEL = extra['whitelabel-domain'];

  require('expo-use-memory-value/storage.web').setLocalForageInstance(
    require('localforage').createInstance({
      name: `${
        DOMAIN_TACTILE ||
        DOMAIN_INTROCLOUD ||
        DOMAIN_WHITELABEL ||
        manifest.slug
      }.2021`,
    })
  );
}
