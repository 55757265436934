import React, { ErrorInfo, Fragment } from 'react';
import { HelperText } from 'react-native-paper';

import { Sentry } from '../sentry';
import { SHOULD_ALLOW_DEBUG } from '../utils';

export class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  { error?: Error }
> {
  constructor(props: Record<string, unknown>) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.clear();
      scope.setExtras({ errorInfo });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error === undefined) {
      return <Fragment>{this.props.children}</Fragment>;
    }

    if (!SHOULD_ALLOW_DEBUG) {
      return <HelperText type="error">Something went wrong</HelperText>;
    }

    return <HelperText type="error">{this.state.error.message}</HelperText>;
  }
}
