import { TactileLocation } from '@introcloud/api-client';
import { Point } from '@introcloud/api-client/dist/fetch/types';
import { useFocusEffect } from '@react-navigation/native';
import { t } from 'i18n-js';
import merge from 'lodash.merge';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { BlockProvision } from '../core/BlockProvision';
import { ErrorBoundary } from '../core/ErrorBoundary';
import { Header } from '../core/Header';
import { INITIAL_MAP_FILTER } from '../features';
import { useCompany } from '../hooks/useCompany';
import { PreparedLocation, useLocations } from '../hooks/useLocations';
import { ContextOverlay } from './ContextOverlay';
import { MapSearchOverlay } from './MapSearch';
import { MapView } from './MapView';

const EMPTY: readonly PreparedLocation[] = [];

export function LocationsScreen({ asTab }: { asTab?: boolean }) {
  const company = useCompany();
  const [selected, setSelected] = useState<TactileLocation | null>(null);
  const [filter, setFilter] = useState<string | null>(INITIAL_MAP_FILTER);
  const { data: allLocations } = useLocations();
  const locations = useMemo(
    () =>
      allLocations
        ? allLocations.filter((location) => {
            // No location module information
            if (!location.module?.application) {
              return true;
            }

            const { application } = location.module;

            // Hide when these flags are both false
            if (
              !application.showOnMapAlways &&
              !application.showOnMapWhenLoggedIn
            ) {
              return false;
            }

            // Only show if there are coordinates
            return (
              location.geojson?.coordinates &&
              location.geojson.coordinates.length > 0 &&
              (location.geojson.type !== 'Point' ||
                isValidPoint(location.geojson.coordinates))
            );
          })
        : EMPTY,
    [allLocations]
  );
  const safeArea = useSafeAreaInsets();
  const [isFocused, setIsFocused] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);

      return () => setIsFocused(false);
    }, [setIsFocused])
  );

  const mapSettings = useMemo(
    () =>
      merge(
        {
          center: { latitude: 52.0808948, longitude: 4.3042691 },
        },
        company?.application.map ?? {}
      ),
    [company?.application.map]
  );

  const insets = useMemo(() => {
    return {
      top: asTab ? safeArea.top : 2,
      left: 0,
      right: 0,
      bottom: 24,
    };
  }, [asTab, safeArea.top]);

  return (
    <BlockProvision screen="LocationsScreen">
      {isFocused && asTab && <StatusBar barStyle="dark-content" />}
      {!asTab && (
        <Header title={t('app.locations.title')} subTitle={undefined} />
      )}
      <View style={styles.container}>
        <ErrorBoundary>
          <MapView
            center={mapSettings.center}
            locations={locations}
            selected={selected}
            onSelect={setSelected}
            filter={filter}
          />
        </ErrorBoundary>

        <SafeWrapper>
          <ContextOverlay
            selected={selected}
            locations={locations}
            filter={filter}
          />

          <MapSearchOverlay
            safeInsets={insets}
            locations={locations}
            selected={selected || undefined}
            filter={filter}
            doSelect={setSelected}
            setFilter={setFilter}
          />
        </SafeWrapper>
      </View>
    </BlockProvision>
  );
}

function isValidPoint(point: Point): boolean {
  // No 0, 0
  if (!point || !point[0] || !point[1]) {
    return false;
  }

  const latitude = Number(point[0]);
  if (isNaN(latitude) || latitude < -90 || latitude > 90) {
    return false;
  }

  const longitude = Number(point[1]);
  if (isNaN(longitude) || longitude < -180 || longitude > 180) {
    return false;
  }

  return true;
}

function SafeWrapper(props: React.PropsWithChildren<unknown>) {
  if (Platform.OS === 'ios') {
    return (
      <SafeAreaView
        style={{
          padding: 0,
          flex: 1,
          position: 'relative',
        }}
        pointerEvents="box-none"
      >
        {props.children}
      </SafeAreaView>
    );
  }
  return <Fragment>{props.children}</Fragment>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
});
