import {
  fetchChatConversationWithGroup,
  fetchChatConversationWithPageAsGroup,
  fetchChatConversationWithPageAsPublic,
  fetchChatConversationWithPageAsSolo,
  fetchChatConversationWithUser,
  TactileConversation,
} from '@introcloud/api-client';
import { AccentButton } from '@introcloud/blocks';
import { useBlockNavigation } from '@introcloud/blocks-interface';
import { useRoute } from '@react-navigation/native';
import { FetchMediaError } from 'fetch-media';
import { t } from 'i18n-js';
import merge from 'lodash.merge';
import { usePubNub } from 'pubnub-react';
import React, { Fragment, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import {
  Caption,
  Card,
  HelperText,
  List,
  ProgressBar,
} from 'react-native-paper';
import { useQuery } from 'react-query';
import { BlockProvision } from '../core/BlockProvision';
import { DetachedHeader } from '../core/Header';
import { RouteProp } from '../core/Routes';
import {
  useChannelOneFetcher,
  useChannelTwoFetcher,
} from '../hooks/useChannelFetcher';
import { ChatMembership, useMutableStoredChats } from '../hooks/useChats';
import { useForceUpdateCount } from '../hooks/useForceUpdate';
import { useGroupsDetached } from '../hooks/useGroup';
import { useNameFetcher } from '../hooks/useNameFetcher';

export function ResolveChatScreen() {
  const { scopes, context } = useRoute<RouteProp<'ResolveChat'>>().params;

  const decoded = decodeURIComponent(scopes.join('.')).split(/[-.]/g);

  const hasPageScope = decoded.includes('page');
  const hasGroupScope = decoded.includes('group');
  const hasUserScope = decoded.includes('user');

  return (
    <BlockProvision screen="ResolveChatScreen">
      <ChatResolver
        {...context}
        hasGroupScope={hasGroupScope}
        hasPageScope={hasPageScope}
        hasUserScope={hasUserScope}
      />
    </BlockProvision>
  );
}

export function ChatResolver({
  hasPageScope,
  hasUserScope,
  hasGroupScope,
  page,
  group,
  user,
  hideHeader,
}: {
  hasPageScope: boolean;
  hasUserScope: boolean;
  hasGroupScope: boolean;
  page?: string;
  user?: string;
  group?: string;
  hideHeader?: boolean;
}) {
  if (hasPageScope) {
    // Expected a page
    if (!page) {
      return (
        <UnsupportedChat
          error={new Error(`Expected a page id`)}
          hideHeader={hideHeader}
        />
      );
    }

    if (hasUserScope) {
      return <PageOneOnOneChat page={page} hideHeader={hideHeader} />;
    }

    if (hasGroupScope) {
      return (
        <PageGroupChat page={page} group={group} hideHeader={hideHeader} />
      );
    }

    return <PagePublicChat page={page} hideHeader={hideHeader} />;
  }

  if (hasGroupScope) {
    return <GroupChat group={group} hideHeader={hideHeader} />;
  }

  if (hasUserScope && user) {
    return <UserChat user={user} hideHeader={hideHeader} />;
  }

  return (
    <UnsupportedChat
      error={new Error(`Unknown combination of scopes and context`)}
      hideHeader={hideHeader}
    />
  );
}

function UnsupportedChat({
  error,
  hideHeader,
}: {
  error: Error;
  hideHeader?: boolean;
}) {
  return <HelperText type="error">{error.message}</HelperText>;
}

function PageOneOnOneChat({
  page,
  hideHeader,
}: {
  page: string;
  hideHeader?: boolean;
}) {
  const nameFetcher = useNameFetcher('page');
  const channelFetcher = useChannelOneFetcher(
    fetchChatConversationWithPageAsSolo
  );

  const { data: name } = useQuery(
    ['chat', 'page', page, 'name'],
    () => nameFetcher(page),
    { enabled: !!page, staleTime: 15 * 60 * 1000 }
  );
  const { data: channel, error } = useQuery<
    TactileConversation,
    FetchMediaError
  >(['chat', 'page', page], () => channelFetcher(page), {
    enabled: !!page,
    staleTime: 15 * 60 * 1000,
  });

  if (error) {
    return <UnsupportedChat error={error} hideHeader={hideHeader} />;
  }

  return (
    <ResolveChat
      conversationId={channel?.channelId}
      title={name}
      hideHeader={hideHeader}
    />
  );
}

function PagePublicChat({
  page,
  hideHeader,
}: {
  page: string;
  hideHeader?: boolean;
}) {
  const nameFetcher = useNameFetcher('page');
  const channelFetcher = useChannelOneFetcher(
    fetchChatConversationWithPageAsPublic
  );

  const { data: name } = useQuery(
    ['chat', 'page', page, 'name'],
    () => nameFetcher(page),
    { enabled: !!page, staleTime: 15 * 60 * 1000 }
  );
  const { data: channel, error } = useQuery<
    TactileConversation,
    FetchMediaError
  >(['chat', 'page', page], () => channelFetcher(page), {
    enabled: !!page,
    staleTime: 15 * 60 * 1000,
  });

  if (error) {
    return <UnsupportedChat error={error} hideHeader={hideHeader} />;
  }

  return (
    <ResolveChat
      conversationId={channel?.channelId}
      title={name}
      hideHeader={hideHeader}
    />
  );
}

function PageGroupChat({
  page,
  group,
  hideHeader,
}: {
  page: string;
  group?: string;
  hideHeader?: boolean;
}) {
  const nameFetcher = useNameFetcher('page');

  const { data: name } = useQuery(
    ['chat', 'page', page, 'name'],
    () => nameFetcher(page),
    { enabled: !!page, staleTime: 15 * 60 * 1000 }
  );

  if (group) {
    return (
      <PageResolvedGroupChat
        page={page}
        group={group}
        title={name}
        hideHeader={hideHeader}
      />
    );
  }

  return <PagePickGroupChat page={page} title={name} hideHeader={hideHeader} />;
}

function PageResolvedGroupChat({
  page,
  group,
  title,
  hideHeader,
}: {
  page: string;
  group: string;
  title?: string;
  hideHeader?: boolean;
}) {
  const channelFetcher = useChannelTwoFetcher(
    fetchChatConversationWithPageAsGroup
  );

  const { data: channel, error } = useQuery<
    TactileConversation,
    FetchMediaError
  >(['chat', 'page-group', page, group], () => channelFetcher(page, group), {
    enabled: !!page && !!group,
    staleTime: 15 * 60 * 1000,
  });

  if (error) {
    return <UnsupportedChat error={error} hideHeader={hideHeader} />;
  }

  return (
    <ResolveChat
      conversationId={channel?.channelId}
      title={title}
      hideHeader={hideHeader}
    />
  );
}

function PagePickGroupChat({
  page,
  title,
  hideHeader,
}: {
  page: string;
  title?: string;
  hideHeader?: boolean;
}) {
  const { groups } = useGroupsDetached();
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  if (!groups) {
    // Shows loading whilst groups are being fetched
    return (
      <ResolveChat
        conversationId={undefined}
        title={title}
        hideHeader={hideHeader}
      />
    );
  }

  if (groups.length === 0) {
    return (
      <UnsupportedChat
        hideHeader={hideHeader}
        error={
          new Error("You can't join a group chat without being part of a group")
        }
      />
    );
  }

  if (selectedGroup || groups.length === 1) {
    return (
      <PageResolvedGroupChat
        hideHeader={hideHeader}
        group={selectedGroup || groups[0]._id}
        page={page}
        title={title}
      />
    );
  }

  return (
    <ScrollView
      contentContainerStyle={{
        paddingTop: 72,
        paddingBottom: 72,
        maxWidth: 500,
        width: '100%',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
      style={{
        width: '100%',
        maxHeight: '100%',
      }}
    >
      <List.Subheader>{t('app.chats.resolve.pick_group')}</List.Subheader>
      <Card elevation={2}>
        {groups.map((group) => {
          const members = group.users
            .slice(0, 2)
            .map((user) => user.name.first);
          const otherMembers = group.users.slice(2).length;
          const text = `${members.filter(Boolean).join(', ')}${
            otherMembers > 0
              ? t('app.chats.resolve.and_others', { count: otherMembers })
              : ''
          }`;

          return (
            <List.Item
              key={group._id}
              title={group.name?.full}
              description={text}
              onPress={() => setSelectedGroup(group._id)}
            />
          );
        })}
      </Card>
    </ScrollView>
  );
}

function GroupChat({
  group,
  hideHeader,
}: {
  group?: string;
  hideHeader?: boolean;
}) {
  const nameFetcher = useNameFetcher('group');
  const { data: name } = useQuery(
    ['chat', 'group', group, 'name'],
    () => nameFetcher(group!),
    {
      enabled: !!group,
      staleTime: 15 * 60 * 1000,
    }
  );

  if (group) {
    return (
      <ResolvedGroupChat group={group} title={name} hideHeader={hideHeader} />
    );
  }

  return <PickGroupChat title={name} hideHeader={hideHeader} />;
}

function PickGroupChat({
  title,
  hideHeader,
}: {
  title?: string;
  hideHeader?: boolean;
}) {
  const { groups } = useGroupsDetached();
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  if (!groups) {
    // Shows loading whilst groups are being fetched
    return (
      <ResolveChat
        conversationId={undefined}
        title={title}
        hideHeader={hideHeader}
      />
    );
  }

  if (groups.length === 0) {
    return (
      <UnsupportedChat
        hideHeader={hideHeader}
        error={
          new Error("You can't join a group chat without being part of a group")
        }
      />
    );
  }

  if (selectedGroup || groups.length === 1) {
    return (
      <ResolvedGroupChat
        group={selectedGroup || groups[0]._id}
        title={title}
        hideHeader={hideHeader}
      />
    );
  }

  return (
    <ScrollView
      contentContainerStyle={{
        paddingTop: 72,
        paddingBottom: 72,
        alignContent: 'center',
        maxWidth: 500,
        width: '100%',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
      style={{ width: '100%', maxHeight: '100%', marginTop: 100 }}
    >
      <List.Subheader>{t('app.chats.resolve.pick_group')}</List.Subheader>
      <Card elevation={2}>
        {groups.map((group) => {
          const members = group.users
            .slice(0, 2)
            .map((user) => user.name.first);
          const otherMembers = group.users.slice(2).length;
          const text = `${members.filter(Boolean).join(', ')}${
            otherMembers > 0
              ? t('app.chats.resolve.and_others', { count: otherMembers })
              : ''
          }`;

          return (
            <List.Item
              key={group._id}
              title={group.name?.full}
              description={text}
              onPress={() => setSelectedGroup(group._id)}
              disabled={!group._id}
            />
          );
        })}
      </Card>
    </ScrollView>
  );
}

function ResolvedGroupChat({
  group,
  title,
  hideHeader,
}: {
  group: string;
  title?: string;
  hideHeader?: boolean;
}) {
  const channelFetcher = useChannelOneFetcher(fetchChatConversationWithGroup);
  const { data: channel, error } = useQuery<
    TactileConversation,
    FetchMediaError
  >(['chat', 'group', group], () => channelFetcher(group), {
    enabled: !!group,
    staleTime: 15 * 60 * 1000,
  });

  if (error) {
    return <UnsupportedChat error={error} hideHeader={hideHeader} />;
  }

  return (
    <ResolveChat
      conversationId={channel?.channelId}
      title={title}
      hideHeader={hideHeader}
    />
  );
}

function UserChat({
  user,
  hideHeader,
}: {
  user: string;
  hideHeader?: boolean;
}) {
  const nameFetcher = useNameFetcher('user');
  const channelFetcher = useChannelOneFetcher(fetchChatConversationWithUser);

  const { data: name } = useQuery(
    ['chat', 'user', user, 'name'],
    () => nameFetcher(user),
    { enabled: !!user, staleTime: 15 * 60 * 1000 }
  );

  const { data: channel, error } = useQuery<
    TactileConversation,
    FetchMediaError
  >(['chat', 'user', user], () => channelFetcher(user), {
    enabled: !!user,
    staleTime: 15 * 60 * 1000,
  });

  if (error) {
    return <UnsupportedChat error={error} hideHeader={hideHeader} />;
  }

  return (
    <ResolveChat
      conversationId={channel?.channelId}
      title={name}
      hideHeader={hideHeader}
    />
  );
}

function ResolveChat({
  conversationId,
  title,
  hideHeader,
}: {
  conversationId?: string;
  title?: string;
  hideHeader?: boolean;
}) {
  return (
    <Fragment>
      {hideHeader ? null : (
        <DetachedHeader title={title} subTitle={undefined} />
      )}
      <Navigate conversationId={conversationId} />
    </Fragment>
  );
}

function Navigate({ conversationId }: { conversationId?: string }) {
  const [, setStoredChats] = useMutableStoredChats();
  const [showRetry, setShowRetry] = useState(false);
  const [count, forceUpdate] = useForceUpdateCount();

  const { gotoChat } = useBlockNavigation();
  const pubnub = usePubNub();

  useEffect(() => {
    let stillCareAboutThis = true;

    if (conversationId) {
      // TODO fetch chat and make sure we have it
      pubnub.objects
        .getChannelMetadata({
          channel: conversationId,
          include: { customFields: true },
        })
        .then((meta) => {
          __DEV__ &&
            console.debug('[pubnub] resolved channel', conversationId, meta);

          const channel = meta.data;
          const custom = channel.custom || { type: 'error', refs: '[]' };

          const chat = merge<
            Omit<ChatMembership, 'custom'>,
            Pick<ChatMembership, 'custom'>
          >(channel, {
            custom: {
              type: custom.type as 'page',
              refs: JSON.parse(custom.refs as string),
            },
          });

          setStoredChats((prev) => {
            const next = prev || [];
            next.push(chat);

            return next.filter(
              (item, index, self) =>
                self.findIndex((i) => i.id === item.id) === index
            );
          });

          // Ensure subscribe -- this is free
          pubnub.subscribe({ channels: [conversationId], withPresence: false });

          // Trick typescript in thinking it is only passing one argument. This
          // is because the gotoChat function interface is not editable at the
          // moment. That's okay, we'll figure this out later.
          stillCareAboutThis &&
            gotoChat(...([conversationId, true] as unknown as [string]));
        })
        .catch((error) => {
          __DEV__ &&
            console.debug(
              '[pubnub] failed to resolve channel',
              conversationId,
              error,
              error.status
            );
          console.error(error, error.status);
          pubnub.reconnect();
          setShowRetry(true);
        });

      return () => {
        stillCareAboutThis = false;
      };
    }
  }, [gotoChat, conversationId, setStoredChats, setShowRetry, count]);

  if (showRetry) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          paddingHorizontal: 32,
        }}
      >
        <Caption style={{ textAlign: 'center' }}>Chat failed to load</Caption>
        <AccentButton
          onPress={() => {
            setShowRetry(false);
            forceUpdate();
          }}
        >
          Retry
        </AccentButton>
      </View>
    );
  }
  return (
    <View
      style={{
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        paddingHorizontal: 32,
      }}
    >
      <Caption style={{ textAlign: 'center' }}>
        {t('app.chats.resolve.resolving')}
      </Caption>
      <ProgressBar
        indeterminate
        style={{
          alignSelf: 'center',
          maxWidth: 500,
          width: '100%',
        }}
      />
    </View>
  );
}
