import { TactileGameMapPlace } from '@introcloud/api-client';
import React, { Fragment } from 'react';
import { MapPlace } from './MapPlace';

function MapPlaces_({
  items,
}: {
  items: {
    mapPlace: TactileGameMapPlace;
    mapPlaceId: string;
  }[];
}) {
  return (
    <Fragment>
      {items.map(({ mapPlace, mapPlaceId }) => (
        <MapPlace key={mapPlaceId} {...mapPlace} />
      ))}
    </Fragment>
  );
}

export const MapPlaces = React.memo(MapPlaces_);
