import {
  fetchApplicationLocations,
  TactileLocation,
  TactileNoPageRef,
  TactilePageRef,
} from '@introcloud/api-client';
import { cleanTag, locationTagIconFor } from '@introcloud/blocks';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { LOCATION_CACHE } from '../core/Cache';
import { NotReady } from '../core/errors/NotReady';
import { StoredMemoryValue, useMutableMemoryValue } from '../storage';
import { merge, SHOULD_DEBUG_FETCH } from '../utils';
import { useAbortController } from './useAbortController';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const LOCATIONS = new StoredMemoryValue<readonly PreparedLocation[]>(
  'application.locations.v1'
);

runOnLogout(() => {
  LOCATIONS.emit(null);
});

export type PreparedLocation = Omit<
  TactileLocation & {
    page: boolean;
  },
  'pageRef'
> & { pageRef: TactileNoPageRef | TactilePageRef };

export function useLocations({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly PreparedLocation[] | null,
  FetchMediaError | Error
> = {}) {
  const isFocused = useIsFocused();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const [storedLocations, setStoredLocations] =
    useMutableMemoryValue(LOCATIONS);
  const isMountedRef = useIsMounted();
  const abortable = useAbortController();

  const fetcher = useCallback(() => {
    if (!endpoint || !authorization) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      const result = await fetchApplicationLocations(
        endpoint,
        authorization!,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      const sorted = await sortLocations(result);
      const cached = await cacheLocations(sorted);
      const prepared = await prepareLocations(cached);

      isMountedRef.current && setStoredLocations(prepared);

      return prepared;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [endpoint, authorization, abortable, setStoredLocations]);

  const {
    data: locations,
    error,
    ...others
  } = useQuery([endpoint, 'application', 'locations'], fetcher, {
    placeholderData: storedLocations,
    enabled: enabled && isFocused && !!(authorization && endpoint),
    staleTime: 5 * 60 * 1000,
    ...options,
  });

  return {
    data: locations,
    loading: others.isLoading,
    error,
    reload: others.refetch,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}

export async function sortLocations(
  locations: readonly TactileLocation[]
): Promise<readonly TactileLocation[]> {
  return locations.slice().sort((a, b) => {
    const compareName = (a.name?.full || '').localeCompare(b.name?.full || '');
    if (compareName !== 0) {
      return compareName;
    }

    return a._id.localeCompare(b._id);
  });
}

export async function prepareLocations(
  locations: readonly TactileLocation[]
): Promise<readonly PreparedLocation[]> {
  return locations.map((location) => prepareLocation(location));
}

export function prepareLocation(location: TactileLocation): PreparedLocation {
  return {
    ...location,
    page: !!(location.pageRef && location.pageRef.pageId),
    name: {
      ...location.name,
      tag: (location.name.tag || [])
        .map(cleanTag)
        .filter((tag) => locationTagIconFor(tag)),
    },
  };
}

function cacheLocations(result: readonly TactileLocation[]) {
  const currentValue = LOCATION_CACHE.current;
  const nextValue = toMap(result);

  Object.keys(currentValue).forEach((key) => {
    if (!nextValue[key]) {
      delete currentValue[key];
    }
  });

  merge(currentValue, nextValue);
  return result;
}

function toMap<T extends { _id: string }>(
  items: readonly T[]
): Record<string, T> {
  return items.reduce((result, item) => {
    result[item._id] = item;
    return result;
  }, {} as Record<string, T>);
}
