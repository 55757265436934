import {
  BalanceOverViewState,
  fetchBalance,
  fetchPaymentMethods,
  topUp,
  PaymentMethodsState,
  transferMoney,
  TactileChatInfo,
} from '@introcloud/api-client';
import {
  AccentButton,
  OutlinedButton,
  PrimaryButton,
  TextButton,
} from '@introcloud/blocks';
import {
  BlockData,
  ProvideBlockData,
  useBlockData,
  useWindowWidth,
} from '@introcloud/blocks-interface';
import { useIsFocused } from '@react-navigation/native';
import Color from 'color';
import { makeUrl } from 'expo-linking';
import { FetchMediaError } from 'fetch-media';
import { t } from 'i18n-js';
import { FetchError } from 'node-fetch';
import React, {
  Fragment,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Linking, ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Avatar,
  Dialog,
  IconButton,
  List,
  Menu,
  Portal,
  useTheme,
  TextInput,
} from 'react-native-paper';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useChatImage } from '../chats/useChatImage';
import { useChatInitials } from '../chats/useChatInitials';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { queryClient } from '../core/QueryCache';
import { ThemedSnackbar } from '../core/ThemedSnackbar';
import {
  useAuthorization,
  useCurrentDomain,
  useEndpoint,
  useSafeAuthorization,
} from '../hooks/useAuthentication';
import { useRemoteCompany } from '../hooks/useCompany';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGroups, useGroupsDetached } from '../hooks/useGroup';

export function PaymentScreen({ asTab }: { asTab?: boolean }) {
  const { values } = useCompanyTabs();
  const tab = useMemo(
    () => values.find((tab) => tab.tab === 'payment'),
    [values]
  );
  const title = useMemo(() => tab?.title, [tab]);
  const icon = useMemo(() => (tab ? tab.icon.name : 'account-cash'), [tab]);
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const isFocused = useIsFocused();
  const [toppingUp, toggleTopUp] = useReducer((prev) => !prev, false);
  const [sending, toggleSend] = useReducer((prev) => !prev, false);

  const { groups, isLoading: isLoadingTargets } = useGroups({
    enabled: isFocused,
  });
  const hasTarget = groups?.some((group) => group.users.length > 0) || false;

  const { getImageUrl } = useBlockData();

  const provider = useMemo(() => {
    const getImageUrl = (
      imageId: string,
      targetSize:
        | 'icon_32'
        | 'icon_64'
        | 'icon_128'
        | 'icon_256'
        | 'icon_512'
        | 'icon_720'
        | 'icon_1440'
    ) => {
      if (!imageId || imageId.trim().length === 0) {
        return null;
      }

      return endpoint + `/image/${imageId}/${targetSize}`;
    };

    return { getImageUrl };
  }, [endpoint]);

  const { data, error, isLoading, dataUpdatedAt } = useQuery<
    BalanceOverViewState,
    FetchMediaError
  >(
    [authorization, 'app', 'balance'],
    () => fetchBalance(endpoint, authorization!),
    {
      notifyOnChangeProps: ['data', 'error', 'isLoading', 'dataUpdatedAt'],
      enabled: Boolean(endpoint) && Boolean(authorization) && isFocused,
    }
  );

  const balance = data?.ok ? data.value.toString() : '   ';

  const message = useRef<string>('');
  const [showingMessage, setShowingMessage] = useState(false);
  const hideMessage = useCallback(
    () => setShowingMessage(false),
    [setShowingMessage]
  );
  const showMessage = useCallback(
    (next: string) => {
      message.current = next;
      setShowingMessage(true);
    },
    [message, setShowingMessage]
  );

  return (
    <View style={{ flex: 1 }}>
      <EmptyState
        title={title || t('app.payment.title')}
        texts={{
          en: isLoading
            ? 'Retrieving your balance'
            : data
            ? `Your balance is ${balance
                .slice(0, balance.length - 2)
                .padStart(1, '0')}.${balance
                .slice(balance.length - 2)
                .padStart(2, '0')} EUR`
            : 'Your balance will be available later.',
          nl: isLoading
            ? 'Jouw balans aan het ophalen'
            : data
            ? `Jouw balans is ${balance
                .slice(0, balance.length - 2)
                .padStart(1, '0')}.${balance
                .slice(balance.length - 2)
                .padStart(2, '0')} EUR`
            : 'Jouw balans zal later beschikbaar zijn.',
        }}
        icon={icon}
        hidden={false}
      />
      <Header
        hideBack={asTab}
        title={title || t('app.payment.title')}
        subTitle={undefined}
        style={{ elevation: 2, zIndex: 2 }}
      />
      <View
        style={{
          position: 'absolute',
          bottom: 56,
          width: '100%',
          zIndex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <AccentButton
          icon="cash-plus"
          style={{ marginLeft: 'auto', marginRight: 4 }}
          onPress={toggleTopUp}
        >
          Top-up
        </AccentButton>

        <AccentButton
          icon="cash-refund"
          style={{ marginRight: 'auto', marginLeft: 4 }}
          onPress={toggleSend}
          loading={isLoadingTargets}
          disabled={!hasTarget}
        >
          Send
        </AccentButton>
      </View>
      <Portal>
        <Dialog
          visible={toppingUp}
          onDismiss={toggleTopUp}
          style={{
            maxWidth: 720,
            alignSelf: 'center',
            minWidth: 300,
            overflow: 'hidden',
          }}
        >
          <TopUpDialogContent visible={toppingUp && isFocused} />
          <Dialog.Actions>
            <TextButton onPress={toggleTopUp}>Cancel</TextButton>
          </Dialog.Actions>
        </Dialog>

        <ProvideBlockData provider={provider}>
          <Dialog
            visible={sending}
            onDismiss={toggleSend}
            style={{
              maxWidth: 720,
              alignSelf: 'center',
              minWidth: 300,
              overflow: 'hidden',
            }}
          >
            <SendDialogContent
              getImageUrl={getImageUrl}
              showMessage={showMessage}
              visible={sending && isFocused}
            />
            <Dialog.Actions>
              <TextButton onPress={toggleSend}>Cancel</TextButton>
            </Dialog.Actions>
          </Dialog>
        </ProvideBlockData>
      </Portal>
      <Portal>
        <ThemedSnackbar
          active={showingMessage}
          onDismiss={hideMessage}
          content={message.current}
          action={{ label: 'Ok', onPress: hideMessage }}
        />
      </Portal>
    </View>
  );
}

const DEFAULT_AMOUNT = [
  { euro: 15, cents: 1500 },
  { euro: 20, cents: 2000 },
  { euro: 25, cents: 2500 },
  { euro: 30, cents: 3000 },
  { euro: 40, cents: 4000 },
  { euro: 50, cents: 5000 },
  { euro: 60, cents: 6000 },
  { euro: 70, cents: 7000 },
  { euro: 80, cents: 8000 },
  { euro: 90, cents: 9000 },
  { euro: 100, cents: 10000 },
  { euro: 200, cents: 20000 },
];

function TopUpDialogContent({ visible }: { visible: boolean }) {
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const { data: company } = useRemoteCompany(useCurrentDomain());
  const [selectedMethod, setSelectedMethod] = useState<string | undefined>();

  const width = useWindowWidth();

  const amounts =
    ((company as any)?.settings?.payment?.amount as typeof DEFAULT_AMOUNT) ||
    DEFAULT_AMOUNT;
  const [selectedAmount, setSelectedAmount] = useState<number | null>(2);

  const { data } = useQuery<PaymentMethodsState, FetchMediaError>(
    [endpoint, 'app', 'payment', 'methods'],
    () => fetchPaymentMethods(endpoint, authorization!),
    {
      notifyOnChangeProps: ['data'],
      enabled: Boolean(endpoint) && Boolean(authorization) && visible,
    }
  );

  const { mutateAsync: startTopUp } = useMutation<
    unknown,
    FetchError,
    {
      amount: number;
      paymentMethod: string;
      bankId?: string | undefined;
    }
  >(
    ['app', 'payment', 'top-up'],
    async ({ amount, paymentMethod, bankId }) => {
      await queryClient.cancelQueries([authorization, 'app', 'balance']);

      const result = await topUp(endpoint, authorization, {
        methodId: paymentMethod,
        bankId,
        cents: __DEV__ ? 1 : amount,
        returnUrl: makeUrl('/payment'),
      });

      if (result.ok) {
        return Linking.openURL(result.url);
      }
    },
    {
      onSettled: async () => {
        return queryClient.invalidateQueries([authorization, 'app', 'balance']);
      },
    }
  );

  if (!data?.ok) {
    return (
      <Fragment>
        <Dialog.Title>Top-up</Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>Top-up</Dialog.Title>

      <View style={{ position: 'absolute', right: 12, top: 18 }}>
        <Menu
          anchor={
            <OutlinedButton onPress={() => setSelectedAmount(null)}>
              {selectedAmount !== null && amounts[selectedAmount]
                ? `${amounts[selectedAmount].euro} EUR`
                : 'Select an amount'}
            </OutlinedButton>
          }
          onDismiss={() => setSelectedAmount(2)}
          visible={selectedAmount === null}
        >
          {amounts.map((amount, index) => (
            <Menu.Item
              key={index}
              title={`${amount.euro} EUR`}
              onPress={() => setSelectedAmount(index)}
            />
          ))}
        </Menu>
      </View>

      <Dialog.ScrollArea
        style={{ paddingHorizontal: 0, maxHeight: 300, maxWidth: width * 0.9 }}
      >
        <ScrollView contentContainerStyle={{ margin: 0, paddingHorizontal: 0 }}>
          {selectedMethod ? (
            <List.Item
              left={() => <List.Icon icon="chevron-left" />}
              title="Different method"
              onPress={() => setSelectedMethod(undefined)}
            />
          ) : null}
          {selectedMethod
            ? (
                data.methods.find(
                  (method) => method.methodId === selectedMethod
                )?.banks || []
              ).map((method) => (
                <List.Item
                  key={method.name}
                  left={() => <List.Icon icon={{ uri: method.imageUrl }} />}
                  title={method.name}
                  onPress={() =>
                    startTopUp({
                      paymentMethod: selectedMethod!,
                      bankId: method.bankId,
                      amount: amounts[selectedAmount!].cents,
                    })
                  }
                />
              ))
            : null}
          {selectedMethod
            ? null
            : data.methods.map((method) => (
                <List.Item
                  key={method.name}
                  left={() => <List.Icon icon={{ uri: method.imageUrl }} />}
                  title={method.name}
                  onPress={
                    method.banks.length > 0
                      ? () => setSelectedMethod(method.methodId)
                      : () =>
                          startTopUp({
                            paymentMethod: method.methodId,
                            amount: amounts[selectedAmount!].cents,
                          })
                  }
                />
              ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

function SendDialogContent({
  visible,
  getImageUrl,
  showMessage,
}: {
  visible: boolean;
  getImageUrl: BlockData['getImageUrl'];
  showMessage: (next: string) => void;
}) {
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const { groups, isLoading } = useGroupsDetached({ enabled: visible });
  const [amount, setAmount] = useState('2.50');
  const [nextRecipient, setNextRecipient] = useState('');

  const width = useWindowWidth();
  const people = useMemo(
    () =>
      groups
        ? groups
            .flatMap((group) => group.users)
            .filter(
              (user, index, self) =>
                self.findIndex((item) => item._id === user._id) === index
            )
            .sort(
              (a, b) =>
                (a.name.full || '').localeCompare(b.name.full || '') ||
                a._id.localeCompare(b._id)
            )
        : [],
    [groups]
  );

  const { mutateAsync: startTransfer, isLoading: isSending } = useMutation<
    unknown,
    FetchError,
    {
      amount: number;
      recipientId: string;
    }
  >(
    ['app', 'payment', 'transfer'],
    async ({ amount, recipientId }) => {
      await queryClient.cancelQueries([authorization, 'app', 'balance']);

      const result = await transferMoney(endpoint, authorization, {
        recipientId,
        cents: amount,
      });

      return result;
    },
    {
      onSettled: async () => {
        return queryClient.invalidateQueries([authorization, 'app', 'balance']);
      },

      onSuccess: (_, variables) => {
        setNextRecipient('');
        showMessage(`Transferred ${(variables.amount / 100).toFixed(2)} EUR`);
      },

      onError: (error, variables) => {
        showMessage(
          `Did not transfer ${(variables.amount / 100).toFixed(2)} EUR: ${
            error.message
          }`
        );
      },
    }
  );

  if (isLoading || isSending) {
    return (
      <Fragment>
        <Dialog.Title>Transfer (send)</Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>Transfer (send)</Dialog.Title>

      <View style={{ position: 'relative', marginTop: -6, marginBottom: 6 }}>
        <TextInput
          keyboardType="decimal-pad"
          onChangeText={setAmount}
          value={amount}
          mode="outlined"
          label="Amount to send"
          style={{ marginHorizontal: 12 }}
        />
        <IconButton
          style={{ zIndex: 1, right: 12, top: 11, position: 'absolute' }}
          icon="plus-box"
          onPress={() => setAmount((parseFloat(amount) + 2.5).toFixed(2))}
          disabled={isNaN(parseFloat(amount))}
        />
      </View>

      <Dialog.ScrollArea
        style={{ paddingHorizontal: 0, maxHeight: 300, maxWidth: width * 0.9 }}
      >
        <ScrollView
          contentContainerStyle={{
            margin: 0,
            paddingHorizontal: 0,
            paddingVertical: 16,
          }}
        >
          {people.map((person) => (
            <List.Item
              key={person._id}
              left={() => <ChatAvatar info={person} />}
              style={{ paddingStart: 16 }}
              title={
                person.name.full ||
                [person.name.first, person.name.last].filter(Boolean).join(' ')
              }
              description={
                nextRecipient === person._id
                  ? `Press the send button to transfer ${amount} EUR`
                  : ' '
              }
              onPress={
                amount && parseFloat(amount) >= 0
                  ? () => setNextRecipient(person._id)
                  : undefined
              }
              right={
                nextRecipient === person._id
                  ? () =>
                      isSending ? (
                        <ActivityIndicator size="small" />
                      ) : (
                        <IconButton
                          icon="send"
                          accessibilityLabel="Send"
                          disabled={isNaN(parseFloat(amount)) || isSending}
                          onPress={() =>
                            startTransfer({
                              amount: Number(
                                (parseFloat(amount) * 100).toFixed(0)
                              ),
                              recipientId: person._id,
                            })
                          }
                        />
                      )
                  : undefined
              }
            />
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

function ChatAvatar({ info }: { info: TactileChatInfo | undefined }) {
  const image = useChatImage(info, 'icon_64');
  const initials = useChatInitials(info);
  const {
    colors: { primary, surface },
  } = useTheme();

  if (image) {
    return (
      <Avatar.Image
        source={{ uri: image, width: 64, height: 64 }}
        size={40}
        style={{ marginRight: 8, backgroundColor: surface }}
      />
    );
  }

  const color = new Color(primary);

  return (
    <Avatar.Text
      label={initials}
      size={40}
      style={{ marginRight: 8 }}
      color={color.isDark() ? '#fff' : '#000'}
    />
  );
}
