import { CURRENT_LOCALE } from '@introcloud/blocks/dist/TextBlock';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import I18n from 'i18n-js';

I18n.defaultLocale = Constants.manifest.extra['locale'];
I18n.fallbacks = { nl: 'en', en: 'nl' };

const deviceLocale =
  ((Localization.locale || '').split('-') || [I18n.defaultLocale])[0] ??
  I18n.defaultLocale;
I18n.locale =
  ['en', 'nl'].find((value) => value === deviceLocale) || I18n.defaultLocale;
