import { TactileChatInfo } from '@introcloud/api-client';
import { useQuery } from 'react-query';
import { useChatInfoFetcher } from '../hooks/useChatInfoFetcher';

export function useChatUserInfo(user: undefined | null | { id: string }) {
  const infoFetcher = useChatInfoFetcher('user');
  const { data: info } = useQuery(
    ['chat', 'user', user?.id, 'info'],
    () => infoFetcher(user!.id),
    {
      enabled: !!user?.id,
      staleTime: 15 * 1000 * 60,
    }
  );

  if (info && info.name && typeof info.name === 'string') {
    const compatibility: TactileChatInfo = {
      name: { full: info.name as unknown as string },
      image: { profile: '' },
    };
    return compatibility;
  }

  return info;
}
