import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useTranslationAction } from '@introcloud/blocks';
import Color from 'color';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Headline, Paragraph, useTheme } from 'react-native-paper';
import Animated, {
  call,
  Easing,
  eq,
  onChange,
  useCode,
} from 'react-native-reanimated';
import { useTimingTransition } from 'react-native-redash';
import { useIsMounted } from 'use-is-mounted';

const DEFAULT_LOCALE = Constants.manifest.extra.locale || 'en';

export function EmptyState({
  hidden,
  title,
  icon,
  texts,
  colorOverride,
}: {
  title: string;
  icon: string;
  hidden: boolean;
  texts: Record<string, string> & { en: string };
  colorOverride?: string;
}) {
  const [initialRender, setInitialRender] = useState(true);
  const isMounted = useIsMounted();
  const {
    colors: { primary },
  } = useTheme();
  const colorIsDark = new Color(colorOverride || primary).isDark();
  const onColor = colorIsDark ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)';

  const [translated] = useTranslationAction();
  const realLocale = translated
    ? DEFAULT_LOCALE === 'en'
      ? 'nl'
      : 'en'
    : DEFAULT_LOCALE;
  const [removed, setRemoved] = useState(hidden);

  const opacity = useTimingTransition(!hidden, {
    duration: 230,
    easing: Easing.out(Easing.ease),
  });

  useCode(
    () => [
      onChange(
        eq(opacity, 0),
        call([eq(opacity, 0)], ([next]) => {
          isMounted.current && setRemoved(!!next);
        })
      ),
    ],
    []
  );

  useEffect(() => {
    setTimeout(() => isMounted.current && setInitialRender(false), 0);
  }, []);

  if (removed) {
    return null;
  }

  return (
    <View
      style={{
        opacity: initialRender ? 0 : 1,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        zIndex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
      }}
      pointerEvents="none"
    >
      <Animated.View
        pointerEvents="none"
        nativeID="empty-chats"
        style={{
          backgroundColor: colorOverride || primary,
          width: '100%',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          opacity,
        }}
      >
        <View style={{ maxWidth: 300 }}>
          <MaterialCommunityIcons
            name={icon as any}
            color={onColor}
            size={128}
            style={{ alignSelf: 'center' }}
          />
          <Headline style={{ color: onColor, textAlign: 'center' }}>
            {title}
          </Headline>

          <Paragraph
            style={{
              color: onColor,
              marginTop: 30,
              textAlign: 'center',
            }}
          >
            {texts[realLocale as keyof typeof texts] || texts['en']}
          </Paragraph>
        </View>
      </Animated.View>
    </View>
  );
}
