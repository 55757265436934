import { mergeTranslations } from '@introcloud/blocks';
import * as Updates from 'expo-updates';
import { MemoryValue, useMutableMemoryValue } from 'expo-use-memory-value';
import { t } from 'i18n-js';
import React, { useCallback, useEffect, useState } from 'react';
import { Portal } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useIsMounted } from 'use-is-mounted';
import { Analytics } from '../analytics';
import { ThemedSnackbar } from './ThemedSnackbar';

mergeTranslations({
  en: {
    updater: {
      action: 'Restart',
      description: 'An update has been downloaded and is ready to launch.',
    },

    app: {
      updater: {
        action: 'Restart',
        description: 'An update has been downloaded and is ready to launch.',
      },
    },
  },

  nl: {
    updater: {
      action: 'Herstarten',
      description: 'Er staat een update voor je klaar.',
    },

    app: {
      updater: {
        action: 'Herstarten',
        description: 'Er staat een update voor je klaar.',
      },
    },
  },
});

const OTA_DEBUG = __DEV__ && false;
const LAST_UPDATE_CHECK = new MemoryValue<number>();

export const INTERVAL_OTA_CHECK = 1000 * 60 * 15;

export function OtaUpdater() {
  const [updateIsAvailable, setUpdateAvailable] = useState(false);

  const [lastUpdate, setLastUpdate] = useMutableMemoryValue(LAST_UPDATE_CHECK);
  const now = useInaccurateTimestamp({ every: 1000 * (__DEV__ ? 10 : 60) });
  const isMountedRef = useIsMounted();

  useEffect(() => {
    // Updated recently
    if (lastUpdate !== undefined && now - lastUpdate < INTERVAL_OTA_CHECK) {
      const diff = (now - lastUpdate) / 1000;
      OTA_DEBUG &&
        console.debug(
          `[ota] skipping because last check was ${diff}s ago, need ${
            INTERVAL_OTA_CHECK / 1000
          }s`
        );
      return;
    }

    OTA_DEBUG && console.debug('[ota] attempting ota');

    setLastUpdate(now);

    Updates.checkForUpdateAsync()
      .then((value) => {
        if (!value.isAvailable) {
          return Promise.reject(new Error('No updates available'));
        }

        return Updates.fetchUpdateAsync();
      })
      .then((value) => {
        if (!value.isNew) {
          return Promise.reject(new Error('No new updates available'));
        }

        Analytics.logEvent('UpdateAvailable', {
          releaseChannel: value.manifest.releaseChannel,
          releaseId: value.manifest.releaseId,
          revisionId: value.manifest.revisionId,
        });

        isMountedRef.current && setUpdateAvailable(true);
      })
      .catch(() => {});
  }, [now, lastUpdate, setUpdateAvailable]);

  const restart = useCallback(() => {
    Updates.reloadAsync().catch(() => {});
  }, []);

  return (
    <Portal>
      <ThemedSnackbar
        active={updateIsAvailable}
        onDismiss={noop}
        action={{ onPress: restart, label: t('app.updater.action') }}
      >
        {t('app.updater.description')}
      </ThemedSnackbar>
    </Portal>
  );
}

const noop = () => {};
