import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Header } from '../core/Header';
import { useAuthentication } from '../hooks/useAuthentication';

export function CustomScreen(props: {
  title?: string;
  url?: string;
  mode?: 'immersive' | 'normal';
  passAuth?: false | 'postMessage' | 'query';
  asTab?: boolean;
}) {
  const { authentication: token } = useAuthentication();
  const { title, url, mode, passAuth } = props;

  const actualUrl =
    url || 'https://www.rijksmuseum.nl/en/masterpieces-up-close';

  const authedUrl =
    passAuth === 'query'
      ? `${actualUrl}${actualUrl?.includes('?') ? '&' : '?'}token=${
          token?.token
        }&domain=${token?.domainFull}`
      : actualUrl;

  useEffect(() => {
    postMessage(
      {
        type: 'authenticate.tactile',
        value: token,
      },
      new URL(authedUrl).origin
    );
  }, [authedUrl, token]);

  return (
    <View style={{ flex: 1 }}>
      {mode !== 'immersive' ? (
        <Header title={title} subTitle={undefined} hideBack={props.asTab} />
      ) : null}
      <iframe
        src={authedUrl}
        frameBorder={0}
        style={{ marginTop: 0, flex: 1, border: 0 }}
        allowFullScreen
      />
    </View>
  );
}
