import { TactilePage, TactileVideo } from '@introcloud/api-client';
import { createContext, useContext } from 'react';

export type PlayerVideo = Partial<TactileVideo> & { pageId: string } & Partial<{
    page: TactilePage;
  }>;

export const PlayerContext = createContext<{
  video: undefined | PlayerVideo;
  setVideo: (next: { pageId: string } | undefined) => void;
}>({ video: undefined, setVideo: () => {} });

export function useVideoPlayer() {
  return useContext(PlayerContext);
}
