import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';

import { LocationsScreen } from './LocationsScreen';

mergeTranslations({
  en: {
    app: {
      actions: {
        filter: 'Filter',
      },
      location: {
        title: 'Location',
      },
      locations: {
        title: 'Map',

        search: 'Search...',
        no_results: 'Nothing found',
        no_address: 'Unaddressed',
        default_label: 'Events',
      },
    },
  },

  nl: {
    app: {
      actions: {
        filter: 'Filter',
      },
      location: {
        title: 'Locatie',
      },
      locations: {
        title: 'Kaart',

        search: 'Zoeken...',
        no_results: 'Niets gevonden',
        no_address: 'Zonder adres',
        default_label: 'Events',
      },
    },
  },
});

export function LocationsTab() {
  return <LocationsScreen asTab />;
}
