import { mergeTranslations } from '@introcloud/blocks';
import { t } from 'i18n-js';
import React, { useLayoutEffect, useState } from 'react';
import { Portal } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { useIsConnected } from '../hooks/useIsConnected';
import { ThemedSnackbar } from './ThemedSnackbar';

mergeTranslations({
  en: {
    app: {
      connectivity: {
        disconnected:
          "Can't reach the internet. Check your connection, or continue offline. Some pages or functionalities won't work properly until you re-connect.",
      },
    },
  },

  nl: {
    app: {
      connectivity: {
        disconnected:
          'Kan geen verbinding maken met het internet. Controleer jouw verbinding of ga offline door. Sommige paginas en/of functionaliteiten werken niet tot je bent verbonden',
      },
    },
  },
});

const noop = () => {};

export function Connectivity() {
  const isMountedRef = useIsMounted();
  const info = useIsConnected();
  const [isActive, setIsActive] = useState(false);

  useLayoutEffect(() => {
    if (info) {
      setIsActive(false);
      return;
    }

    const timeout = setTimeout(() => {
      if (isMountedRef.current) {
        setIsActive(true);
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [info]);

  return (
    <Portal>
      <ThemedSnackbar
        wrapperStyle={{ marginBottom: 54 }}
        active={isActive}
        onDismiss={noop}
      >
        {t('app.connectivity.disconnected')}
      </ThemedSnackbar>
    </Portal>
  );
}
