export function isDuringDay(
  dayStart: number,
  dayEnd: number,
  start: number,
  end: number
) {
  // Starts during this day
  if (start >= dayStart && start < dayEnd) {
    return true;
  }

  // Ends during this day
  if (end >= dayStart && end < dayEnd) {
    return true;
  }

  // Starts before this day and ends after this day
  if (start < dayStart && end > dayEnd) {
    return true;
  }

  // TODO: Active during entire day
  return false;
}
