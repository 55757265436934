import { TactileChatInfo } from '@introcloud/api-client';
import { useMemo } from 'react';

export function useChatInitials(
  info: Pick<TactileChatInfo, 'name'> | undefined
): string {
  const name = info?.name?.full;

  return useMemo(() => {
    if (!name) {
      return '';
    }

    const letters = name.split(' ').map((item) => item[0]);

    const upcases = letters
      .filter(Boolean)
      .filter((char) => char.toLocaleUpperCase() === char);

    if (upcases.length >= 2) {
      return upcases.slice(0, 2).join('');
    }

    return letters.slice(0, 2).join('').toLocaleUpperCase();
  }, [name]);
}
