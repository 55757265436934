import {
  fetchApplicationSwipePotentials,
  submitSwipeInterview,
  swipe,
  TactileInterviewAnswer,
  TactileInterviewAnswers,
  TactileInterviewQuestion,
  TactileModuleEventInterview,
  TactileModuleSwipe,
} from '@introcloud/api-client';
import { PrimaryButton } from '@introcloud/blocks';
import { FetchMediaError } from 'fetch-media';
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Keyboard } from 'react-native';
import {
  ActivityIndicator,
  Card,
  DarkTheme,
  HelperText,
  Paragraph,
  Text,
  TextInput,
  ThemeProvider,
} from 'react-native-paper';
import { useQuery } from 'react-query';
import { useAbortController } from '../hooks/useAbortController';
import { useAuthorization, useEndpoint } from '../hooks/useAuthentication';
import { PreparedEvent } from '../hooks/useEvents';
import { useSwipeInterviewGuest } from '../hooks/useSwipeInterviewGuest';
import { useUser } from '../hooks/useUser';
import { MemoryValue, useMutableMemoryValue } from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { AnimatedDialog } from './AnimatedDialog';
import { Profile, SelfProfile } from './Profile';
import { Profiles } from './Profiles';

function SwipeEvent_({
  _id,
  module: {
    interview: { question },
    swipe: { card },
  },
}: PreparedEvent & {
  module: { interview: TactileModuleEventInterview; swipe: TactileModuleSwipe };
}) {
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const { data: guest, error, loading, reload } = useSwipeInterviewGuest(_id);

  const [submittingInterview, setSubmittingInterview] = useState(false);
  const needsToAcceptTheTos =
    error && error instanceof FetchMediaError && error.response.status === 404;

  const applicableInterviewQuestions = question.filter(
    (q) => q.type === 'toggle'
  );

  // Has not yet started filling in the profile
  const needsToFillInProfile =
    (!loading && !guest && error && error instanceof FetchMediaError) ||
    (guest &&
      applicableInterviewQuestions.length !== 0 &&
      guest.module.interview.answer.length === 0);

  // Needs to fill in at least one question
  const needsInterview =
    applicableInterviewQuestions.length - 1 >
    (guest?.module.interview.answer.length || 0);

  const [showDialog, setShowDialog] = useState<boolean | undefined>(undefined);
  const nextGuest = useRef<TactileInterviewAnswers>({
    module: { interview: { answer: [] } },
  });

  const submit = useCallback(() => {
    setSubmittingInterview(true);

    return submitSwipeInterview(
      _id,
      nextGuest.current.module.interview.answer,
      endpoint,
      authorization,
      undefined,
      SHOULD_DEBUG_FETCH
    )
      .then(() => reload({})) // TODO: was undefined, true
      .then(() => setSubmittingInterview(false))
      .catch((error) => {
        console.error(error);
        setSubmittingInterview(false);
      });
  }, [reload, nextGuest]);

  // Show the dialog if we haven't yet. This needs to be an effect because the
  // useSwipeInterviewGuest might not give back an answer during the first render.
  // If the user has not yet filled in their profile, we'll also just display this
  // again.
  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    if (typeof showDialog === 'boolean') {
      return;
    }

    setShowDialog(needsToAcceptTheTos || needsToFillInProfile);
  }, [needsToAcceptTheTos, needsToFillInProfile, showDialog, loading]);

  // Update known profile
  useEffect(() => {
    if (!guest) {
      return;
    }

    nextGuest.current = {
      module: { interview: { answer: guest?.module.interview.answer } },
    };
  }, [guest?._id]);

  const isReady = !loading && showDialog !== undefined;

  if (!isReady) {
    return null;
  }

  if (submittingInterview) {
    return (
      <ActivityIndicator
        size="large"
        style={{ alignSelf: 'center', margin: 32, flex: 1 }}
      />
    );
  }

  if (showDialog) {
    return (
      <ShowTermsOfService
        onAccept={(profileText) => {
          const profileQuestion = question[0];

          // Delete old answer
          const hasCurrent =
            nextGuest.current.module.interview.answer.findIndex(
              (answer) => answer.questionId === profileQuestion._id
            );

          if (hasCurrent !== -1) {
            nextGuest.current.module.interview.answer.splice(hasCurrent, 1);
          }

          nextGuest.current.module.interview.answer.unshift({
            questionId: profileQuestion._id,
            value: profileText,
          });

          setShowDialog(false);
        }}
      />
    );
  }

  if ((guest || error) && needsInterview) {
    return (
      <ConductInterview
        questions={question}
        guestRef={nextGuest}
        onFinish={submit}
      />
    );
  }

  if (!guest) {
    return null;
  }

  return (
    <SwipeForMatches
      event={_id}
      interview={applicableInterviewQuestions}
      interviewAnswers={guest.module.interview.answer}
      cardType={card?.image || 'big'}
    />
  );
}

function ShowTermsOfService({ onAccept }: { onAccept(text: string): void }) {
  const [preAccepted, setPreAccepted] = useState(false);

  const onPreAccept = useCallback(() => setPreAccepted(true), [setPreAccepted]);

  return (
    <AnimatedDialog onAccept={onAccept}>
      {(accept) =>
        preAccepted ? (
          <InputDescription onAccept={accept} />
        ) : (
          <Terms onAccept={onPreAccept} />
        )
      }
    </AnimatedDialog>
  );
}

function Terms({ onAccept }: { onAccept: () => void }) {
  return (
    <Card
      elevation={4}
      style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
    >
      <Card.Content>
        <Paragraph style={{ lineHeight: 22 }}>
          By using the matching module you accept that some personal data (name,
          interests, profile picture and age) is shared with other users. The
          data is collected <Text style={{ fontWeight: 'bold' }}>only</Text> for
          matching you with others with similar interest. All data falls under
          the general Terms and Conditions &amp; Privacy Policy.
        </Paragraph>
      </Card.Content>
      <Card.Actions style={{ padding: 12, justifyContent: 'center' }}>
        <PrimaryButton onPress={onAccept}>I accept</PrimaryButton>
      </Card.Actions>
    </Card>
  );
}

function InputDescription({ onAccept }: { onAccept: (text: string) => void }) {
  const [chosenText, setChosenText] = useState('');
  const { data: user } = useUser();

  return (
    <Card
      elevation={4}
      style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
    >
      <Card.Content>
        <Paragraph style={{ lineHeight: 22, marginBottom: 16 }}>
          Okay {user?.name.first}, write a little bit about yourself (max. 200
          chars). This text will be displayed to others who are swiping.{' '}
          <Text style={{ fontWeight: 'bold' }}>
            You will only see people with similar interests, so make sure you
            stand out in the 200 characters below.
          </Text>
        </Paragraph>
        <TextInput
          multiline
          label="About me"
          style={{ height: 150 }}
          value={chosenText}
          onChangeText={setChosenText}
          maxLength={200}
          returnKeyType="done"
          onSubmitEditing={() => {
            Keyboard.dismiss();
            chosenText && onAccept(chosenText);
          }}
        />
        <HelperText style={{ textAlign: 'right' }} type="info">
          {chosenText.length} / 200
        </HelperText>
      </Card.Content>
      <Card.Actions style={{ padding: 12, justifyContent: 'center' }}>
        <PrimaryButton
          onPress={() => {
            Keyboard.dismiss();
            onAccept(chosenText);
          }}
          disabled={chosenText.length === 0}
        >
          I'm ready
        </PrimaryButton>
      </Card.Actions>
    </Card>
  );
}

function ConductInterview_({
  questions,
  guestRef,
  onFinish,
}: {
  questions: TactileInterviewQuestion[];
  guestRef: React.MutableRefObject<TactileInterviewAnswers>;
  onFinish: () => void;
}) {
  const swipeQuestions = useMemo(
    () => questions.filter((q) => q.type === 'toggle'),
    [questions]
  );
  const onSwipe = useCallback(
    (profile: Profile, kind: 'like' | 'dislike') => {
      const guestAnswers = guestRef.current.module.interview.answer;
      const hasCurrent = guestAnswers.findIndex(
        (answer) => answer.questionId === profile.id
      );

      // Replace or insert this answer
      guestAnswers.splice(
        hasCurrent === -1 ? guestAnswers.length : hasCurrent,
        hasCurrent === -1 ? 0 : 1,
        {
          questionId: profile.id,
          value: kind === 'like',
        }
      );

      // Check if done
      if (
        swipeQuestions.every((q) =>
          guestAnswers.find((a) => a.questionId === q._id)
        )
      ) {
        onFinish();
      }
    },
    [swipeQuestions, guestRef, onFinish]
  );

  const profiles: Profile[] = useMemo(
    () =>
      swipeQuestions
        .map((q) => ({
          id: q._id,

          title: q.description || '',
          description: q.options.helpText || q.options.subquestion || '',
          image: undefined,
        }))
        // Remove previously answered
        .filter(
          (q) =>
            !guestRef.current.module.interview.answer.some(
              (a) => a.questionId === q.id
            )
        )
        .reverse(),
    [swipeQuestions]
  );

  return (
    <ThemeProvider theme={DarkTheme}>
      <Profiles
        profiles={profiles}
        onSwipe={onSwipe}
        showCount
        cardType="small"
      />
    </ThemeProvider>
  );
}

const ConductInterview = memo(ConductInterview_);

const POTENTIALS_STATE = new MemoryValue(Math.random());

function SwipeForMatches({
  event,
  interview,
  interviewAnswers,
  cardType,
}: {
  event: string;
  interview: TactileInterviewQuestion[];
  interviewAnswers: TactileInterviewAnswer[];
  cardType: NonNullable<TactileModuleSwipe['card']>['image'];
}) {
  const [cacheState, setCacheState] = useMutableMemoryValue(POTENTIALS_STATE);
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const abortable = useAbortController();
  const requiredAnswers = useRef<string[]>([]);

  const fetcher = useCallback(() => {
    const ac = abortable();

    async function call() {
      await new Promise((resolve) => setTimeout(resolve, 1250));

      const potentials = await fetchApplicationSwipePotentials(
        event,
        endpoint,
        authorization,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      requiredAnswers.current = potentials.map((p) => p._id);

      return potentials;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [abortable]);

  const {
    data: potentials,
    error,
    refetch,
  } = useQuery([cacheState, 'swipe.potentials'], fetcher, {
    refetchOnWindowFocus: false,
  });

  const profiles: Profile[] = useMemo(
    () =>
      potentials
        ? potentials
            .map((q) => ({
              id: q._id,

              title: q.userRef.user.name.first || '',
              description:
                (q.module.interview.answer[0]?.value as string) || '',
              image: q.userRef.user.image?.profile || undefined,
              likes: q.module?.interview.answer
                .filter((a) => a.value === true)
                .map((l) => l.questionId),
              dislikes: q.module?.interview.answer
                .filter((a) => a.value === false)
                .map((l) => l.questionId),
            }))
            // Remove previously answered
            .reverse()
        : [],
    [potentials]
  );

  const onSwipe = useCallback(
    (profile: Profile, kind: 'like' | 'dislike') => {
      const index = requiredAnswers.current.indexOf(profile.id);
      if (index !== -1) {
        requiredAnswers.current.splice(index, 1);
      }

      swipe(
        event,
        profile.id,
        kind,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      ).catch(console.error);

      if (requiredAnswers.current.length === 0) {
        refetch();
        setCacheState(Math.random());
      }
    },
    [setCacheState, requiredAnswers]
  );

  const selfProfile = useMemo(
    () =>
      interview.reduce((result, question) => {
        result[question._id] = {
          label: question.description || '',
          value: interviewAnswers.find(
            (answer) => answer.questionId === question._id
          )?.value as boolean | undefined,
        };
        return result;
      }, {} as SelfProfile),
    [interview, interviewAnswers]
  );

  if (!error && !potentials) {
    return (
      <ActivityIndicator
        size="large"
        style={{ alignSelf: 'center', margin: 32, flex: 1 }}
      />
    );
  }

  if (profiles.length === 0) {
    return <NoOneAround />;
  }

  return (
    <Profiles
      profiles={profiles}
      selfProfile={selfProfile}
      onSwipe={onSwipe}
      showCount
      cardType={cardType}
    />
  );
}

function NoOneAround() {
  return (
    <AnimatedDialog onAccept={() => {}}>
      {(accept) => (
        <Card
          elevation={4}
          style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
        >
          <Card.Content>
            <Paragraph style={{ lineHeight: 22 }}>
              We don't have any potential matches for you. Come back later!
            </Paragraph>
          </Card.Content>
        </Card>
      )}
    </AnimatedDialog>
  );
}

export const SwipeEvent = memo(SwipeEvent_);
