import { submitUserImage } from '@introcloud/api-client';
import { AccentButton } from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, { Fragment, useCallback, useState } from 'react';
import { Image, View } from 'react-native';
import {
  Caption,
  Card,
  Dialog,
  Portal,
  Title,
  useTheme,
} from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { FileDialogContent } from '../goals/FileDialogContent';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useUser } from '../hooks/useUser';
import { SHOULD_DEBUG_FETCH } from '../utils';

export function ProfileCard() {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const { data: user, error, loading, reload } = useUser();
  const theme = useTheme();
  const { getImageUrl } = useBlockData();

  const isMountedRef = useIsMounted();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastError, setLastError] = useState<null | Error>(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const submit = useCallback(
    (data: FormData) => {
      if (!user?._id || !authorization) {
        return;
      }

      setIsSubmitting(true);
      setLastError(null);

      if (typeof data.has === 'undefined') {
        data.has = (key: string) => key === 'filepond';
      }

      submitUserImage(
        user?._id,
        data,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      )
        .then(() => {
          if (!isMountedRef.current) {
            return;
          }

          setIsSubmitting(false);
          setDialogVisible(false);
          reload();
        })
        .catch((err: Error) => {
          console.error(err);
          if (!isMountedRef.current) {
            return;
          }

          setIsSubmitting(false);
          setLastError(err);
        });
    },
    [user, endpoint, authorization]
  );

  return (
    <Fragment>
      <Card.Content style={{ marginVertical: 16 }}>
        <View style={{ flexDirection: 'row' }}>
          <Card
            style={{ width: 96, height: 96 }}
            onPress={() => setDialogVisible(true)}
          >
            <Image
              source={{
                uri:
                  getImageUrl(user?.image.profile || '', 'icon_256') ||
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                width: 256,
                height: 256,
              }}
              style={{
                width: 96,
                height: 96,
                borderRadius: theme.roundness,
              }}
              resizeMode="cover"
            />
          </Card>

          <View style={{ marginLeft: 16 }}>
            <Title>{user?.name?.first || user?.name?.full || ' '}</Title>
            <Caption>{user?.email?.value || ' '}</Caption>
            <AccentButton
              icon="camera"
              onPress={() => setDialogVisible(true)}
              compact
              style={{ marginTop: 3 }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {t('app.actions.change_image')}
            </AccentButton>
          </View>
        </View>
      </Card.Content>
      <Portal>
        <Dialog
          visible={dialogVisible}
          onDismiss={() => {
            setDialogVisible(false);
          }}
          style={{
            maxWidth: 720,
            alignSelf: 'center',
            minWidth: 300,
            overflow: 'hidden',
          }}
        >
          <Dialog.Content>
            <FileDialogContent
              formDataKey="filepond"
              kind="image"
              loading={isSubmitting || loading}
              onDismiss={() => {
                setDialogVisible(false);
              }}
              onSubmit={(data: string | File | Blob | FormData) => {
                submit(data as FormData);
              }}
              error={lastError}
            />
            <Dialog.Actions style={{ padding: 0, marginTop: 16 }}>
              <Caption>{t('app.profile.image.caching')}</Caption>
            </Dialog.Actions>
          </Dialog.Content>
        </Dialog>
      </Portal>
    </Fragment>
  );
}
