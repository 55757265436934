import { TactilePage } from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import Constants from 'expo-constants';
import { PAGE_CACHE } from '../core/Cache';
import { useIsAuthenticated } from './useAuthentication';

function getPlaceholderPage(pageId: string): TactilePage | null {
  const result = PAGE_CACHE.get(pageId);
  if (result && result.content) {
    return result as TactilePage;
  }

  try {
    const bundled = Constants.manifest.extra[`page:seed:${pageId}`];
    if (bundled) {
      const parsed = JSON.parse(bundled);
      if (parsed && parsed.content) {
        return parsed;
      }
    }
  } catch {}

  return null;
}

export function usePage(
  pageId: string | null | undefined,
  getInfoById: (pageId: string) => Promise<TactilePage>,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<TactilePage | null, FetchMediaError | Error> = {}
) {
  const isAuthenticated = useIsAuthenticated();
  const initialPage = pageId ? getPlaceholderPage(pageId) : null;

  const fetcher = useCallback(
    () => getInfoById(pageId!),
    [getInfoById, pageId]
  );

  const {
    data: page,
    error,
    ...others
  } = useQuery(['application', 'page', pageId], fetcher, {
    placeholderData: initialPage || undefined,
    enabled: enabled && !!pageId && isAuthenticated,
    staleTime: 30 * 1000,
    ...options,
  });

  return {
    page,
    revalidate: others.refetch,
    error,
    loading: others.isLoading,
    ...others,
  };
}
