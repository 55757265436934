import { EventData, EventListItemOptions } from '@introcloud/blocks';
import { BlockData } from '@introcloud/blocks-interface';
import Constants from 'expo-constants';
import { makeUrl, openURL } from 'expo-linking';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { PreparedEvent } from './hooks/useEvents';

export function getSafeAbortController(): Pick<AbortController, 'abort'> & {
  signal: AbortSignal | undefined;
} {
  if (typeof AbortController === 'undefined') {
    return {
      signal: undefined,
      abort: () => {},
    };
  }

  return new AbortController();
}

export const IS_TEST_RELEASE = (
  Constants.manifest.releaseChannel || ''
).startsWith('test');

export const SHOULD_ALLOW_DEBUG = __DEV__ || IS_TEST_RELEASE;
export const SHOULD_DEBUG_FETCH = false;

export function extractEventData(
  data: PreparedEvent | null,
  other: EventListItemOptions,
  utils: { getImageUrl: BlockData['getImageUrl'] }
): (EventData & EventListItemOptions) | null {
  if (data === null) {
    return null;
  }

  const imageId = data.image ? data.image.profile || data.image.banner : null;

  return {
    id: data._id,
    title: data.name.full,
    description: data.name.description || '',
    duration: {
      start: data.duration?.start?.unix || 0,
      end: data.duration?.end?.unix || 0,
    },
    tags: data.name.tag || [],
    locationIds: (data.locationRef || [])
      .map((ref) => ref.locationId)
      .filter(Boolean) as string[],
    fallbackLocation: undefined,
    illustration: imageId
      ? utils.getImageUrl(imageId, 'icon_128') || undefined
      : undefined,
    hasTimeslots: data.hasTimeslots,
    ...other,
    loading: false,
  };
}

const LISTENER_REF = { current: openURL };

export function setLocalDeeplinkListener(
  listener: null | ((url: string) => Promise<true>)
): void {
  if (!listener) {
    LISTENER_REF.current = openURL;
  } else {
    LISTENER_REF.current = listener;
  }
}

export function openLocalDeeplink(
  path: string,
  queryParams?: Parameters<typeof makeUrl>[1]
) {
  const fullUrl = makeUrl(path, queryParams);
  return LISTENER_REF.current(fullUrl);
}

export { merge, cloneDeep };
