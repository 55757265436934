import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';

import { PaymentScreen } from './PaymentScreen';

mergeTranslations({
  en: {
    app: {
      payment: {
        title: 'Your balance',
      },
    },
  },

  nl: {
    app: {
      payment: {
        title: 'Jouw balans',
      },
    },
  },
});

export function PaymentTab() {
  return <PaymentScreen asTab />;
}
