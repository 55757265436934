import { EventList, ResponsiveImage, TextBlock } from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import { useRoute } from '@react-navigation/native';
import { t } from 'i18n-js';
import React, { useMemo } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { localeLongWeekDayString } from 'react-native-time-helpers';
import { BlockProvision } from '../core/BlockProvision';
import { Header } from '../core/Header';
import { RouteProp } from '../core/Routes';
import { STATIC_SPONSORS_ENABLED } from '../features';
import { useCompanyEventDays } from '../hooks/useCompanyEventDays';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useDayBoundaries } from '../hooks/useDayBoundaries';
import { useEvents } from '../hooks/useEvents';
import { extractEventData } from '../utils';
import { isDuringDay } from './isDuringDay';
import { RandomSponsor, StaticSponsor } from './Sponsor';

const RATIO: [number, number] = [16, 10];

export function EventDayScreen() {
  const route = useRoute<RouteProp<'EventDay'>>();
  const dayId = route.params['day'];
  const date = new Date(`${dayId}T11:00:00Z`);
  const day = localeLongWeekDayString(date);

  const title =
    useCompanyTabs().values.find((tab) => tab.tab === 'event-days')?.title ||
    t('app.calendar.title');

  return (
    <BlockProvision screen="EventDayScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({
            web: '100vh',
            default: '100%',
          }),
        }}
      >
        <Header
          title={title}
          subTitle={day}
          backFallback={{
            screen: 'Tabs',
            params: { screen: 'EventDays' },
          }}
        />
        <ScrollView
          nativeID="scroller"
          style={{
            flex: 1,
            maxHeight: '100%',
          }}
          contentContainerStyle={{
            maxWidth: 720,
            alignSelf: 'center',
            paddingBottom: 56,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <View style={{ width: '100%' }}>
            <EventDayImage day={dayId} />
            <TextBlock text={day} options={{ size: 'title' }} />
            <EventDayList date={date} />
            <Sponsor day={dayId} />
          </View>
        </ScrollView>
      </View>
    </BlockProvision>
  );
}

function EventDayList({ date }: { date: Date | number | string }) {
  const { getImageUrl } = useBlockData();
  const [dayBoundaryStart, dayBoundaryEnd] = useDayBoundaries(date);

  const { data: items } = useEvents();
  const events = useMemo(
    () =>
      (items || [])
        .filter((event) => {
          return (
            event.hierarchy.showInCalendar &&
            isDuringDay(
              dayBoundaryStart,
              dayBoundaryEnd,
              event.duration.start.unix,
              event.duration.end.unix
            )
          );
        })
        .map(
          (event) =>
            extractEventData(event, { loading: false }, { getImageUrl }) ||
            event._id
        ),
    [items, dayBoundaryStart, dayBoundaryEnd]
  );

  return <EventList events={events} />;
}

function EventDayImage({ day }: { day: string }) {
  const { getImageUrl } = useBlockData();
  const days = useCompanyEventDays();
  const imageSource = getImageUrl(
    (days?.images || {})[day] || '',
    'icon_512'
  )?.replace('/icon_512', '/icon_1440');

  if (!imageSource) {
    return null;
  }

  return (
    <ResponsiveImage
      src={imageSource || ''}
      maxWidth={720}
      ratio={RATIO[0] / RATIO[1]}
      mode="cover"
    />
  );
}

function Sponsor({ day }: { day: string }) {
  if (STATIC_SPONSORS_ENABLED) {
    return <StaticSponsor day={day} />;
  }
  return <RandomSponsor day={day} />;
}
