import { UrlMissing } from './errors/UrlMissing';

export async function openExternalUrl(url: string | null | undefined) {
  const url_ = url && url.trim();

  if (!url_) {
    throw new UrlMissing();
  }

  const finalUrl = url_?.includes(':') ? url_ : `https://${url_}`;

  const anchor = document.createElement('a');
  anchor.setAttribute('rel', 'noreferrer noopener');
  anchor.setAttribute('href', finalUrl);
  anchor.setAttribute('target', '__blank');
  anchor.click();
}
