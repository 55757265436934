import { Theme } from '@react-navigation/native';
import { useTheme } from 'react-native-paper';

export function useNavigationTheme(): Theme {
  const theme = useTheme();

  return {
    colors: {
      primary: theme.colors.primary,
      background: theme.colors.background,
      card: theme.colors.surface,
      border: theme.colors.placeholder,
      text: theme.colors.text,
      notification: theme.colors.notification,
    },
    dark: theme.dark,
  };
}
