import { useBlockData } from '@introcloud/blocks-interface';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Header } from '../core/Header';
import { useChatInfoFetcher } from '../hooks/useChatInfoFetcher';
import { ChatMembership } from '../hooks/useChats';
import { useNameFetcher } from '../hooks/useNameFetcher';
import { useUser } from '../hooks/useUser';

export function HeaderComponent({
  kind,
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const Component = getHeaderComponent(kind as any);
  return <Component kind={kind} refs={refs} />;
}

function getHeaderComponent(
  kind:
    | 'page'
    | 'group'
    | 'page-group'
    | 'page-1-on-1'
    | '1-on-1'
    | 'self'
    | undefined
): React.ComponentType<{
  kind: string;
  refs: ChatMembership['custom']['refs'];
}> {
  switch (kind) {
    case 'page-1-on-1':
    case 'page':
      return PageChatHeader;
    case 'page-group':
      return PageGroupChatHeader;
    case 'group':
      return GroupChatHeader;
    case '1-on-1':
      return UserChatHeader;
    default:
      return () => (
        <Header
          title="Unknown chat"
          subTitle={kind}
          backFallback={{ screen: 'Tabs', params: { screen: 'Chats' } }}
        />
      );
  }
}

function PageChatHeader({
  kind,
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const page = useMemo(() => refs?.find((ref) => ref.model === 'page'), [refs]);

  const nameFetcher = useNameFetcher('page');

  const { data: name } = useQuery(
    ['chat', 'page', page?.id, 'name'],
    () => nameFetcher(page!.id),
    {
      enabled: !!page?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  return (
    <Header
      title={name || ' '}
      subTitle={kind}
      backFallback={{ screen: 'Tabs', params: { screen: 'Chats' } }}
    >
      <ChatNotificationToggle />
    </Header>
  );
}

function PageGroupChatHeader({
  kind,
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const page = useMemo(() => refs?.find((ref) => ref.model === 'page'), [refs]);
  const group = useMemo(
    () => refs?.find((ref) => ref.model === 'group'),
    [refs]
  );

  const nameFetcher = useNameFetcher('page');
  const groupNameFetcher = useNameFetcher('group');

  const { data: name } = useQuery(
    ['chat', 'page', page?.id, 'name'],
    () => nameFetcher(page!.id),
    {
      enabled: !!page?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  const { data: groupName } = useQuery(
    ['chat', 'group', group?.id, 'name'],
    () => groupNameFetcher(group!.id),
    {
      enabled: !!group?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  return (
    <Header
      title={name ? (groupName ? `${name} (${groupName})` : name) : ' '}
      subTitle={kind}
      backFallback={{ screen: 'Tabs', params: { screen: 'Chats' } }}
    >
      <ChatNotificationToggle />
    </Header>
  );
}

function GroupChatHeader({
  kind,
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const group = useMemo(
    () => refs?.find((ref) => ref.model === 'group'),
    [refs]
  );

  const nameFetcher = useNameFetcher('group');

  const { data: name } = useQuery(
    ['chat', 'group', group?.id, 'name'],
    () => nameFetcher(group!.id),
    {
      enabled: !!group?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  return (
    <Header
      title={name || ' '}
      subTitle={kind}
      backFallback={{ screen: 'Tabs', params: { screen: 'Chats' } }}
    >
      <ChatNotificationToggle />
    </Header>
  );
}

function UserChatHeader({
  kind,
  refs,
}: {
  kind: string;
  refs: ChatMembership['custom']['refs'];
}) {
  const { getImageUrl } = useBlockData();
  const { data: self } = useUser();
  const user = useMemo(
    () =>
      self?._id
        ? refs?.find((ref) => ref.model === 'user' && ref.id !== self._id)
        : undefined,
    [refs, self?._id]
  );

  const infoFetcher = useChatInfoFetcher('user');

  const { data: info } = useQuery(
    ['chat', 'user', user?.id, 'name', 'info'],
    () => infoFetcher(user!.id),
    {
      enabled: !!user?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  const image = useMemo(
    () => getImageUrl(info?.image?.profile || '', 'icon_64'),
    [info?.image?.profile]
  );

  return (
    <Header
      title={info?.name?.full || ' '}
      subTitle={kind}
      backFallback={{ screen: 'Tabs', params: { screen: 'Chats' } }}
      image={image}
    >
      <ChatNotificationToggle />
    </Header>
  );
}

function ChatNotificationToggle() {
  return null;
}
