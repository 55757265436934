import { TactileNewsItems } from '@introcloud/api-client';
import { useBlockData } from '@introcloud/blocks-interface';
import { ActionsBlock } from '@introcloud/page';
import React, { useMemo } from 'react';

export function Highlights({ items }: { items: TactileNewsItems }) {
  const { getImageUrl } = useBlockData();

  const value = useMemo(
    () => ({
      label: 'Highlights',
      items: items.slice(0, 4).map((item) => ({
        label: item.name.full,
        image: getImageUrl(item.image.profile || '', 'icon_720') || '',
        destination: {
          kind: 'info',
          value: item.pageRef.pageId || (item.pageRef as any)?.page?._id,
        } as const,
      })),
    }),
    [items]
  );

  const options = useMemo(
    () => ({ mode: 'image-grid', resizeMode: 'cover' } as const),
    []
  );

  return (
    <ActionsBlock
      value={value}
      options={options}
      id="highlights"
      kind="actions"
      marked={false}
      editing={false}
      dragging={false}
      anyEditing={false}
    />
  );
}
