import Constants from 'expo-constants';
import merge from 'lodash.merge';

const { features, blocks, banner } = merge(
  { features: [], blocks: [], banner: undefined },
  JSON.parse(
    Constants.manifest.extra['custom'] ||
      JSON.stringify({ features: [], blocks: [], banner: null })
  )
);

const tabColors: undefined | string[] =
  JSON.parse(Constants.manifest.extra['tab-colors'] || 'null') || undefined;

const gelegraaf:
  | undefined
  | {
      endpoint: string;
      'api-key': string;
      first: { edition: string; timestamp: string };
      last: { edition: string; timestamp: string };
    } =
  JSON.parse(Constants.manifest.extra['gelegraaf'] || 'null') || undefined;

const staticSponsors: undefined | { [day: string]: string } =
  JSON.parse(Constants.manifest.extra['static-sponsors'] || 'null') ||
  undefined;

const tagOverrides: undefined | { [tag: string]: string } =
  JSON.parse(Constants.manifest.extra['tag-overrides'] || 'null') || undefined;

const mapFilter: null | string =
  Constants.manifest.extra['initial-map-filter'] || null;

const SWITCH_MAP_SWIPER = 'map-swiper';
const SWITCH_GELEGRAAF = 'gelegraaf';
const SWITCH_EXPERIENCE = 'experience';
const SWITCH_INFO_MARKET = 'info-market';
const SWITCH_RIJKSMUSEUM = 'rijksmuseum';
const SWITCH_ZOO_AUDIO_TOUR = 'zoo-audio-tour';
const SWITCH_TAB_COLORS = 'tab-colors';
const SWITCH_MULTI_COMPANY = 'multi-company';
const SWITCH_CUSTOM_EMBED = 'custom-embed';
const SWITCH_TAG_OVERRIDES = 'tag-overrides';
const SWITCH_STATIC_SPONSORS = 'static-sponsors';
const SWITCH_CHAT_BUTTONS = 'chat-buttons';

export const MAP_SWIPER_ENABLED = features.includes(SWITCH_MAP_SWIPER);
export const GELEGRAAF_ENABLED = features.includes(SWITCH_GELEGRAAF);
export const EXPERIENCE_ENABLED = features.includes(SWITCH_EXPERIENCE);
export const MULTI_COMPANY_ENABLED = features.includes(SWITCH_MULTI_COMPANY);
export const TAB_COLORS_ENABLED = features.includes(SWITCH_TAB_COLORS);
export const PAGE_COLORS_ENABLED = true;
export const TAG_OVERRIDES_ENABLED = features.includes(SWITCH_TAG_OVERRIDES);
export const CHAT_BUTTONS_ENABLED = features.includes(SWITCH_CHAT_BUTTONS);
export const STATIC_SPONSORS_ENABLED = features.includes(
  SWITCH_STATIC_SPONSORS
);

export const BLOCK_GELEGRAAF = blocks.includes(SWITCH_GELEGRAAF);
export const BLOCK_INFO_MARKET = blocks.includes(SWITCH_INFO_MARKET);
export const BLOCK_ZOO_AUDIO_TOUR = blocks.includes(SWITCH_ZOO_AUDIO_TOUR);
export const BLOCK_CUSTOM_EMBED = blocks.includes(SWITCH_CUSTOM_EMBED);
export const BLOCK_RIJKSMUSEUM = blocks.includes(SWITCH_RIJKSMUSEUM);

export const TAB_COLORS = TAB_COLORS_ENABLED && tabColors;
export const GELEGRAAF = GELEGRAAF_ENABLED && gelegraaf;
export const STATIC_SPONSORS = STATIC_SPONSORS_ENABLED && staticSponsors;
export const TAG_OVERRIDES = TAG_OVERRIDES_ENABLED && tagOverrides;
export const INITIAL_MAP_FILTER = mapFilter;

export const HOME_PAGE_BANNER = banner;
