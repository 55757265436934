import { AllHtmlEntities } from 'html-entities';
import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { List, useTheme } from 'react-native-paper';

import { GelegraafItem } from './useGelegraaf';
import { useSafe } from './useSafeHtmlContent';

const entities = new AllHtmlEntities();

const styles = StyleSheet.create({
  featured: {
    width: 72,
    height: 72,
    marginLeft: 16,
  },
});

export interface RegularHeadlineProps {
  article: GelegraafItem;
  onGotoArticle(): void;
}

export function RegularHeadline({
  article,
  onGotoArticle,
}: RegularHeadlineProps) {
  const { title, featured_media, content } = article;
  const image =
    (featured_media &&
      featured_media.media_details &&
      featured_media.media_details.sizes &&
      featured_media.media_details.sizes['speaker-thumb'] &&
      featured_media.media_details.sizes['speaker-thumb'].source_url) ||
    null;

  const safeDescription = useSafe((content || { rendered: '' }).rendered);
  const { roundness } = useTheme();

  return (
    <List.Item
      onPress={onGotoArticle}
      title={entities.decode((title || { rendered: '' }).rendered)}
      description={safeDescription}
      descriptionNumberOfLines={2}
      descriptionStyle={{ lineHeight: 19, marginTop: 4, fontSize: 13 }}
      right={() =>
        (image && (
          <Image
            style={[styles.featured, { borderRadius: roundness }]}
            source={{ uri: image, width: 458, height: 458 }}
            width={112}
            height={112}
          />
        )) ||
        null
      }
    />
  );
}
