import { TactileModuleSwipe } from '@introcloud/api-client';
import { useBlockData } from '@introcloud/blocks-interface';
import { motion, MotionValue, useTransform } from 'framer-motion';
import React, { memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Avatar,
  Caption,
  Card,
  Chip,
  Text,
  useTheme,
} from 'react-native-paper';
import { AA_THRESHOLD_CONTRAST, useContrast } from 'use-color-luminance';
import { Profile, SelfProfile } from './Profile';

const EMPTY: string[] = [];

function SwipeCard_({
  animationPosition,
  selfProfile,
  cardType,
  ...profile
}: Profile & {
  selfProfile?: SelfProfile;
  cardType: NonNullable<TactileModuleSwipe['card']>['image'];
  animationPosition: MotionValue<number>;
}) {
  const { getImageUrl } = useBlockData();

  const matchesPositive = useMemo(() => {
    if (!selfProfile || !profile.likes) {
      return EMPTY;
    }
    return (
      profile.likes.filter(
        (id) => selfProfile[id] && selfProfile[id].value === true
      ) || EMPTY
    );
  }, [profile, selfProfile]);

  const matchesNegative = useMemo(() => {
    if (!selfProfile || !profile.dislikes) {
      return EMPTY;
    }
    return (
      profile.dislikes.filter(
        (id) => selfProfile[id] && !selfProfile[id].value === false
      ) || EMPTY
    );
  }, [profile, selfProfile]);

  const {
    dark,
    colors: { primary, accent },
  } = useTheme();
  const primaryHasContrast =
    useContrast(primary, dark ? '#121212' : '#fff') > AA_THRESHOLD_CONTRAST;
  const accentHasContrast =
    useContrast(accent, dark ? '#121212' : '#fff') > AA_THRESHOLD_CONTRAST;
  const fallback = dark ? '#fff' : '#121212';
  const color = primaryHasContrast
    ? primary
    : accentHasContrast
    ? accent
    : fallback;

  const randomPositive = useMemo(
    () => matchesPositive[Math.floor(Math.random() * matchesPositive.length)],
    [matchesPositive]
  );

  const randomNegative = useMemo(
    () => matchesNegative[Math.floor(Math.random() * matchesNegative.length)],
    [matchesNegative]
  );

  return (
    <Card style={StyleSheet.absoluteFill} pointerEvents="box-only">
      <View
        style={[
          styles.overlay,
          cardType === 'big'
            ? { justifyContent: 'flex-end' }
            : {
                justifyContent: 'space-between',
              },
        ]}
      >
        {profile.image && cardType === 'big' ? (
          <Avatar.Image
            size={230}
            source={{
              uri: getImageUrl(profile.image, 'icon_512') || '',
              width: 512,
              height: 512,
            }}
            style={{
              position: 'absolute',
              alignSelf: 'center',
              marginBottom: 'auto',
              top: 8,
            }}
          />
        ) : null}
        <View style={styles.header}>
          <motion.div
            style={{
              borderWidth: 4,
              borderRadius: 5,
              padding: 8,
              borderColor: '#6ee3b4',
              opacity: useTransform(animationPosition, [0.2, 1], [0, 1]),
            }}
          >
            <Text style={styles.likeLabel}>Like</Text>
          </motion.div>
          <motion.div
            style={{
              borderWidth: 4,
              borderRadius: 5,
              padding: 8,
              borderColor: '#ec5288',
              opacity: useTransform(animationPosition, [-1, -0.2], [1, 0]),
            }}
          >
            <Text style={styles.nopeLabel}>Nope</Text>
          </motion.div>
        </View>

        {matchesPositive.length === 0 && matchesNegative.length === 0 ? null : (
          <View style={cardType === 'big' ? { marginBottom: 8 } : {}}>
            <Caption style={{ color }}>You matched on</Caption>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {randomPositive ? (
                <Chip
                  style={{
                    marginRight: 4,
                    marginBottom: 4,
                  }}
                >
                  {selfProfile![randomPositive]?.label?.slice(0, 20)}
                  {(selfProfile![randomPositive]?.label?.length || 0) > 20
                    ? '...'
                    : ''}
                  {matchesPositive.length > 1 &&
                    ` + ${matchesPositive.length - 1}`}
                </Chip>
              ) : null}
              {!randomPositive && randomNegative ? (
                <Chip
                  style={{ maxWidth: 200, marginRight: 4, marginBottom: 4 }}
                >
                  {selfProfile![randomNegative]?.label?.slice(0, 20)}
                  {(selfProfile![randomNegative]?.label?.length || 0) > 20
                    ? '...'
                    : ''}
                  {matchesNegative.length > 1 &&
                    ` + ${matchesNegative.length - 1}`}
                </Chip>
              ) : null}
            </View>
          </View>
        )}

        <View style={styles.footer}>
          {profile.description ? (
            <Caption selectable={false}>
              {String(profile.description).replace(/\n/g, '').slice(0, 200)}
            </Caption>
          ) : null}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: profile.image ? 4 : 0,
            }}
          >
            {profile.image && cardType === 'small' ? (
              <Avatar.Image
                size={36}
                source={{
                  uri: getImageUrl(profile.image, 'icon_64') || '',
                  width: 128,
                  height: 128,
                }}
                style={{ marginRight: 8 }}
              />
            ) : null}
            <Text style={styles.name} selectable={false}>
              {profile.title}
            </Text>
          </View>
        </View>
      </View>
    </Card>
  );
}

export const SwipeCard = memo(SwipeCard_);

const styles = StyleSheet.create({
  image: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
  },
  overlay: {
    flex: 1,
    padding: 16,
    paddingBottom: 12,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    flexDirection: 'column',
  },
  name: {
    fontSize: 32,
  },
  like: {
    borderWidth: 4,
    borderRadius: 5,
    padding: 8,
    borderColor: '#6ee3b4',
  },
  likeLabel: {
    fontSize: 32,
    color: '#6ee3b4',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  nope: {
    borderWidth: 4,
    borderRadius: 5,
    padding: 8,
    borderColor: '#ec5288',
  },
  nopeLabel: {
    fontSize: 32,
    color: '#ec5288',
    fontWeight: 'bold',
  },
});
