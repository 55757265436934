import {
  fetchApplicationUser,
  fetchUser,
  TactileUser,
} from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';

import { NotReady } from '../core/errors/NotReady';
import { queryClient } from '../core/QueryCache';
import {
  AnyMemoryValue,
  SecureStoredMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useAbortController } from './useAbortController';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const USER: AnyMemoryValue<TactileUser | null> = Platform.select({
  web: new StoredMemoryValue<TactileUser>(
    'application.user.v1.web',
    true
  ) as AnyMemoryValue<TactileUser>,
  default: new SecureStoredMemoryValue<TactileUser>(
    'application.user.v1.native',
    true
  ) as AnyMemoryValue<TactileUser>,
});

runOnLogout(() => {
  USER.emit(undefined);
});

export function useUser({ enabled = true }: { enabled?: boolean } = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const abortable = useAbortController();

  const fetcher = useCallback(() => {
    if (!endpoint || !authorization) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      const result = await fetchApplicationUser(
        endpoint,
        authorization!,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      const {
        _id,
        name: { full, first, middle, last },
        image: { profile },
        email: { value, anonymous },
        isAdmin,
        accessRef,
        chatId,
      } = merge({ name: {}, image: {}, email: {} }, result);

      const finalUser: TactileUser = {
        _id,
        name: { full, first, middle, last },
        image: { profile },
        email: { value, anonymous },
        isAdmin,
        accessRef,
        chatId,
      };

      return finalUser;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [endpoint, authorization]);

  const lastEndpoint = useRef(endpoint);
  lastEndpoint.current = endpoint;

  const {
    data: user,
    error,
    refetch,
    isFetching,
    ...others
  } = useQuery<TactileUser | null, FetchMediaError | NotReady>(
    [endpoint, 'application', 'user', authorization],
    fetcher,
    {
      enabled: enabled && !!(authorization && endpoint),
      staleTime: 60 * 1000,
    }
  );

  return {
    data: user,
    error,
    reload: refetch,
    loading: others.isLoading,
    refreshing: isFetching,
    ...others,
  };
}

export function useUserSettings({
  enabled = true,
}: { enabled?: boolean } = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const abortable = useAbortController();

  const fetcher = useCallback(() => {
    if (!endpoint || !authorization) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      const result = await fetchUser(
        endpoint,
        authorization!,
        ac.signal,
        SHOULD_DEBUG_FETCH
      );

      return (result as unknown as { settings: { chatNotification?: boolean } })
        .settings;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [endpoint, authorization]);

  const lastEndpoint = useRef(endpoint);
  lastEndpoint.current = endpoint;

  const {
    data: user,
    error,
    refetch,
    isFetching,
    ...others
  } = useQuery<
    { chatNotification?: boolean } | null,
    FetchMediaError | NotReady
  >([endpoint, 'application', 'user', authorization, 'settings'], fetcher, {
    enabled: enabled && !!(authorization && endpoint),
    staleTime: 60 * 1000,
  });

  return {
    data: user,
    error,
    reload: refetch,
    loading: others.isLoading,
    refreshing: isFetching,
    ...others,
  };
}
