import { useMemo } from 'react';

import { useEvents, PreparedEvent } from './useEvents';

const NO_ITEMS: readonly PreparedEvent[] = [];

export function useRelevantEvents(
  timestamp: number,
  { enabled = true }: { enabled?: boolean } = {}
) {
  const { data: programme } = useEvents({ enabled });

  // TODO: these memos don't do what we want them to do. Because the timestamp
  // changes, the filter creates a new array, and therefore a new identity. In
  // reality, we want to check if the result is deeply or id-ly different from
  // what we had before and bail out if it's the same.

  const notEndedItems = useMemo(
    () =>
      programme
        ? programme.filter(
            (item) =>
              item.duration.end.unix > timestamp &&
              item.hierarchy.showInCalendar
          )
        : NO_ITEMS,
    [programme, timestamp]
  );

  const notStartedItems = useMemo(
    () => notEndedItems.filter((item) => item.duration.start.unix > timestamp),
    [notEndedItems, timestamp]
  );

  const nextItems = useMemo(
    () => (notStartedItems.length === 0 ? NO_ITEMS : notStartedItems),
    [notStartedItems]
  );

  const nowItems = useMemo(
    () =>
      notEndedItems.length === 0
        ? NO_ITEMS
        : notEndedItems.filter((item) => item.duration.start.unix <= timestamp),
    [notEndedItems]
  );

  return { next: nextItems, now: nowItems };
}
